import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import nameIcon from "../../Assets/profile/name.svg";
import EmailIcon from "../../Assets/profile/email.svg";
import { Image, Input } from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "@clerk/clerk-react";
import { AuthContext } from "Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { PopUpContext } from "Context/PopUpContext";

export default function EditProfileData({
  firstName,
  lastName,
  userEmail,
  triggerForm,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const { user } = useUser();

  const { setIsMailAdded, setNewEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  const { setIsUpdateProfile, setIsUpdateProfileFailed } =
    useContext(PopUpContext);

  let token = localStorage.getItem("token");

  const updateNameOnly = async (data) => {
    const payload = {
      firstName: data.firstName,
    };
    if (data.lastName) {
      payload.lastName = data.lastName;
    }
    try {
      await user.update(payload);

      toast("Name updated successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      await user.reload();

      await axios.put(
        "/user",
        {
          name: `${data.firstName} ${data.lastName || " "}`,
          email: userEmail,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setIsUpdateProfile(true);
      setTimeout(() => {
        setIsUpdateProfile(false);
      }, 1000);
    } catch (error) {
      const errorMessage =
        error.errors?.[0]?.longMessage ||
        error.message ||
        "Failed to update name";

      setIsUpdateProfileFailed(true);
      setTimeout(() => {
        setIsUpdateProfileFailed(false);
      }, 1000);

      toast(errorMessage, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const updateEmailOnly = async (data) => {
    try {
      const newEmail = await user.createEmailAddress({ email: data.email });
      if (newEmail.verification?.status !== "verified") {
        await newEmail.prepareVerification({ strategy: "email_code" });
        setIsMailAdded(false);
        setNewEmail(data.email);

        toast("Verification code sent to the new email", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });

        navigate("/verify-email");
      }
    } catch (error) {
      const errorMessage =
        error.errors?.[0]?.longMessage ||
        error.message ||
        "Failed to update email";

      setIsUpdateProfileFailed(true);
      setTimeout(() => {
        setIsUpdateProfileFailed(false);
      }, 1000);

      toast(errorMessage, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const updateNameAndEmail = async (data) => {
    try {
      await updateEmailOnly(data);
      await updateNameOnly(data);
    } catch (error) {}
  };

  const editProfile = async (data) => {
    if (data.firstName !== firstName || data.lastName !== lastName) {
      if (data.email !== userEmail) {
        await updateNameAndEmail(data);
      } else {
        await updateNameOnly(data);
      }
    } else if (data.email !== userEmail) {
      if (data.firstName !== firstName || data.lastName !== lastName) {
        await updateNameAndEmail(data);
      } else {
        await updateEmailOnly(data);
      }
    } else {
      setIsUpdateProfile(true);
      setTimeout(() => {
        setIsUpdateProfile(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (triggerForm) {
      handleSubmit(editProfile)();
    }
  }, [triggerForm, handleSubmit]);

  useEffect(() => {
    if (firstName) {
      setValue("firstName", firstName);
    }
    if (lastName) {
      setValue("lastName", lastName);
    }
    if (userEmail) {
      setValue("email", userEmail);
    }
  }, [firstName, lastName, userEmail]);

  return (
    <form
      className="sm:flex-nowrap flex-wrap px-5 justify-center sm:mt-0 mt-5"
      onSubmit={handleSubmit(editProfile)}
    >
      <div
        className={`flex flex-col gap-2 w-full ${
          errors.firstName?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="firstNameField" className="flex items-center gap-[5px]">
          <Image
            src={nameIcon}
            className="w-[18px] h-[18px]"
            alt="Edit First Name"
          />
          <span className="text-cardGrayColor text-sm">First Name</span>
        </label>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: "First name is required",
            minLength: {
              value: 3,
              message: "Minimum number of characters is 3",
            },
            maxLength: {
              value: 30,
              message: "Maximum number of characters is 30",
            },
            pattern: {
              value: /^[a-zA-Z]+$/,
              message: "Invalid first name",
            },
          }}
          render={({ field }) => (
            <Input
              id="firstNameField"
              {...field}
              type="text"
              variant="bordered"
              placeholder="Enter your first name"
              classNames={{
                base: `input-noMargin ${
                  errors.firstName?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.firstName?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.firstName?.message
                    ? "invalid-text"
                    : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.firstName?.message ? true : false}
              errorMessage={errors.firstName?.message}
            />
          )}
        />
      </div>

      <div
        className={`flex flex-col gap-2 w-full ${
          errors.lastName?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="lastNameField" className="flex items-center gap-[5px]">
          <Image
            src={nameIcon}
            className="w-[18px] h-[18px]"
            alt="Edit Last Name"
          />
          <span className="text-cardGrayColor text-sm">Last Name</span>
        </label>
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: "Last name is required",
            maxLength: {
              value: 30,
              message: "Maximum number of characters is 30",
            },
            pattern: {
              value: /^[a-zA-Z]+$/,
              message: "Invalid last name",
            },
          }}
          render={({ field }) => (
            <Input
              id="lastNameField"
              {...field}
              type="text"
              variant="bordered"
              placeholder="Enter your last name"
              classNames={{
                base: `input-noMargin ${
                  errors.lastName?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.lastName?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.lastName?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.lastName?.message ? true : false}
              errorMessage={errors.lastName?.message}
            />
          )}
        />
      </div>

      <div
        className={`flex flex-col gap-2 w-full ${
          errors.email?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="emailField" className="flex items-center gap-[5px]">
          <Image
            src={EmailIcon}
            className="w-[18px] h-[18px]"
            alt="Edit Email"
          />
          <span className="text-cardGrayColor text-sm">Email</span>
        </label>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required",
            validate: (value) => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              return emailRegex.test(value) || "Invalid email address";
            },
          }}
          render={({ field }) => (
            <Input
              id="emailField"
              {...field}
              type="text"
              variant="bordered"
              placeholder="Enter your email"
              classNames={{
                base: `input-noMargin ${
                  errors.email?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.email?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.email?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.email?.message ? true : false}
              errorMessage={errors.email?.message}
            />
          )}
        />
      </div>
    </form>
  );
}
