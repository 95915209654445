import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Tooltip,
} from "@nextui-org/react";
import taskArrow from "../../Assets/tasks-board/task-arrow.svg";
import grayTimer from "../../Assets/tasks-board/gray-timer.svg";
import redTimer from "../../Assets/tasks-board/red-timer.svg";
import dots from "../../Assets/scrapbook/dots-vertical.svg";
import downArrow from "../../Assets/tasks-board/down.svg";
import { TasksBoardContext } from "Context/TasksBoardContext";
import axios from "axios";
import { toast } from "react-toastify";
import DataLoader from "pages/DataLoader/DataLoader";
import AddTask from "components/FrondDetails/Tasklist/AddTask";
import DeleteTaskModal from "components/FrondDetails/Tasklist/DeleteTaskModal";
import { PopUpContext } from "Context/PopUpContext";
import useSize from "hooks/useSize";
import { Link } from "react-router-dom";
import EchoClient from "Echo/echo";

export default function MyTasks() {
  const [columns, setColumns] = useState({
    pending: { name: "pending", items: [] },
    in_progress: { name: "in_progress", items: [] },
    completed: { name: "completed", items: [] },
  });
  const [taskOpenStates, setTaskOpenStates] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const { setTasksPos, tasksPos } = useContext(TasksBoardContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const initialTaskOpenStates = {};
    Object.values(columns).forEach((column) => {
      column.items.forEach((item) => {
        initialTaskOpenStates[item.id] = false;
      });
    });
    setTaskOpenStates(initialTaskOpenStates);
  }, [columns]);

  const onDragEnd = (result) => {
    setIsDragging(false);
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
      });
    } else {
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      const newStatus = destination.droppableId;
      editStatus(newStatus, removed.task.id, removed.task, true);
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const toggleTask = (id) => {
    setTaskOpenStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    if (isDragging) {
      setTasksPos(taskOpenStates);
    }
    if (!isDragging) {
      setTaskOpenStates(tasksPos);
    }
  }, [isDragging]);

  const authToken = localStorage.getItem("token");

  const { isAddTask } = useContext(PopUpContext);

  const [userId, setUserId] = useState(null);

  const getUserData = async () => {
    setErrorMessage("");
    try {
      const res = await axios.get("/user", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserId(res.data?.id);
    } catch (error) {
      setErrorMessage("Failed to fetch user data");
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const [tasksSize, setTasksSize] = useState({
    completed: 0,
    in_progress: 0,
    pending: 0,
  });

  const tasksSizeRef = useRef(tasksSize);

  function sortData(items) {
    const sortedData = items.sort((a, b) => {
      const dateA = a?.task?.due_date
        ? new Date(a.task.due_date.split("/").reverse().join("-"))
        : null;
      const dateB = b?.task?.due_date
        ? new Date(b.task.due_date.split("/").reverse().join("-"))
        : null;

      if (!dateA) return 1;
      if (!dateB) return -1;

      return dateA - dateB;
    });
    return sortedData;
  }

  const getFronds = async (isActivateLoading) => {
    if (!isActivateLoading) {
      setIsLoading(true);
    }
    setErrorMessage("");
    try {
      const { data } = await axios.get(
        "https://admin.thefrondappadmin.com/api/mytasks",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const sortedPending = sortData(data?.response?.data?.pending);
      const sortedInProgress = sortData(data?.response?.data?.in_progress);
      const sortedCompleted = sortData(data?.response?.data?.completed);

      setColumns({
        pending: { items: sortedPending },
        in_progress: { items: sortedInProgress },
        completed: { items: sortedCompleted },
      });

      const newTasksSize = {
        completed: data?.response?.data?.completed?.length,
        in_progress: data?.response?.data?.in_progress?.length,
        pending: data?.response?.data?.pending?.length,
      };
      if (!isActivateLoading) {
        setTasksSize(newTasksSize);
      }
      tasksSizeRef.current = newTasksSize;
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    if (!isActivateLoading) {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!userId) {
  //     getFronds();
  //   }
  // }, []);

  const fronds = useMemo(async (isActivateLoading) => {
    if (!isActivateLoading) {
      setIsLoading(true);
    }
    setErrorMessage("");
    try {
      const { data } = await axios.get(
        "https://admin.thefrondappadmin.com/api/mytasks",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const sortedPending = sortData(data?.response?.data?.pending);
      const sortedInProgress = sortData(data?.response?.data?.in_progress);
      const sortedCompleted = sortData(data?.response?.data?.completed);

      setColumns({
        pending: { items: sortedPending },
        in_progress: { items: sortedInProgress },
        completed: { items: sortedCompleted },
      });

      const newTasksSize = {
        completed: data?.response?.data?.completed?.length,
        in_progress: data?.response?.data?.in_progress?.length,
        pending: data?.response?.data?.pending?.length,
      };
      if (!isActivateLoading) {
        setTasksSize(newTasksSize);
      }
      tasksSizeRef.current = newTasksSize;
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    if (!isActivateLoading) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const channel = EchoClient.channel(`user.tasks.${userId}`);
      channel.listen("TaskUpdated", (event) => {
        const { completed, in_progress, pending } = event || {};

        if (
          completed !== tasksSizeRef.current.completed ||
          in_progress !== tasksSizeRef.current.in_progress ||
          pending !== tasksSizeRef.current.pending
        ) {
          getFronds(true);
        }
      });

      return () => {
        channel.stopListening("TaskUpdated");
      };
    }
  }, [userId, columns]);

  useEffect(() => {
    if (isAddTask) {
      getFronds(true);
    }
  }, [isAddTask]);

  if (errorMessage) {
    toast(errorMessage || "Failed to fetch tasks", {
      autoClose: 1500,
      position: "bottom-right",
      hideProgressBar: true,
      className: "rounded-4xl custom-toast error-toast",
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {}, [columns]);

  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [frondId, setFrondId] = useState(null);

  useEffect(() => {
    if (itemKey === "delete") {
      setOpenDeleteModal(true);
      setItemKey(null);
    } else if (itemKey === "edit") {
      setEditTaskModal(true);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id, projectId) => {
    setItemKey(key);
    setTargetId(id);
    setFrondId(projectId);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const [targetTaskID, setTargetTaskID] = useState(null);
  const [targetKey, setTrgetKey] = useState(null);
  const [targetFrond, setTargetFrond] = useState(null);

  useEffect(() => {
    if (targetKey === "pending") {
      editTask();
      setTrgetKey(null);
      setTargetFrond(null);
    } else if (targetKey === "in progress") {
      editTask();
      setTrgetKey(null);
      setTargetFrond(null);
    } else if (targetKey === "completed") {
      editTask();
      setTrgetKey(null);
      setTargetFrond(null);
    }
  }, [targetKey]);

  const editStatus = (key, id, task, directCall) => {
    setTrgetKey(key);
    setTargetTaskID(id);
    setTargetFrond(task);

    if (directCall && key && id && task) {
      editTask(key, id, task, true);
    }
  };

  const handleStatusClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  async function editTask(newStatus, changedId, project, flag) {
    let statusType = "";
    if (flag) {
      statusType = newStatus;
    } else {
      if (targetKey === "pending") {
        statusType = "pending";
      } else if (targetKey === "in progress") {
        statusType = "in_progress";
      } else if (targetKey === "completed") {
        statusType = "completed";
      }
    }

    const payload = {
      title: project ? project.title : targetFrond?.title,
      assigned_to: project ? project.assigned_to : targetFrond?.assigned_to,
      description: project ? project.description : targetFrond?.description,
      due_date: project ? project.due_date : targetFrond?.due_date,
      status: statusType,
    };

    try {
      const { data } = await axios.put(
        `https://admin.thefrondappadmin.com/api/tasks/${
          changedId ? changedId : targetTaskID
        }`,
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedTasks = data.response?.tasks || {};
      tasksSizeRef.current = {
        completed:
          updatedTasks.completed?.length || tasksSizeRef.current.completed,
        in_progress:
          updatedTasks.in_progress?.length || tasksSizeRef.current.in_progress,
        pending: updatedTasks.pending?.length || tasksSizeRef.current.pending,
      };
      getFronds(true);
    } catch (error) {
      toast(error.response?.data?.message || "An error occurred", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  const { width } = useSize();

  if (width === 0) {
    return null;
  }

  const toggleAllTasks = (columnId) => {
    setTaskOpenStates((prevState) => {
      const currentColumnTasks = columns[columnId]?.items || [];
      const shouldCollapse = currentColumnTasks.every(
        (item) => taskOpenStates[item.task.id]
      );

      const newTaskStates = currentColumnTasks.reduce((acc, item) => {
        acc[item?.task?.id] = !shouldCollapse;
        return acc;
      }, {});

      return {
        ...prevState,
        ...newTaskStates,
      };
    });
  };

  const isOverdue = (dueDate) => {
    const [year, month, day] = dueDate.split("-").map(Number);
    const dueDateObject = new Date(year, month - 1, day);
    const today = new Date();

    return today > dueDateObject;
  };

  return (
    <>
      <div className="boardMedia:mr-0 overflow-x-auto">
        <section className="my-tasks flex gap-5 min-h-[calc(100vh-109px)] w-full justify-evenly">
          {errorMessage ? (
            <div className="text-white bg-cardColor py-3 text-center rounded-15px text-sm">
              <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                {errorMessage}
              </span>
            </div>
          ) : isLoading ? (
            <div className="min-h-[400px] flex justify-center items-center w-full">
              <DataLoader />
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              {Object.entries(columns).map(([columnId, column]) => (
                <div
                  key={columnId}
                  // style={{
                  //   height:
                  //     columnId === "pending"
                  //       ? pendingHeight
                  //       : columnId === "in_progress"
                  //       ? inProgressHeight
                  //       : completedHeight,
                  //   transition: "height 300ms ease",
                  // }}
                  className={`kanban-column boardMedia:w-1/3 sm:min-w-[360px] sm:max-w-[360px] min-w-[330px] max-w-[330px] flex flex-col gap-5 overflow-hidden`}
                >
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`flex flex-col gap-5 ${
                          snapshot.isDraggingOver
                            ? "dragging-over gap-5"
                            : "gap-5"
                        }`}
                        style={{
                          marginBottom: snapshot.isDraggingOver ? "20px" : "0",
                        }}
                      >
                        <button
                          onClick={() => {
                            toggleAllTasks(columnId);
                            // if (columnId === "pending") {
                            //   setHidePending(!hidePending);
                            // } else if (columnId === "in_progress") {
                            //   setHideInProgress(!hideInProgress);
                            // } else if (columnId === "completed") {
                            //   setHideCompleted(!hideCompleted);
                            // }
                          }}
                          className="flex justify-center items-center rounded-4xl border-solid border-2 border-grayBorder p-5 no-focus bg-white"
                        >
                          <h2
                            className={`text-lg ${
                              columnId === "in_progress" && "text-redTimeline"
                            } ${
                              columnId === "pending" && "text-blueTimeline"
                            } ${
                              columnId === "completed" && "text-greenTimeline"
                            } leading-[20.7px] font-bold`}
                          >
                            {columnId !== "in_progress"
                              ? columnId[0].toUpperCase() + columnId.slice(1) ||
                                "-"
                              : "In Progress"}
                          </h2>
                        </button>
                        {column.items.map((item, index) => (
                          <Draggable
                            key={item?.task?.id}
                            draggableId={item?.task?.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                onClick={() => {
                                  openModal(
                                    "edit",
                                    item?.task?.id,
                                    item?.task?.project_id
                                  );
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`${
                                  taskOpenStates[item?.task?.id]
                                    ? "h-[66px]"
                                    : "h-[210px]"
                                } transition-all duration-300 rounded-4xl border-solid border-2 border-grayBorder p-5 flex flex-col bg-white overflow-hidden no-focus ${
                                  snapshot.isDragging ? "dragging" : ""
                                }`}
                              >
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleTask(item?.task?.id);
                                  }}
                                >
                                  <div
                                    className={`flex justify-between items-center gap-2.5 pb-2.5 mb-2.5 after:transition-all after:duration-200 relative after:absolute after:h-[1px] after:bg-grayBorder after:left-0 after:bottom-0 after:right-0 ${
                                      taskOpenStates[item?.task?.id]
                                        ? "after:opacity-0"
                                        : "after:opacity-100"
                                    }`}
                                  >
                                    <Tooltip
                                      content={item?.task?.title}
                                      placement="top-start"
                                    >
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openModal(
                                            "edit",
                                            item?.task?.id,
                                            item?.task?.project_id
                                          );
                                        }}
                                        className={`text-lg ${
                                          item?.task?.status === "pending" &&
                                          "text-blueTimeline"
                                        } ${
                                          item?.task?.status ===
                                            "in_progress" && "text-redTimeline"
                                        } ${
                                          item?.task?.status === "completed" &&
                                          "text-greenTimeline"
                                        } leading-[20.7px] font-bold`}
                                      >
                                        {width >= 640
                                          ? item?.task?.title?.length > 30
                                            ? item?.task?.title?.slice(0, 30)
                                            : item?.task?.title || "-"
                                          : item?.task?.title?.length > 18
                                          ? item?.task?.title?.slice(0, 18)
                                          : item?.task?.title || "-"}
                                      </button>
                                    </Tooltip>
                                    <Image
                                      src={downArrow}
                                      alt="Collapse Task"
                                      className={`${
                                        taskOpenStates[item?.task?.id]
                                          ? "rotate-0"
                                          : "rotate-180"
                                      } transition-all duration-300 w-[24px] h-[24px]`}
                                    />
                                  </div>
                                </button>
                                <div className={`task`}>
                                  <div className="flex justify-between gap-2.5 mb-2.5">
                                    <div className="user flex items-center gap-2.5 mb-2">
                                      {item?.owner?.img_url ? (
                                        <Image
                                          src={item?.owner?.img_url}
                                          className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full bg-cover"
                                          alt={item?.owner?.name}
                                        />
                                      ) : (
                                        <div className="relative min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full bg-offWhite"></div>
                                      )}
                                      <div className="flex flex-col">
                                        <h4 className="text-textColor font-bold leading-[22px] text-base">
                                          {item?.owner?.name || "-"}
                                        </h4>
                                        <p className="text-sm text-cardGrayColor leading-[16.1px]">
                                          {timeAgo(item?.task?.created_at) ||
                                            "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <Dropdown
                                      classNames={{
                                        content:
                                          "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                                      }}
                                      placement="left-start"
                                    >
                                      <DropdownTrigger>
                                        <button
                                          className="w-[24px] h-[24px]"
                                          onClick={handleDotsClick}
                                        >
                                          <img
                                            src={dots}
                                            alt="Dots"
                                            className="min-w-[24px] min-h-[24px]"
                                          />
                                        </button>
                                      </DropdownTrigger>
                                      <DropdownMenu
                                        aria-label="Frond Actions"
                                        onAction={(key) =>
                                          openModal(
                                            key,
                                            item?.task?.id,
                                            item?.task?.project_id
                                          )
                                        }
                                      >
                                        <DropdownItem
                                          textValue="edit"
                                          key="edit"
                                          className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                                        >
                                          Edit
                                        </DropdownItem>
                                        <DropdownItem
                                          key="delete"
                                          className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                                          color="danger"
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                  <Link
                                    to={`/frond/about/${item?.task?.project_id}`}
                                  >
                                    <p
                                      className={`leading-[22px] mb-2 ${
                                        item?.task?.status === "pending" &&
                                        "text-blueTimeline"
                                      } ${
                                        item?.task?.status === "in_progress" &&
                                        "text-redTimeline"
                                      } ${
                                        item?.task?.status === "completed" &&
                                        "text-greenTimeline"
                                      }`}
                                    >
                                      {width >= 640
                                        ? item?.project_name?.length > 28
                                          ? item?.project_name.slice(0, 28)
                                          : item?.project_name || "-"
                                        : item?.project_name?.length > 28
                                        ? item?.project_name?.slice(0, 18)
                                        : item?.project_name || "-"}
                                    </p>
                                  </Link>

                                  <div className="flex justify-between items-center gap-2.5">
                                    <Dropdown
                                      classNames={{
                                        base: "mt-[-6px] p-0",
                                        content:
                                          "min-w-[120px] max-w-[120px] p-0 rounded-md",
                                      }}
                                      placement="bottom"
                                    >
                                      <DropdownTrigger>
                                        <button
                                          className={`${
                                            item?.task?.status === "pending" &&
                                            "bg-blueTimeline"
                                          } ${
                                            item?.task?.status ===
                                              "in_progress" && "bg-redTimeline"
                                          } ${
                                            item?.task?.status ===
                                              "completed" && "bg-greenTimeline"
                                          } py-[3px] px-2.5 rounded-md h-[28px] w-[120px] flex justify-center items-center gap-2`}
                                          onClick={handleStatusClick}
                                        >
                                          <span className="text-sm text-white">
                                            {item?.task?.status !==
                                            "in_progress"
                                              ? item?.task?.status[0].toUpperCase() +
                                                  item?.task?.status.slice(1) ||
                                                "-"
                                              : "In Progress" || "-"}
                                          </span>
                                          <Image
                                            src={taskArrow}
                                            alt="Change status"
                                            className="w-[18px] h-[18px]"
                                          />
                                        </button>
                                      </DropdownTrigger>
                                      <DropdownMenu
                                        aria-label="Task Status"
                                        classNames={{ list: "gap-0" }}
                                        className="p-0"
                                        onAction={(key) =>
                                          editStatus(
                                            key,
                                            item?.task?.id,
                                            item?.task
                                          )
                                        }
                                      >
                                        {item?.task?.status !== "pending" && (
                                          <DropdownItem
                                            textValue="pending"
                                            key="pending"
                                            className="text-white text-center rounded-bl-none rounded-br-none bg-blueTimeline px-[9px] py-[4px] change-pending-task text-sm font-normal relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder selected-action"
                                          >
                                            Pending
                                          </DropdownItem>
                                        )}
                                        {item?.task?.status !==
                                          "in_progress" && (
                                          <DropdownItem
                                            textValue="in progress"
                                            key="in progress"
                                            className={`text-white text-center ${
                                              item?.task?.status === "completed"
                                                ? "rounded-tl-none rounded-tr-none"
                                                : "rounded-bl-none rounded-br-none"
                                            } bg-redTimeline px-[9px] py-[4px] change-inProgress-task text-sm font-normal relative ${
                                              item?.task?.status !==
                                                "completed" &&
                                              "after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder"
                                            } selected-action`}
                                          >
                                            In Progress
                                          </DropdownItem>
                                        )}
                                        {item?.task?.status !== "completed" && (
                                          <DropdownItem
                                            textValue="completed"
                                            key="completed"
                                            className="text-white text-center rounded-tl-none rounded-tr-none bg-greenTimeline px-[9px] py-[4px] change-completed-task text-sm font-normal selected-action"
                                          >
                                            Completed
                                          </DropdownItem>
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                    <div className="flex items-center gap-[5px]">
                                      {item?.task?.status === "in_progress" ? (
                                        <Image
                                          src={redTimer}
                                          className="w-[22px] h-[22px]"
                                          alt="Due Date"
                                        />
                                      ) : (
                                        <Image
                                          src={grayTimer}
                                          className="w-[22px] h-[22px]"
                                          alt="Due Date"
                                        />
                                      )}
                                      <span
                                        className={`leading-[22px] text-sm ${
                                          item?.task?.status === "pending" &&
                                          "text-blueTimeline"
                                        } ${
                                          item?.task?.status ===
                                            "in_progress" && "text-redTimeline"
                                        } ${
                                          item?.task?.status === "completed" &&
                                          "text-greenTimeline"
                                        }`}
                                      >
                                        {
                                          months[
                                            parseInt(
                                              item?.task?.due_date?.split(
                                                "-"
                                              )[1],
                                              10
                                            ) - 1
                                          ]
                                        }{" "}
                                        {parseInt(
                                          item?.task?.due_date?.split("-")[2],
                                          10
                                        ) || "-"}
                                      </span>

                                      {item?.task?.due_date &&
                                        isOverdue(item?.task?.due_date) && (
                                          <span className="ms-2.5 text-redTimeline text-sm">
                                            Overdue
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </DragDropContext>
          )}
        </section>
      </div>
      {editTaskModal && targetId && frondId && (
        <AddTask
          isOpen={editTaskModal}
          onClose={() => setEditTaskModal(false)}
          isModalOpen={true}
          taskId={targetId}
          frondId={frondId}
        />
      )}
      {openDeleteModal && targetId && (
        <DeleteTaskModal
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          taskId={targetId}
        />
      )}
    </>
  );
}
