import React, { useContext, useEffect, useState } from "react";
import EditCommentModal from "./EditCommentModal";
import dots from "../../Assets/community/dots-vertical.svg";
import likesIcon from "../../Assets/community/likes.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Textarea,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import { CommunityContext } from "Context/CommunityContext";
import CommentReplies from "./CommentReplies";

export default function PostComments({ postId, listOfComments }) {
  let authToken = localStorage.getItem("token");
  const [itemKey, setItemKey] = useState(null);

  const [commentsList, setCommentsList] = useState([]);

  const { setCommentsNum } = useContext(CommunityContext);

  async function getPostData() {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/posts/${postId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setCommentsList(data?.comments);
      setCommentsNum((prevCommentsNum) => {
        const existingIndex = prevCommentsNum.findIndex(
          (item) => item.commentPostId === postId
        );

        if (existingIndex !== -1) {
          const updatedComments = [...prevCommentsNum];
          updatedComments[existingIndex] = {
            ...updatedComments[existingIndex],
            commentPostNum: data?.comments_count,
          };
          return updatedComments;
        } else {
          return [
            ...prevCommentsNum,
            { commentPostId: postId, commentPostNum: data?.comments_count },
          ];
        }
      });
      setIsRefetchTrending(true);
      setTimeout(() => {
        setIsRefetchTrending(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const [targetId, setTargetId] = useState(null);
  const [targetComment, setTargetComment] = useState(null);

  const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false);

  const { isAddPost, setIsRefetchTrending } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddPost) {
      setTargetId(null);
      setTargetComment(null);
    }
  }, [isAddPost]);

  useEffect(() => {
    if (itemKey === "edit comment") {
      setIsEditCommentModalOpen(true);
      setItemKey(null);
    } else if (itemKey === "delete comment") {
      deleteComment();
      setTargetId(null);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id, comment) => {
    setItemKey(key);
    if (id) {
      setTargetId(id);
    }
    if (comment) {
      setTargetComment(comment);
    }
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {}, [commentsList]);

  // Comment

  const {
    control: controlForm1,
    handleSubmit: handleSubmitForm1,
    reset: resetForm1,
    formState: { errors: errorsForm1 },
  } = useForm({
    defaultValues: {
      desc: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [descLength, setDescLength] = useState(0);

  async function commentPost(reqBody) {
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/posts/${postId}/comment`,
        {
          comment: reqBody.desc,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      resetForm1();
      setDescLength(0);
      getPostData(true);
    } catch (error) {}
  }

  async function deleteComment() {
    try {
      const { data } = await axios.delete(
        `https://admin.thefrondappadmin.com/api/comments/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getPostData(true);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  // Reply

  const [showReplies, setShowReplies] = useState([]); // Array to hold IDs of open replies

  const toggleReplies = (id) => {
    setShowReplies(
      (prev) =>
        prev.includes(id)
          ? prev.filter((itemId) => itemId !== id) // Remove ID if already exists
          : [...prev, id] // Add ID if not exists
    );
  };
  const {
    control: controlForm2,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    formState: { errors: errorsForm2 },
  } = useForm({
    defaultValues: {
      desc2: "",
    },
  });

  const [commentId, setCommentId] = useState(null);

  const [replyDescLength, setReplyDescLength] = useState(0);

  async function replyComment(reqBody) {
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/comments/${commentId}/reply`,
        {
          comment: reqBody.desc2,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      resetForm2();
      setReplyDescLength(0);
      getPostData(true);
      setCommentId(null);
    } catch (error) {}
  }

  // Like Comment

  async function likeComment(id) {
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/comments/${id}/toggle-like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getPostData(true);
    } catch (error) {
      toast(error?.response?.data?.message || "An error occurred", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  return (
    <>
      <form className="my-5" onSubmit={handleSubmitForm1(commentPost)}>
        <div className="flex items-start gap-5">
          <div className="flex-1">
            <Controller
              name="desc"
              control={controlForm1}
              rules={{
                required: {
                  value: true,
                  message: "Comment is required",
                },
                minLength: {
                  value: 2,
                  message: "Minimum number of characters is 2",
                },
                maxLength: {
                  value: 500,
                  message: "Maximum number of characters is 500",
                },
              }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  onInput={(e) => setDescLength(e.target.value.length)}
                  variant="bordered"
                  maxLength={500}
                  placeholder="Write a comment..."
                  labelPlacement="outside"
                  classNames={{
                    base: "input-noMargin flex-1 h-[40px]",
                    label:
                      "text-textColor font-normal smallPhone:text-sm text-[12px]",
                    input:
                      "px-[18px] pt-[10px] pb-[6px] border-none overflow-y-auto smallPhone:text-sm text-[10px] h-[40px] max-h-[40px]",
                    innerWrapper:
                      "p-0 min-h-[40px] max-h-[40px] overflow-hidden",
                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                      errorsForm1.desc?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[20px] min-h-[40px] max-h-[40px]`,
                  }}
                  isInvalid={errorsForm1.desc?.message ? true : false}
                  errorMessage={errorsForm1.desc?.message}
                />
              )}
            />
            {!errorsForm1.desc?.message && 500 - descLength > 0 ? (
              <p className="text-[.75rem] text-mainBlue mt-1">
                {500 - descLength} remaining characters
              </p>
            ) : null}
          </div>
          <button
            isDisabled={isDisabled}
            type="submit"
            className="bg-mainGradiant rounded-full w-[40px] h-[40px] flex justify-center items-center gap-2 ms-auto"
          >
            {isLoading ? (
              <span className="flex justify-center items-center text-white">
                <i className="fa-solid fa-spinner fa-spin"></i>
              </span>
            ) : (
              <>
                <i className="fa-solid fa-comment text-white fa-lg"></i>
              </>
            )}
          </button>
        </div>
      </form>
      <div className={`overflow-hidden`}>
        {commentsList?.length > 0 || listOfComments?.length > 0 ? (
          <div className={`flex-grow overflow-y-auto`}>
            <div className="flex flex-col gap-2.5">
              {(commentsList?.length > 0 ? commentsList : listOfComments).map(
                (item) => (
                  <div
                    key={item?.id}
                    className="flex justify-between rounded-[10px] overflow-hidden"
                  >
                    <div className="p-2.5 flex flex-col overflow-hidden flex-1">
                      <div className="flex items-start gap-2.5 mb-2">
                        <div
                          className={`min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px] rounded-full`}
                          style={{
                            backgroundImage: `url(${item?.user?.img_url})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100%",
                            objectFit: "cover",
                            backgroundPosition: "50%",
                          }}
                        ></div>
                        <div className="flex bg-socialIconBg w-full justify-between items-start px-3 py-2 rounded-[20px]">
                          <div className="flex-col gap-[3px]">
                            <p className="text-textColor font-bold text-base">
                              {item?.user?.name}
                            </p>
                            <p className="text-cardGrayColor text-sm mb-2">
                              {timeAgo(item?.updated_at)}
                            </p>
                            <p className="text-textColor leading-[22px] break-before-all">
                              {item?.comment}
                            </p>
                          </div>
                          {item?.my_comment && (
                            <Dropdown
                              classNames={{
                                content:
                                  "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                              }}
                              placement="left-start"
                            >
                              <DropdownTrigger>
                                <button
                                  className="w-[24px] h-[24px] mt-0.5"
                                  onClick={handleDotsClick}
                                >
                                  <img
                                    src={dots}
                                    alt="Dots"
                                    className="min-w-[24px] min-h-[24px]"
                                  />
                                </button>
                              </DropdownTrigger>
                              <DropdownMenu
                                aria-label="Post Actions"
                                onAction={(key) =>
                                  openModal(key, item?.id, item?.comment)
                                }
                              >
                                <DropdownItem
                                  textValue="edit comment"
                                  key="edit comment"
                                  className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  key="delete comment"
                                  className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                                  color="danger"
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          )}
                        </div>
                      </div>
                      <div
                        className={`flex items-center gap-5 ms-[55px] ${
                          item?.replies?.length > 0 && commentId !== item?.id
                            ? !showReplies.includes(item.id) && "mb-5"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() => {
                            likeComment(item?.id);
                          }}
                          className="flex items-center justify-start gap-[5px]"
                        >
                          <Image
                            src={likesIcon}
                            alt="Like"
                            className="w-[18px] h-[18px]"
                          />
                          {item?.like_count > 0 && (
                            <h4
                              className={`text-sm font-normal ${
                                item?.is_liked
                                  ? "text-transparent bg-clip-text bg-mainGradiant"
                                  : "text-textColor"
                              } leading-[18.4px]`}
                            >
                              {item?.like_count}
                            </h4>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            if (commentId) {
                              if (commentId === item?.id) {
                                setCommentId(null);
                                resetForm2();
                              } else {
                                setCommentId(null);
                                resetForm2();
                                setCommentId(item?.id);
                              }
                            } else {
                              setCommentId(item?.id);
                            }
                          }}
                          className={`text-sm text-textColor w-fit`}
                        >
                          Reply
                        </button>
                        {item?.replies?.length > 0 && (
                          <button
                            onClick={() => toggleReplies(item.id)}
                            className={`text-sm text-textColor w-fit`}
                          >
                            {!showReplies.includes(item.id)
                              ? `Hide ${item?.replies?.length} ${
                                  item.replies?.length > 1 ? "Replies" : "Reply"
                                }`
                              : `Show ${item?.replies?.length} ${
                                  item.replies?.length > 1 ? "Replies" : "Reply"
                                }`}
                          </button>
                        )}
                      </div>
                      {commentId === item?.id && (
                        <form
                          className="ms-[55px]"
                          onSubmit={handleSubmitForm2(replyComment)}
                        >
                          <div className="flex items-start gap-5 my-5">
                            <div className="flex-1">
                              <Controller
                                name="desc2"
                                control={controlForm2}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Reply is required",
                                  },
                                  minLength: {
                                    value: 2,
                                    message:
                                      "Minimum number of characters is 2",
                                  },
                                  maxLength: {
                                    value: 500,
                                    message:
                                      "Maximum number of characters is 500",
                                  },
                                }}
                                render={({ field }) => (
                                  <Textarea
                                    {...field}
                                    onInput={(e) =>
                                      setReplyDescLength(e.target.value.length)
                                    }
                                    variant="bordered"
                                    maxLength={500}
                                    placeholder="Write a reply..."
                                    labelPlacement="outside"
                                    classNames={{
                                      base: "input-noMargin flex-1 h-[40px]",
                                      label:
                                        "text-textColor font-normal smallPhone:text-sm text-[12px]",
                                      input:
                                        "px-[18px] pt-[10px] pb-[6px] border-none overflow-y-auto smallPhone:text-sm text-[10px] h-[40px] max-h-[40px]",
                                      innerWrapper:
                                        "p-0 min-h-[40px] max-h-[40px] overflow-hidden",
                                      inputWrapper: `border-[0.86px] p-0 border-solid ${
                                        errorsForm2.desc2?.message
                                          ? "invalid-text"
                                          : "border-[#E5E4EB]"
                                      } rounded-[20px] min-h-[40px] max-h-[40px]`,
                                    }}
                                    isInvalid={
                                      errorsForm2.desc2?.message ? true : false
                                    }
                                    errorMessage={errorsForm2.desc2?.message}
                                  />
                                )}
                              />
                              {!errorsForm2.desc2?.message &&
                              500 - replyDescLength > 0 ? (
                                <p className="text-[.75rem] text-mainBlue mt-1">
                                  {500 - replyDescLength} remaining characters
                                </p>
                              ) : null}
                            </div>
                            <button
                              isDisabled={isDisabled}
                              type="submit"
                              className="bg-mainGradiant rounded-full w-[40px] h-[40px] flex justify-center items-center gap-2 ms-auto"
                            >
                              {isLoading ? (
                                <span className="flex justify-center items-center text-white">
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </span>
                              ) : (
                                <>
                                  <i className="fa-solid fa-comment text-white fa-lg"></i>
                                </>
                              )}
                            </button>
                          </div>
                        </form>
                      )}

                      {!showReplies.includes(item.id) && (
                        <CommentReplies
                          getPostData={getPostData}
                          listOfReplies={item.replies}
                        />
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : null}
      </div>
      {isEditCommentModalOpen && targetComment && (
        <EditCommentModal
          isOpen={isEditCommentModalOpen}
          onClose={() => setIsEditCommentModalOpen(false)}
          editComment={targetComment}
          editId={targetId}
          getPostData={getPostData}
        />
      )}
    </>
  );
}
