export const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };
  
  export const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };
  
  export const formatDuration = (duration) => {
    // Remove PT prefix and handle hours (H) and minutes (M)
    const durationStr = duration.replace('PT', '');
    const hours = durationStr.match(/(\d+)H/)?.[1] || '0';
    const minutes = durationStr.match(/(\d+)M/)?.[1] || '0';
    
    return `${hours}h ${minutes}m`;
  };