import {
  Avatar,
  DatePicker,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../../Assets/popup/cross-square.svg";
import { AuthContext } from "Context/AuthContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import addTaskIcon from "../../../Assets/frond-details/tasklist/tasklist-icon.svg";
import userIcon from "../../../Assets/frond-details/tasklist/user.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import up from "../../../Assets/create-frond/up.svg";
import calendar from "../../../Assets/create-frond/calendar.svg";
import { parseDate } from "@internationalized/date";
import checkIcon from "../../../Assets/my-fronds/check.svg";

const TimelineAddTask = ({ frondId, taskId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState(new Set());
  const [users, setUsers] = useState([]);
  const [addToTasks, setAddToTasks] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      date: null,
      title: "",
      desc: "",
      status: "",
    },
  });

  useEffect(() => {
    if (frondId) {
      getMembers();
    }
  }, [frondId]);

  useEffect(() => {}, [selectedUser]);

  const [descLength, setDescLength] = useState(0);

  const getTaskData = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/events/${taskId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const activeUsers = data?.response?.data?.assigned_to.filter(
        (user) => user.status === true
      );

      setSelectedUser(new Set(activeUsers.map((user) => user.name)));

      setValue(
        "name",
        activeUsers.map((user) => user.name)
      );

      const dateArr = data?.response?.data?.event?.due_date?.split("-");

      setValue("desc", data?.response?.data?.event?.description);
      setValue("title", data?.response?.data?.event?.title);

      if (data?.response?.data?.event?.status === "pending") {
        setValue("status", "Pending");
      } else if (data?.response?.data?.event?.status === "in_progress") {
        setValue("status", "In Progress");
      } else if (data?.response?.data?.event?.status === "completed") {
        setValue("status", "Completed");
      }

      setValue(
        "date",
        parseDate(
          `${dateArr[0]}-${dateArr[1].padStart(2, "0")}-${dateArr[2].padStart(
            2,
            "0"
          )}`
        )
      );
      setDescLength(data?.response?.data?.task?.description.length || 0);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (taskId) {
      getTaskData();
    }
  }, [taskId]);

  const { token } = useContext(AuthContext);
  const { setIsAddEvent, setIsTaskError, setCloseAddTaskModal, setIsAddTask } =
    useContext(PopUpContext);

  async function addTask(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    const selectedUserNames = Array.from(selectedUser);

    const targetUserId = users
      .filter((user) => selectedUserNames.includes(user.name))
      .map((user) => user.id);

    let statusType = null;
    if (reqBody.status === "Pending") {
      statusType = "pending";
    } else if (reqBody.status === "In Progress") {
      statusType = "in_progress";
    } else if (reqBody.status === "Completed") {
      statusType = "completed";
    }

    const payload = {
      title: reqBody.title,
      description: reqBody.desc,
      type: "task",
    };

    if (statusType) {
      payload.status = statusType;
    }

    if (reqBody.date) {
      payload.due_date = `${reqBody.date.year}/${reqBody.date.month}/${reqBody.date.day}`;
    }

    if (targetUserId) {
      payload.user_ids = targetUserId;
    }

    try {
      if (taskId) {
        const { data } = await axios.put(
          `https://admin.thefrondappadmin.com/api/events/${taskId}`,
          payload,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        toast("Updates saved", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      } else {
        payload.create_in_tasks = true;
        const { data } = await axios.post(
          `https://admin.thefrondappadmin.com/api/projects/${frondId}/events`,
          payload,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // if (addToTasks) {
        // setIsAddTask(true);
        // setTimeout(() => {
        //   setIsAddTask(false);
        // }, 1000);
        // }

        toast("Task created successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });

        // if (targetUserId?.length > 0) {
        //   pushNotification(targetUserId, reqBody.title);
        // }
      }

      setIsAddTask(true);
      setTimeout(() => {
        setIsAddTask(false);
      }, 1000);

      setIsAddEvent(true);
      setCloseAddTaskModal(true);
      setTimeout(() => {
        setIsAddEvent(false);
        setCloseAddTaskModal(false);
      }, 1000);
      reset();
      setAddToTasks(false);
      setSelectedUser(new Set([]));
    } catch (error) {
      toast(error.response?.data?.message || "An error occurred", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }

    setIsDisabled(false);
    setIsLoading(false);
  }

  const getMembers = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/projects/${frondId}/membersassign`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUsers(data);
    } catch (error) {
      toast(error.response.data.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    const hasErrors =
      errors.name?.message ||
      errors.date?.message ||
      errors.title?.message ||
      errors.desc?.message;

    if (hasErrors) {
      setIsTaskError(true);
      setTimeout(() => {
        setIsTaskError(false);
      }, 1000);
    } else {
      setIsTaskError(true);
      setTimeout(() => {
        setIsTaskError(false);
      }, 1000);
    }
  }, [
    errors.name?.message,
    errors.date?.message,
    errors.title?.message,
    errors.desc?.message,
  ]);

  const dateValue = watch("date");
  const statusValue = watch("status");

  const statusList = ["Pending", "In Progress", "Completed"];

  // useEffect(() => {
  //   if (descLength > 0) {
  //     setIsTaskError(true);
  //   } else {
  //     setIsTaskError(true);
  //   }
  // }, [descLength]);

  useEffect(() => {
    setIsTaskError(true);
  }, [descLength]);

  useEffect(() => {
    if (descLength === 0) {
      setIsTaskError(true);
    }
  }, [descLength]);

  return (
    <>
      <form onSubmit={handleSubmit(addTask)}>
        <div
          className={`relative about-edit-img sm:min-h-[295px] sm:max-h-[calc(100%+120px)] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%] mb-[50px]`}
        >
          <div className="content flex flex-col gap-5 items-center">
            <Controller
              name="name"
              control={control}
              rules={{}}
              render={({ field }) => (
                <Select
                  {...field}
                  isInvalid={errors.name?.message ? true : false}
                  errorMessage={errors.name?.message}
                  selectionMode="multiple"
                  selectedKeys={selectedUser}
                  onSelectionChange={(selected) => {
                    const updatedSet = new Set(selected.values());
                    setSelectedUser(updatedSet);
                  }}
                  selectorIcon={
                    <img src={up} alt="Arrow" className="w-[12px] h-[12px]" />
                  }
                  items={users}
                  startContent={
                    [...selectedUser]?.length === 0 ? (
                      <img
                        src={userIcon}
                        alt="User Icon"
                        className="w-[18px] h-[18px]"
                      />
                    ) : null
                  }
                  placeholder="Assigned to"
                  variant="bordered"
                  classNames={{
                    base: ` ${
                      [...selectedUser].length > 1
                        ? "min-h-[100px] h-[100px] max-h-[100px] overflow-hidden"
                        : "min-h-[39px] h-[39px]"
                    }]`,
                    trigger: `rounded-[8px] px-4 border-[0.86px] border-solid flex-1 overflow-hidden ${
                      errors.name?.message ? "invalid-text" : "border-[#E5E4EB]"
                    }`,
                    mainWrapper: "flex-1 max-h-full",
                    innerWrapper:
                      "user-select-input overflow-y-auto min-w-[calc(100%+40px)]",
                    value: `text-cardGrayColor phone:text-base text-[10px] flex flex-wrap gap-2 flex-col ${
                      [...selectedUser].length > 2 ? "pt-5" : ""
                    }`,
                  }}
                  renderValue={(items) => {
                    return items.map((item) => (
                      <div key={item.key} className="flex items-center gap-2">
                        <Avatar
                          alt={item.data.name}
                          className="flex-shrink-0"
                          size="sm"
                          src={item.data.img_url}
                        />
                        <div className="flex flex-col">
                          <span>{item.data.name}</span>
                        </div>
                      </div>
                    ));
                  }}
                >
                  {(user) => (
                    <SelectItem key={user.name} textValue={user.name}>
                      <div className="flex gap-2 items-center">
                        <Avatar
                          alt={user.name}
                          className="flex-shrink-0"
                          size="sm"
                          src={user.img_url}
                        />
                        <div className="flex flex-col">
                          <span className="text-small">{user.name}</span>
                        </div>
                      </div>
                    </SelectItem>
                  )}
                </Select>
              )}
            />

            <div className="date-wrapper relative w-full">
              <div
                className={`absolute left-[45px] ${
                  errors.date?.message
                    ? "top-0 translate-y-[33%]"
                    : "top-[50%] translate-y-[-50%]"
                } text-cardGrayColor phone:text-base text-[10px] font-normal ${
                  dateValue ? "hidden" : "block"
                }`}
              >
                Due Date
              </div>
              <Controller
                name="date"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    variant="bordered"
                    selectorIcon={
                      <Image src={calendar} className="w-[18px] h-[18px]" />
                    }
                    isInvalid={!!errors.date?.message}
                    errorMessage={errors.date?.message}
                    classNames={{
                      selectorButton:
                        "order-first min-w-[18px] max-h-[18px] h-[18px] me-0",
                    }}
                    dateInputClassNames={{
                      base: `mt-0 ${
                        errors.date?.message ? "h-[68px]" : "h-[39px]"
                      }`,
                      inputWrapper: `rounded-[8px] border-[0.86px] p-0 border-solid ${
                        errors.date?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      }`,
                      innerWrapper: "px-[13px] h-full gap-x-[2px] no-action",
                      segment: `date-picker-frond phone:text-base text-[10px] font-normal ${
                        dateValue ? "block" : "hidden"
                      }`,
                    }}
                  />
                )}
              />
            </div>

            <Controller
              name="title"
              control={control}
              rules={{
                required: "Title is required",
                minLength: {
                  value: 3,
                  message: "Minimum number of characters is 3",
                },
                maxLength: {
                  value: 100,
                  message: "Maximum number of characters is 100",
                },
                validate: {
                  noTrailingSpace: (value) =>
                    !/\s$/.test(value) || "Title shouldn't end with a space",
                },
                pattern: {
                  value:
                    // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                    /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\:\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                  message:
                    "Title may contain characters, numbers and special characters",
                },
              }}
              render={({ field }) => (
                <Input
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  {...field}
                  type="text"
                  variant="bordered"
                  placeholder="*Task Title"
                  isInvalid={errors.title?.message ? true : false}
                  errorMessage={errors.title?.message}
                  classNames={{
                    base: `input-noMargin ${
                      errors.title?.message ? "h-[68px]" : "h-[39px]"
                    }`,
                    label:
                      "text-textColor font-normal phone:text-sm text-[10px]",
                    input: "border-none px-[18px] phone:text-base text-[10px]",
                    mainWrapper: `${
                      errors.title?.message ? "h-[68px]" : "h-[39px]"
                    }`,
                    innerWrapper: "h-[39px]",
                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                      errors.title?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] h-[39px]`,
                  }}
                />
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  className="w-[100%]"
                  {...field}
                  variant="bordered"
                  labelPlacement="outside"
                  selectedKeys={field.value ? [field.value] : []}
                  placeholder="Select Status"
                  classNames={{
                    base: `input-noMargin ${
                      errors.status?.message ? "h-[68px]" : "h-[39px]"
                    }`,
                    label: "text-textColor font-normal text-sm",
                    mainWrapper: "h-[42px]",
                    innerWrapper: "h-[42px] px-[18px]",
                    trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                      errors.status?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] flex items-center justify-between`,
                    value: `${
                      statusValue ? "text-textColor" : "text-cardGrayColor"
                    } phone:text-base text-[10px] font-normal`,
                    selectorIcon: "w-[18px] h-[18px]",
                  }}
                  selectorIcon={<img src={up} />}
                  isInvalid={errors.status?.message ? true : false}
                  errorMessage={errors.status?.message}
                >
                  {statusList.map((item) => (
                    <SelectItem key={item}>{item}</SelectItem>
                  ))}
                </Select>
              )}
            />

            <div className={`w-full mb-5`}>
              <Controller
                name="desc"
                control={control}
                rules={{
                  minLength: {
                    value: 2,
                    message: "Minimum number of characters is 2",
                  },
                  maxLength: {
                    value: 255,
                    message: "Maximum number of characters is 255",
                  },
                  // pattern: {
                  //   value:
                  //     /^[a-zA-Z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\/\\\:]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\:]+)*$/gi,
                  //   message:
                  //     "Task description may contain characters, numbers or special characters and shouldn't end with space",
                  // },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    onInput={(e) => setDescLength(e.target.value.length)}
                    maxLength={255}
                    type="text"
                    variant="bordered"
                    placeholder="*Add a task description..."
                    classNames={{
                      base: "input-noMargin",
                      label:
                        "text-textColor font-normal phone:text-sm text-[10px]",
                      input:
                        "border-none px-[18px] phone:text-base text-[10px]",
                      mainWrapper: "",
                      innerWrapper: " overflow-y-auto",
                      inputWrapper: `border-[0.86px] p-0 border-solid ${
                        errors.desc?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      } rounded-[8px] h-[102px] overflow-y-auto`,
                    }}
                    isInvalid={errors.desc?.message ? true : false}
                    errorMessage={errors.desc?.message}
                  />
                )}
              />
              {!errors.desc?.message && 255 - descLength > 0 ? (
                <p className="text-[.75rem] text-mainBlue mt-1">
                  {255 - descLength} remaining characters
                </p>
              ) : null}
            </div>

            {/* {!taskId && (
              <div className="w-full mb-5">
                <button
                  type="button"
                  className="flex items-center"
                  onClick={() => {
                    setAddToTasks(!addToTasks);
                  }}
                >
                  <div
                    className={`transition-all duration-300
                     w-[29px] h-[29px] opacity-100 me-[5px]
                 rounded-[7px] flex justify-center items-center bg-white`}
                  >
                    <div
                      className={`w-[21px] h-[21px] transition-all border-cardGrayColor duration-300 rounded-[7px] border-2 border-solid relative
                
               flex justify-center items-center`}
                    >
                      <div
                        className={`${
                          addToTasks ? "opacity-100" : "opacity-0"
                        } flex justify-center transition-all absolute inset-[-2px] duration-300 items-center rounded-[7px] bg-offWhite`}
                      >
                        <img
                          src={checkIcon}
                          alt="Check"
                          className="w-[12.61px] h-[8.41px]"
                        />
                      </div>
                    </div>
                  </div>
                  <span className="text-textColor font-normal">
                    Add to tasks
                  </span>
                </button>
              </div>
            )} */}
          </div>
        </div>
        <ModalFooter className="shadow-modalShadow">
          <button
            disabled={isDisabled}
            type="submit"
            className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
          >
            {isLoading ? (
              <span className="flex justify-center items-center text-white">
                <i className="fa-solid fa-spinner fa-spin"></i>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </ModalFooter>
      </form>
    </>
  );
};

export default TimelineAddTask;
