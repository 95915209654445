import { Error500Context } from "Context/Error500Context";
import { SettingsContext } from "Context/SettingsContext";
import useSettings from "hooks/useSettings";
import React, { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";

const ProtectedDiscover = (props) => {
  const { frondId } = useParams();
  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);
  if (frondSettings.can_edit_discover) {
    return props.children;
  } else if (frondSettings.is_owner) {
    return props.children;
  } else {
    return <Navigate to={`/frond/about/${frondId}`}></Navigate>;
  }
};

export default ProtectedDiscover;
