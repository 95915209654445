import { Clock } from "lucide-react";
import { FlightSegment } from "./FlightSegment";

export const FlightDetails = ({ itinerary, isReturn }) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 text-sm text-gray-600">
        <Clock className="w-4 h-4" />
        <span>Total duration: {itinerary.duration.replace("PT", "")}</span>
      </div>

      <div className="space-y-2">
        {itinerary.segments.map((segment, index) => (
          <div key={index}>
            {index > 0 && (
              <div className="py-2 px-4 bg-gray-50 rounded-md">
                <p className="text-sm text-gray-500">
                  Layover: {segment.departure.iataCode}
                </p>
              </div>
            )}
            <FlightSegment {...segment} />
          </div>
        ))}
      </div>
    </div>
  );
};
