import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import closeIcon from "../../../../Assets/popup/cross-square.svg";
import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import RemoveComponent from "../../../../pages/CreateAFrond/RemoveComponent";
import "../../modalStyle.css";
import { AuthContext } from "Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import removeImg from "../../../../Assets/frond-details/removed-img.svg";
import placholderIcon from "../../../../Assets/frond-details/placholder-icon.svg";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import Doka. For testing purpose only, if you're intrested in using Doka
// in your project please purchase a license at https://pqina.nl/doka/
import "../../vendor/doka.min.css";
import { create } from "../../vendor/doka.esm.min";
import uplodaIcon from "../../../../Assets/create-frond/Upload.svg";
import { PopUpContext } from "Context/PopUpContext";

const AddFile = ({ isOpen, onClose, frondId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDokaEditorActive, setIsDokaEditorActive] = useState(false);

  const imgTitle = `<Image src=${uplodaIcon} alt="Upload" /> <p>Drag and Drop File here</p> <p>Or</p> <span>Browse Files</span>`;

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
    // FilePondPluginFileEncode,
  );

  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      file: "",
    },
  });

  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (files.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [files]);

  const { setIsAddScrapbookFile } = useContext(PopUpContext);

  async function addFile(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/scrapbook-files/${frondId}`,
        {
          file: uploadedFile,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.status === 200) {
        toast("File added Successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
        setIsAddScrapbookFile(true);
        setTimeout(() => {
          setIsAddScrapbookFile(false);
        }, 1000);
        onClose();
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        onClose();
      }}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Add File
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(addFile)}>
                <div className="max-h-[60vh] overflow-y-auto w-[calc(100%+17px)]">
                  <ModalBody className="w-[calc(100%-17px)] item-center">
                    <div className="relative about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                      <FilePond
                        files={files}
                        allowReorder={true}
                        allowMultiple={false}
                        allowFileEncode={false}
                        onupdatefiles={setFiles}
                        onpreparefile={(file, output) => {
                          setUploadedFile(output);
                        }}
                        imageResizeTargetWidth={1000}
                        imageResizeTargetHeight={500}
                        imageResizeUpscale={false}
                        imageResizeMode="force"
                        acceptedFileTypes={["image/*"]}
                        name="file"
                        imageEditEditor={create({
                          cropMinImageWidth: 500,
                          cropMinImageHeight: 146,
                        })}
                        labelIdle={imgTitle}
                        allowImagePreview={true}
                      />
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow">
                  <button
                    disabled={isDisabled || uploadedFile.length === 0}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] sm:w-[234px] w-[117px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default AddFile;
