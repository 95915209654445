import React from "react";
import { DollarSign } from "lucide-react";

const PriceTag = ({ price }) => {
  const displayPrice =
    Number(price.amount) === 0
      ? "Free"
      : `${price.amount} ${price.currencyCode}`;

  return (
    <div className="absolute top-4 right-4">
      <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-sm font-medium shadow-sm">
        <DollarSign className="w-4 h-4 inline-block mr-1 relative -top-[1px]" />
        {displayPrice}
      </span>
    </div>
  );
};

export default PriceTag;
