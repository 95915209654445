import { LoadingContext } from "Context/LoadingContext";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Image } from "@nextui-org/react";
import likeHeart from "../../Assets/profile/interests/likeHeart.svg";
import axios from "axios";
import { toast } from "react-toastify";

export default function Interests() {
  const { isInterestsLoaded, setIsInterestsLoaded } =
    useContext(LoadingContext);

  const [showSkeleton, setShowSkeleton] = useState(true);

  let authToken = localStorage.getItem("token");

  useLayoutEffect(() => {
    if (!isInterestsLoaded) {
      setTimeout(() => {
        setIsInterestsLoaded(true);
      }, 2000);
    }
  }, [isInterestsLoaded]);

  useEffect(() => {
    if (isInterestsLoaded) {
      const timer = setTimeout(() => {
        setShowSkeleton(false);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isInterestsLoaded]);

  const [selectedInterests, setSelectedInterests] = useState([]);

  const [allInterests, setAllInterests] = useState([]);

  const toggleInterest = (interest) => {
    const updatedSelectedInterests = selectedInterests.includes(interest)
      ? selectedInterests.filter((item) => item !== interest)
      : [...selectedInterests, interest];

    setSelectedInterests(updatedSelectedInterests);
  };

  const getInterests = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/interests`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setAllInterests(data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getInterests();
  }, []);

  const getUserInterests = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/user/interests`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const interestNames = data.map((interest) => interest.name);

      setSelectedInterests(interestNames || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getUserInterests();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const setInterests = async (selectedInterestIds) => {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/user/interests`,
        {
          interests: selectedInterestIds,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast("Interests updated successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
    setIsDisabled(false);
  };

  return (
    <div
      className={`hidden lg:block p-5 right-5 lg:min-w-[317px] lg:max-w-[317px] bg-white rounded-4xl border-2 border-solid border-grayBorder fixed z-20 no-focus transition-all duration-400 ${
        !isInterestsLoaded ? "opacity-0" : "opacity-100"
      }`}
    >
      <div className="flex justify-between items-center mb-[15px]">
        <h2 className="text-lg text-textColor font-bold leading-[20.7px]">
          Interests
        </h2>
        <button
          disabled={isDisabled}
          onClick={() => {
            const selectedInterestIds = allInterests
              .filter((item) => selectedInterests.includes(item.name))
              .map((item) => item.id);

            setInterests(selectedInterestIds);
          }}
          className="bg-mainGradiant text-white text-sm w-[80px] h-[30px] rounded-full flex justify-center items-center"
        >
          {isLoading ? (
            <span className="flex justify-center items-center text-white">
              <i className="fa-solid fa-spinner fa-spin"></i>
            </span>
          ) : (
            "Save"
          )}
        </button>
      </div>
      <div className="grid grid-cols-3 gap-x-2.5 gap-y-5 items-start">
        {allInterests.map((interest) => (
          <div
            key={interest?.id}
            onClick={() => toggleInterest(interest?.name)}
            className={`${
              showSkeleton ? "pointer-events-none" : "cursor-pointer"
            } flex flex-col items-center gap-2`}
          >
            <div
              className={`relative rounded-[17px] overflow-hidden ${
                selectedInterests.includes(interest?.name)
                  ? "gradiantBorder largeBorder shadow-goalsShadow"
                  : ""
              }`}
            >
              <div
                className={`bg-gray-200 animate-pulse relative w-[80px] h-[80px] ${
                  showSkeleton ? "opacity-100 z-40" : "opacity-0 z-10"
                }`}
              ></div>

              <Image
                src={require(`../../Assets/profile/interests/${interest?.name}.webp`)}
                classNames={{
                  wrapper: `object-cover block absolute z-20 inset-0 min-w-full`,
                }}
                className={`size-full object-cover block`}
              />
              <div
                className={`absolute inset-0 bg-[#BBBBBB80] flex justify-center items-center z-30 transition-all duration-300 ${
                  selectedInterests.includes(interest?.name)
                    ? "opacity-100"
                    : "opacity-0"
                }`}
              >
                <Image
                  src={likeHeart}
                  alt="Selected"
                  className="relative z-20"
                />
              </div>
            </div>
            <h4
              className={`font-bold text-sm text-center ${
                selectedInterests.includes(interest?.name)
                  ? "bg-mainGradiant bg-clip-text text-transparent"
                  : "text-textColor"
              }`}
            >
              {interest?.name}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
}
