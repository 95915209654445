import React, { useContext, useEffect, useMemo, useState } from "react";
import calendarIcon from "../../../Assets/frond-details/discover/calendar.svg";
import geolocationIcon from "../../../Assets/frond-details/discover/geolocation.svg";
import categoryIcon from "../../../Assets/frond-details/discover/category.svg";
import plusIcon from "../../../Assets/frond-details/discover/plus-circle.svg";
import userIcon from "../../../Assets/frond-details/discover/profile-user.svg";
import messageEditIcon from "../../../Assets/frond-details/discover/message-edit.svg";
import {
  Select,
  DatePicker,
  DateRangePicker,
  Image,
  Input,
  SelectItem,
  SelectSection,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import calendar from "../../../Assets/create-frond/calendar.svg";
import arrowRight from "../../../Assets/discover/arrow-right.svg";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import DiscoverModal from "./DiscoverModal";
import { parseDate } from "@internationalized/date";
import RemoveComponent from "../../../pages/CreateAFrond/RemoveComponent";
import { WithContext as ReactTags } from "react-tag-input";
import locationIcon from "../../../Assets/create-frond/location.svg";
import { data } from "autoprefixer";
import countriesList from "../../../utils/countries.json";
import up from "../../../Assets/create-frond/up.svg";
import { CashedDataContext } from "Context/CashedDataContext";
import { ChevronDown, ChevronUp, Search } from "lucide-react";

export default function Discover({ flag }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [frondData, setFrondData] = useState([]);

  const labelDateHtml = `Date <span class='text-xs text-cardGrayColor'>(Required)</span>`;
  const labelLocationHtml = `Departure <span class='text-xs text-cardGrayColor'>(Required)</span>`;
  const labelDestinationHtml = `Destination <span class='text-xs text-cardGrayColor'>(Required)</span>`;
  const labelGuestsHtml = `Guests <span class='text-xs text-cardGrayColor'>(Required)</span>`;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dateRange: "",
      location: "",
      destination: "",
      departureCode: "",
      destinationCode: "",
      recommendations: [],
      guests: "",
    },
  });

  const dateValue = watch("dateRange");
  const locationValue = watch("location");
  const destinationValue = watch("destination");
  const recommendationsValue = watch("recommendations");
  const guestsValue = watch("guests");
  const departureCodeValue = watch("departureCode");
  const destinationCodeValue = watch("destinationCode");

  const { frondId } = useParams();
  let authToken = localStorage.getItem("token");

  const getFrondData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const fetchedData = data?.response?.data;

      setFrondData(fetchedData);

      if (fetchedData?.frond?.start_date && fetchedData?.frond?.end_date) {
        const start = fetchedData?.frond?.start_date.split("/");
        const end = fetchedData?.frond?.end_date.split("/");
        setStartDateArr(start);
        setEndDateArr(end);

        setValue("dateRange", {
          start: parseDate(
            `${start[2]}-${start[1].padStart(2, "0")}-${start[0]}`
          ),
          end: parseDate(`${end[2]}-${end[1].padStart(2, "0")}-${end[0]}`),
        });
      }

      if (Array.isArray(fetchedData.tags)) {
        const processedTags = fetchedData.tags
          .map((tag) => ({
            id: tag.name.en || "",
            text: tag.name.en || "",
          }))
          .filter((tag) => tag.text);

        setValue("recommendations", processedTags);
        setTags(processedTags);
      }

      if (fetchedData?.members?.length > 0) {
        setValue("guests", fetchedData?.members?.length);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Get Access Token

  const [accessToken, setAccessToken] = useState("");

  async function getAccessToken() {
    let data = {
      client_id: "04XfDlgOXbeeczYEIOndU1DbH0R6Ql8C",
      client_secret: "UHWKi9VcTsQLlVjc",
      grant_type: "client_credentials",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://test.api.amadeus.com/v1/security/oauth2/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    const response = axios
      .request(config)
      .then((response) => {
        setAccessToken(response?.data?.access_token);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Edit Data

  const [editDate, setEditDate] = useState(false);

  const handleSaveDate = () => {
    const currentDate = new Date();

    if (dateValue?.start && dateValue?.end) {
      const startDate = new Date(
        dateValue.start.year,
        dateValue.start.month - 1,
        dateValue.start.day
      );
      const endDate = new Date(
        dateValue.end.year,
        dateValue.end.month - 1,
        dateValue.end.day
      );

      if (startDate < currentDate) {
        setEditDate(true);

        setError("dateRange", {
          type: "manual",
          message: "Start date is in the past",
        });
        return;
      } else if (endDate < currentDate) {
        setEditDate(true);

        setError("dateRange", {
          type: "manual",
          message: "End date is in the past",
        });
        return;
      }

      if (endDate > currentDate && startDate > currentDate) {
        setEditDate(false);
      }

      setStartDateArr([
        dateValue.start.day,
        dateValue.start.month,
        dateValue.start.year,
      ]);
      setEndDateArr([
        dateValue.end.day,
        dateValue.end.month,
        dateValue.end.year,
      ]);
      setError("dateRange", { type: "manual", message: "" });
    } else {
      setError("dateRange", { type: "manual", message: "Date is required" });
    }
  };

  useEffect(() => {
    if (dateValue) {
      handleSaveDate();
    }
  }, [dateValue]);

  // Edit Location

  const [departureIata, setDepartureIata] = useState([]);
  const [destinationIata, setDestinationIata] = useState([]);

  const [editLocation, seEditLocation] = useState(false);

  const [hotelId, setHotelId] = useState("");

  const handleSaveLocation = () => {
    if (locationValue.name && destinationValue.name) {
      const locationCityName = locationValue.name;
      const locationCityCode = countriesList
        .filter((country) =>
          country.states.some((state) => state.name === locationCityName)
        )
        .map((matchedCountry) => matchedCountry.iso2);

      const destinationCityName = destinationValue.name;
      const destinationCityCode = countriesList
        .filter((country) =>
          country.states.some((state) => state.name === destinationCityName)
        )
        .map((matchedCountry) => matchedCountry.iso2);

      setError("location", { type: "manual", message: "" });
      setError("destination", { type: "manual", message: "" });

      getIata(locationCityName, locationCityCode, "departure");
      getIata(destinationCityName, destinationCityCode, "destination");

      const destinationLat = countriesList
        .flatMap((country) => country.states)
        .find((state) => state.name === destinationCityName)?.latitude;

      const destinationLong = countriesList
        .flatMap((country) => country.states)
        .find((state) => state.name === destinationCityName)?.longitude;

      getTours(destinationLat, destinationLong);
    } else {
      if (!locationValue.name) {
        setError("location", {
          type: "manual",
          message: "Departure is required",
        });
      }
      if (!destinationValue.name) {
        setError("destination", {
          type: "manual",
          message: "Destination is required",
        });
      }
    }
  };

  async function getIata(cityName, cityCode, valToAdd) {
    setValue("departureCode", "");
    setValue("destinationCode", "");
    try {
      const response = await axios.get(
        `https://test.api.amadeus.com/v1/reference-data/locations/cities`,
        {
          headers: {
            "content-type": "application/vnd.amadeus+json ",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            keyword: cityName,
            countryCode: cityCode[0],
            include: "AIRPORTS",
          },
        }
      );
      if (valToAdd === "departure") {
        const airports = response.data.included.airports;

        const airportDetails = Object.keys(airports).map((key) => ({
          name: airports[key].name,
          code: airports[key].iataCode,
        }));

        if (airportDetails.length > 0) {
          setDepartureIata(airportDetails);
        }
      }

      if (valToAdd === "destination") {
        const airports = response.data.included.airports;

        const airportDetails = Object.keys(airports).map((key) => ({
          name: airports[key].name,
          code: airports[key].iataCode,
        }));

        if (airportDetails.length > 0) {
          setDestinationIata(airportDetails);

          // You can pass the entire array or a specific airport code to `getHotelId`.
          // if (airportDetails[0]?.code) {
          //   getHotelId(airportDetails[0].code);
          // }
        }
      }
    } catch (error) {
      if (valToAdd === "departure") {
        seEditLocation(true);
        setValue("departureCode", "");
        setValue("destinationCode", "");
        setDepartureIata([]);
        setError("location", {
          type: "manual",
          message: "Departure airport not found",
        });
      }
      if (valToAdd === "destination") {
        seEditLocation(true);
        setValue("departureCode", "");
        setValue("destinationCode", "");
        setDestinationIata([]);
        setError("destination", {
          type: "manual",
          message: "Destination airport not found",
        });
      }
    }
  }

  async function getHotelId() {
    try {
      const response = await axios.get(
        `https://test.api.amadeus.com/v1/reference-data/locations/hotels/by-city`,
        {
          headers: {
            "content-type": "application/vnd.amadeus+json ",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            cityCode: destinationCodeValue,
            hotelSource: "ALL",
          },
        }
      );
      setHotelId(response?.data?.data?.slice(0, 4));
      seEditLocation(false);
    } catch (error) {
      seEditLocation(true);
      setError("destinationCode", {
        type: "manual",
        message: "Destination hotels not found",
      });
    }
  }

  useEffect(() => {}, [departureIata]);
  useEffect(() => {}, [destinationIata]);

  // Flight Offers

  const [flightOffer, setFlightOffer] = useState([]);

  async function getFlightOffers() {
    setIsLoading(true);
    setIsDisabled(true);
    const originLocationCode = departureCodeValue;
    const destinationLocationCode = destinationCodeValue;

    try {
      const response = await axios.get(
        `https://test.api.amadeus.com/v2/shopping/flight-offers`,
        {
          headers: {
            accept: "application/vnd.amadeus+json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            originLocationCode: originLocationCode,
            destinationLocationCode: destinationLocationCode,
            departureDate: `${startDateArr[2]}-${startDateArr[1]
              .toString()
              .padStart(2, "0")}-${startDateArr[0]
              .toString()
              .padStart(2, "0")}`,
            returnDate: `${endDateArr[2]}-${endDateArr[1]
              .toString()
              .padStart(2, "0")}-${endDateArr[0].toString().padStart(2, "0")}`,
            adults: guestsValue,
            max: 4,
          },
        }
      );
      setFlightOffer(response?.data?.data);
      setIsLoading(false);
      setIsDisabled(false);
      setIsModalOpen(true);
    } catch (error) {
      seEditLocation(true);
      setError("location", {
        type: "manual",
        message: "No offers found",
      });
    }
  }

  // Get Tours and Activities

  const [toursList, setToursList] = useState([]);

  async function getTours(lat, long) {
    try {
      const response = await axios.get(
        `https://test.api.amadeus.com/v1/shopping/activities`,
        {
          headers: {
            accept: "application/vnd.amadeus+json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            latitude: lat,
            longitude: long,
          },
        }
      );
      setToursList(response?.data?.data?.slice(0, 6));
    } catch (error) {}
  }

  // Edit Tags

  const [editTags, setEditTags] = useState(false);

  const [tags, setTags] = useState([]);
  const [tagError, setTagError] = useState("");
  const [tagLimit, setTagLimit] = useState(false);

  useEffect(() => {
    setValue("recommendations", tags);
    if (tags.length === 10) {
      setTagLimit(true);

      setTimeout(() => {
        setTagLimit(false);
      }, 1500);
    }
  }, [tags, setValue]);

  const handleValidation = (tags) => {
    for (let tag of tags) {
      if (tag.text.length < 2 || tag.text.length > 60) {
        return "Each tag must be between 2 and 60 characters";
      }
      if (
        !/^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+)*$/gi.test(
          tag.text
        )
      ) {
        return "Tag may contain characters, numbers or special characters and shouldn't end with space";
      }
    }
    return true;
  };

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    setValue("recommendations", newTags);

    const validation = handleValidation(newTags);
    if (validation === true) {
      setTagError("");
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    const validation = handleValidation(newTags);

    if (validation === true) {
      setTags(newTags);
      setValue("recommendations", newTags);
      setTagError("");
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  const handleSaveTags = () => {
    if (recommendationsValue?.length > 0) {
      setEditTags(false);

      setValue("recommendations", recommendationsValue);

      setError("recommendations", { type: "manual", message: "" });
    } else {
      setError("recommendations", {
        type: "manual",
        message: "Tags is required",
      });
      setTagError("Tags is required");
    }
  };

  // Edit Guests

  const [editGuests, setEditGuests] = useState(false);

  const handleSaveGuests = () => {
    if (guestsValue) {
      setEditGuests(false);

      setValue("guests", guestsValue);

      setError("guests", { type: "manual", message: "" });
    } else {
      setError("guests", {
        type: "manual",
        message: "Guests count is required",
      });
    }
  };

  // Searchable Depature Select

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const filteredAndSortedCountries = useMemo(() => {
    return countriesList
      .map((country) => {
        // Filter states within each country based on the search value
        const filteredStates = country.states?.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        );

        // If states match, return the country with the matching states
        if (filteredStates?.length > 0) {
          return { ...country, filteredStates };
        }

        // If no states match, check if the country itself matches
        if (
          country.name.toLowerCase().includes(search.toLowerCase()) ||
          country.capital.toLowerCase().includes(search.toLowerCase()) ||
          country.region.toLowerCase().includes(search.toLowerCase())
        ) {
          return { ...country, filteredStates: [] }; // No matching states
        }

        // If neither the country nor its states match, exclude this country
        return null;
      })
      .filter(Boolean) // Remove null entries
      .sort((a, b) => {
        const aValue = String(a[sortField]);
        const bValue = String(b[sortField]);
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
  }, [search, sortField, sortDirection]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  // Searchable Destination Select

  const [isOpen2, setIsOpen2] = useState(false);
  const [search2, setSearch2] = useState("");
  const [sortField2, setSortField2] = useState("name");
  const [sortDirection2, setSortDirection2] = useState("asc");
  const [selectedCountry2, setSelectedCountry2] = useState(null);

  const filteredAndSortedCountries2 = useMemo(() => {
    return countriesList
      .map((country) => {
        // Filter states within each country based on the search value
        const filteredStates2 = country.states?.filter((state) =>
          state.name.toLowerCase().includes(search2.toLowerCase())
        );

        // If states match, return the country with the matching states
        if (filteredStates2?.length > 0) {
          return { ...country, filteredStates2 };
        }

        // If no states match, check if the country itself matches
        if (
          country.name.toLowerCase().includes(search2.toLowerCase()) ||
          country.capital.toLowerCase().includes(search2.toLowerCase()) ||
          country.region.toLowerCase().includes(search2.toLowerCase())
        ) {
          return { ...country, filteredStates2: [] }; // No matching states
        }

        // If neither the country nor its states match, exclude this country
        return null;
      })
      .filter(Boolean) // Remove null entries
      .sort((a, b) => {
        const aValue = String(a[sortField2]);
        const bValue = String(b[sortField2]);
        return sortDirection2 === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
  }, [search2, sortField2, sortDirection2]);

  const handleSort2 = (field) => {
    if (sortField2 === field) {
      setSortDirection2((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortField2(field);
      setSortDirection2("asc");
    }
  };

  // Airports Select

  const [isOpenLocation, setIsOpenLocation] = useState(false);

  useEffect(() => {
    if (locationValue?.name && destinationValue?.name) {
      handleSaveLocation();
    }
  }, [locationValue, destinationValue]);

  useEffect(() => {
    if (departureCodeValue && destinationCodeValue) {
      setIsOpenLocation(true);
    } else {
      setIsOpenLocation(false);
    }
  }, [departureCodeValue, destinationCodeValue]);

  return (
    <section className="discover relative min-h-[620px]">
      <form
        className={`transition-all duration-300 ${
          isModalOpen
            ? "opacity-0 z-10 pointer-events-none"
            : "opacity-100 z-20"
        } flex flex-col absolute left-5 right-5`}
        onSubmit={handleSubmit(getFlightOffers)}
      >
        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4">
          {editDate ? (
            <div className="date-wrapper relative w-1/2">
              <Controller
                name="dateRange"
                control={control}
                render={({ field }) => (
                  <DateRangePicker
                    {...field}
                    visibleMonths={2}
                    labelPlacement="outside"
                    variant="bordered"
                    selectorIcon={
                      <Image src={calendar} className="w-[18px] h-[18px]" />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: labelDateHtml }}
                      />
                    }
                    isInvalid={!!errors.dateRange?.message}
                    errorMessage={errors.dateRange?.message}
                    classNames={{
                      selectorButton:
                        "order-first min-w-[18px] max-h-[18px] h-[18px] me-0",
                      base: `mt-0 w-full gap-y-[5px] ${
                        errors?.dateRange?.message ? "h-[90px]" : "h-[80px]"
                      }`,
                      inputWrapper: `rounded-[8px] border-[0.86px] p-0 border-solid ${
                        errors?.dateRange?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      }`,
                      innerWrapper: "px-[13px] h-full gap-x-[4px] no-action",
                      segment: `date-picker-frond phone:text-base text-[10px] font-normal`,
                    }}
                  />
                )}
              />
            </div>
          ) : (
            <div className="flex items-center gap-[13px]">
              <Image
                src={calendarIcon}
                alt="calendar"
                className="w-[24px] h-[24px]"
              />
              <div>
                <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                  Date
                </h4>
                <p className="text-base font-bold text-textColor leading-[22px]">
                  {startDateArr.length
                    ? `${months[startDateArr[1] - 1]} ${startDateArr[0]}`
                    : null}
                  {startDateArr?.length > 0 && endDateArr?.length > 0 && (
                    <span className="mx-1">-</span>
                  )}
                  {!startDateArr?.length && !endDateArr?.length && (
                    <span className="mx-1">-</span>
                  )}
                  {endDateArr.length
                    ? `${months[endDateArr[1] - 1]} ${endDateArr[0]}`
                    : null}
                </p>
              </div>
            </div>
          )}
          {editDate ? (
            <div className="flex items-center gap-2.5">
              <button
                onClick={handleSaveDate}
                type="button"
                className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
              >
                Save
              </button>
              <button
                onClick={() => setEditDate(false)}
                type="button"
                className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
              >
                Discard
              </button>
            </div>
          ) : (
            <button
              onClick={() => setEditDate(true)}
              type="button"
              className="flex items-center gap-2.5"
            >
              <Image
                src={messageEditIcon}
                alt="Edit Item"
                className="w-[24px] h-[24px]"
              />
              <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
                Edit
              </span>
            </button>
          )}
        </div>

        <div
          className={`flex flex-col justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder ${
            editLocation ? "py-5" : "pt-4 pb-4"
          } mb-4`}
        >
          <div className="item flex justify-between items-center gap-2.5 w-full">
            {editLocation ? (
              <>
                <div className="flex items-start gap-2.5 flex-1 lg:flex-nowrap flex-wrap">
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <div className="relative w-full">
                        <div
                          className="border rounded-lg bg-white shadow-sm cursor-pointer"
                          onClick={() => setIsOpen((prev) => !prev)}
                        >
                          <div className="p-3 flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              {selectedCountry ? (
                                <>
                                  <span className="text-2xl">
                                    {selectedCountry.emoji}
                                  </span>
                                  <span>{selectedCountry?.name}</span>
                                </>
                              ) : (
                                <span className="text-gray-500">Depature</span>
                              )}
                            </div>
                            <ChevronDown className="w-5 h-5 text-gray-500" />
                          </div>
                        </div>

                        {isOpen && (
                          <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-50 max-h-[400px] overflow-auto">
                            <div className="sticky top-0 bg-white border-b p-2">
                              <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                                <input
                                  type="text"
                                  className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                  placeholder="Search countries..."
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </div>
                              <div className="flex gap-2 mt-2">
                                <button
                                  className={`px-3 py-1 text-sm rounded ${
                                    sortField === "name"
                                      ? "bg-blue-100 text-blue-700"
                                      : "bg-gray-100"
                                  }`}
                                  onClick={() => handleSort("name")}
                                >
                                  Name{" "}
                                  {sortField === "name" &&
                                    (sortDirection === "asc" ? (
                                      <ChevronUp className="inline w-4 h-4" />
                                    ) : (
                                      <ChevronDown className="inline w-4 h-4" />
                                    ))}
                                </button>
                                <button
                                  className={`px-3 py-1 text-sm rounded ${
                                    sortField === "region"
                                      ? "bg-blue-100 text-blue-700"
                                      : "bg-gray-100"
                                  }`}
                                  onClick={() => handleSort("region")}
                                >
                                  Region{" "}
                                  {sortField === "region" &&
                                    (sortDirection === "asc" ? (
                                      <ChevronUp className="inline w-4 h-4" />
                                    ) : (
                                      <ChevronDown className="inline w-4 h-4" />
                                    ))}
                                </button>
                              </div>
                            </div>

                            <div>
                              {filteredAndSortedCountries.map((country) => (
                                <div key={country.iso2}>
                                  {/* If no matching states, display the country name */}
                                  {country.filteredStates.length === 0 ? (
                                    <div
                                      className="p-3 hover:bg-gray-50 cursor-pointer flex items-center gap-3"
                                      onClick={() => {
                                        setSelectedCountry(country);
                                        setValue("location", country);
                                        setIsOpen(false);
                                      }}
                                    >
                                      <span className="text-2xl">
                                        {country.emoji}
                                      </span>
                                      <div>
                                        <div className="font-medium">
                                          {country.name}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                          {country.capital} • {country.region}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    // If there are matching states, display only the states
                                    <div>
                                      <div className="p-3 font-medium text-gray-700">
                                        {country.name}
                                      </div>
                                      {country.filteredStates.map((state) => (
                                        <div
                                          key={state.id}
                                          className="p-3 hover:bg-gray-50 cursor-pointer flex items-center gap-3 pl-6"
                                          onClick={() => {
                                            setSelectedCountry(state);
                                            setValue("location", state);
                                            setIsOpen(false);
                                          }}
                                        >
                                          <span className="text-sm text-gray-700">
                                            {state.name}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}

                              {filteredAndSortedCountries.length === 0 && (
                                <div className="p-4 text-center text-gray-500">
                                  No countries found
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {errors?.location?.message && (
                          <p className="mt-2 text-xs text-[#f31260]">
                            {errors?.location?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name="destination"
                    control={control}
                    render={({ field }) => (
                      <div className="relative w-full">
                        <div
                          className="border rounded-lg bg-white shadow-sm cursor-pointer"
                          onClick={() => setIsOpen2((prev) => !prev)}
                        >
                          <div className="p-3 flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              {selectedCountry2 ? (
                                <>
                                  <span className="text-2xl">
                                    {selectedCountry2.emoji}
                                  </span>
                                  <span>{selectedCountry2?.name}</span>
                                </>
                              ) : (
                                <span className="text-gray-500">
                                  Destination
                                </span>
                              )}
                            </div>
                            <ChevronDown className="w-5 h-5 text-gray-500" />
                          </div>
                        </div>

                        {isOpen2 && (
                          <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-50 max-h-[400px] overflow-auto">
                            <div className="sticky top-0 bg-white border-b p-2">
                              <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                                <input
                                  type="text"
                                  className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                  placeholder="Search countries..."
                                  value={search2}
                                  onChange={(e) => setSearch2(e.target.value)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </div>
                              <div className="flex gap-2 mt-2">
                                <button
                                  className={`px-3 py-1 text-sm rounded ${
                                    sortField2 === "name"
                                      ? "bg-blue-100 text-blue-700"
                                      : "bg-gray-100"
                                  }`}
                                  onClick={() => handleSort2("name")}
                                >
                                  Name{" "}
                                  {sortField2 === "name" &&
                                    (sortDirection2 === "asc" ? (
                                      <ChevronUp className="inline w-4 h-4" />
                                    ) : (
                                      <ChevronDown className="inline w-4 h-4" />
                                    ))}
                                </button>
                                <button
                                  className={`px-3 py-1 text-sm rounded ${
                                    sortField2 === "region"
                                      ? "bg-blue-100 text-blue-700"
                                      : "bg-gray-100"
                                  }`}
                                  onClick={() => handleSort2("region")}
                                >
                                  Region{" "}
                                  {sortField2 === "region" &&
                                    (sortDirection2 === "asc" ? (
                                      <ChevronUp className="inline w-4 h-4" />
                                    ) : (
                                      <ChevronDown className="inline w-4 h-4" />
                                    ))}
                                </button>
                              </div>
                            </div>

                            <div>
                              {filteredAndSortedCountries2.map((country) => (
                                <div key={country.iso2}>
                                  {/* If no matching states, display the country name */}
                                  {country.filteredStates2.length === 0 ? (
                                    <div
                                      className="p-3 hover:bg-gray-50 cursor-pointer flex items-center gap-3"
                                      onClick={() => {
                                        setSelectedCountry2(country);
                                        setValue("destination", country);
                                        setIsOpen2(false);
                                      }}
                                    >
                                      <span className="text-2xl">
                                        {country.emoji}
                                      </span>
                                      <div>
                                        <div className="font-medium">
                                          {country.name}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                          {country.capital} • {country.region}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    // If there are matching states, display only the states
                                    <div>
                                      <div className="p-3 font-medium text-gray-700">
                                        {country.name}
                                      </div>
                                      {country.filteredStates2.map((state) => (
                                        <div
                                          key={state.id}
                                          className="p-3 hover:bg-gray-50 cursor-pointer flex items-center gap-3 pl-6"
                                          onClick={() => {
                                            setSelectedCountry2(state);
                                            setValue("destination", state);
                                            setIsOpen2(false);
                                          }}
                                        >
                                          <span className="text-sm text-gray-700">
                                            {state.name}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}

                              {filteredAndSortedCountries2.length === 0 && (
                                <div className="p-4 text-center text-gray-500">
                                  No countries found
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {errors?.destination?.message && (
                          <p className="mt-2 text-xs text-[#f31260]">
                            {errors?.destination?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </>
            ) : (
              <div className="flex items-center gap-[13px]">
                <Image
                  src={geolocationIcon}
                  alt="geolocation"
                  className="w-[24px] h-[24px]"
                />
                <div>
                  <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                    Location
                  </h4>
                  <p className="text-base font-bold text-textColor leading-[22px]">
                    {locationValue.name || "-"} {", "}
                    {destinationValue.name || "-"}
                  </p>
                </div>
              </div>
            )}

            {editLocation ? (
              <div className={`flex items-center gap-2.5 `}>
                {isOpenLocation && (
                  <button
                    onClick={getHotelId}
                    type="button"
                    className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
                  >
                    Save
                  </button>
                )}
                <button
                  onClick={() => seEditLocation(false)}
                  type="button"
                  className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
                >
                  Discard
                </button>
              </div>
            ) : (
              <button
                onClick={() => seEditLocation(true)}
                type="button"
                className="flex items-center gap-2.5"
              >
                <Image
                  src={messageEditIcon}
                  alt="Edit Item"
                  className="w-[24px] h-[24px]"
                />
                <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
                  Edit
                </span>
              </button>
            )}
          </div>
          {editLocation && (
            <div className="flex items-start gap-2.5 w-full">
              {departureIata?.length > 0 && (
                <Controller
                  name="departureCode"
                  control={control}
                  rules={{
                    required: "Departure airport is required",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      variant="bordered"
                      label="Departure airport"
                      labelPlacement="outside"
                      selectedKeys={field.value ? [field.value] : []}
                      placeholder="Select airport"
                      classNames={{
                        base: "input-noMargin h-[68px] custom-input-value",
                        label:
                          "text-textColor font-normal smallPhone:text-sm text-[12px]",
                        mainWrapper: "h-[42px]",
                        innerWrapper: "h-[42px] px-[18px]",
                        trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                          errors.departureCode?.message
                            ? "invalid-text"
                            : "border-[#E5E4EB]"
                        } rounded-[8px] flex items-center justify-between`,
                        value:
                          "text-cardGrayColor smallPhone:text-sm text-[10px] font-normal",
                        selectorIcon:
                          "smallPhone:w-[18px] smallPhone:h-[18px] w-[12px] h-[12px]",
                      }}
                      selectorIcon={<img src={up} />}
                      isInvalid={errors.departureCode?.message ? true : false}
                      errorMessage={errors.departureCode?.message}
                    >
                      {departureIata.map((item) => (
                        <SelectItem key={item.code}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  )}
                />
              )}
              {destinationIata?.length > 0 && (
                <Controller
                  name="destinationCode"
                  control={control}
                  rules={{
                    required: "Destination airport is required",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      variant="bordered"
                      label="Destination airport"
                      labelPlacement="outside"
                      selectedKeys={field.value ? [field.value] : []}
                      placeholder="Select airport"
                      classNames={{
                        base: "input-noMargin h-[68px] custom-input-value",
                        label:
                          "text-textColor font-normal smallPhone:text-sm text-[12px]",
                        mainWrapper: "h-[42px]",
                        innerWrapper: "h-[42px] px-[18px]",
                        trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                          errors.destinationCode?.message
                            ? "invalid-text"
                            : "border-[#E5E4EB]"
                        } rounded-[8px] flex items-center justify-between`,
                        value:
                          "text-cardGrayColor smallPhone:text-sm text-[10px] font-normal",
                        selectorIcon:
                          "smallPhone:w-[18px] smallPhone:h-[18px] w-[12px] h-[12px]",
                      }}
                      selectorIcon={<img src={up} />}
                      isInvalid={errors.destinationCode?.message ? true : false}
                      errorMessage={errors.destinationCode?.message}
                    >
                      {destinationIata.map((item) => (
                        <SelectItem key={item.code}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            </div>
          )}
        </div>

        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
          <div className="flex items-center gap-[13px]">
            <Image
              src={categoryIcon}
              alt="category"
              className="w-[24px] h-[24px]"
            />
            <div>
              <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                Category
              </h4>
              <p className="text-base font-bold text-textColor leading-[22px]">
                {frondData?.frond?.category}
              </p>
            </div>
          </div>
        </div>

        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4">
          {!editTags ? (
            <div className="flex items-center gap-[13px]">
              <Image src={plusIcon} alt="plus" className="w-[24px] h-[24px]" />
              <div>
                <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                  Recommendations
                </h4>
                <p>
                  {recommendationsValue?.length > 0
                    ? recommendationsValue?.map((item, idx) => (
                        <>
                          {idx !== 0 && (
                            <span className="text-base font-bold text-textColor leading-[22px]">
                              ,{" "}
                            </span>
                          )}
                          <span
                            key={idx}
                            className="text-base font-bold text-textColor leading-[22px]"
                          >
                            {item.text}
                          </span>
                        </>
                      ))
                    : "-"}
                </p>
              </div>
            </div>
          ) : (
            <Controller
              name="recommendations"
              control={control}
              rules={{ validate: handleValidation }}
              render={({ field }) => (
                <div className="w-1/2">
                  <label
                    className={`block ${
                      // || tagLimit (if there are tag limit enter that after tagError)
                      tagError ? "text-[#f31260]" : "text-textColor"
                    } phone:text-sm text-[10px] font-normal mb-2 transition-all duration-400`}
                    htmlFor="tags"
                  >
                    Recommendations{" "}
                    <span className="phone:text-xs text-[12px] text-cardGrayColor">
                      (Required)
                    </span>
                  </label>
                  <ReactTags
                    autoFocus={false}
                    {...field}
                    id="recommendations"
                    tags={tags}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    allowDragDrop={false}
                    inputFieldPosition="top"
                    separators={["Enter", "Comma"]}
                    placeholder="Add Recommendations ..."
                    // maxTags={10}
                    removeComponent={RemoveComponent}
                    classNames={{
                      // || tagLimit (if there are tag limit enter that after tagError)
                      tagInputField: `tagInputField bg-white border-[0.86px] w-full px-[18px] border-solid phone:placeholder:text-base placeholder:text-[10px] ${
                        tagError ? "invalid-text" : "border-[#E5E4EB]"
                      } rounded-[8px] h-[42px] placeholder: text-sm placeholder: font-normal phone:text-sm text-[12px] font-normal transition-all duration-400 no-focus`,
                      tag: `px-2.5 py-[6px] bg-[#1976D2] rounded-[20px] inline-flex text-white phone:text-sm text-[12px] items-center justify-center gap-1.5 mt-2 me-2
                      `,
                      editTagInput: "flex",
                    }}
                  />
                  {tagError && (
                    <p
                      className={`text-[#f31260] phone:text-xs text-[10px] mt-0.5 transition-opacity duration-400 ${
                        tagError ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      {tagError}
                    </p>
                  )}
                  {/* {tagLimit && (
                            <p
                              className={`text-[#f31260] text-xs mt-1 transition-opacity duration-400 ${
                      tagLimit? "opacity-100": "opacity-0"
                    }`}
                            >
                              Tag limit reached
                            </p>
                          )} */}
                </div>
              )}
            />
          )}

          {editTags ? (
            <div
              className={`flex items-center gap-2.5 ${
                errors?.location?.message ? "mt-0" : "mt-2.5"
              }`}
            >
              <button
                onClick={handleSaveTags}
                type="button"
                className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
              >
                Save
              </button>
              <button
                onClick={() => setEditTags(false)}
                type="button"
                className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
              >
                Discard
              </button>
            </div>
          ) : (
            <button
              onClick={() => setEditTags(true)}
              type="button"
              className="flex items-center gap-2.5"
            >
              <Image
                src={messageEditIcon}
                alt="Edit Item"
                className="w-[24px] h-[24px]"
              />
              <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
                Edit
              </span>
            </button>
          )}
        </div>

        <div
          className={`item flex justify-between items-center gap-2.5 ${
            editGuests ? "pt-2 pb-[27px]" : "pt-4 pb-4"
          } mb-4`}
        >
          {editGuests ? (
            <Controller
              name="guests"
              control={control}
              render={({ field }) => (
                <Input
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  {...field}
                  type="number"
                  labelPlacement="outside"
                  variant="bordered"
                  label={
                    <span
                      dangerouslySetInnerHTML={{ __html: labelGuestsHtml }}
                    />
                  }
                  placeholder="Guests..."
                  classNames={{
                    base: `mt-0 no-margin-input w-1/2 ${
                      errors?.guests?.message ? "h-[100px]" : "h-[80px]"
                    }`,
                    label:
                      "text-textColor font-normal phone:text-sm text-[10px]",
                    input: "border-none px-[18px] phone:text-base text-[10px]",
                    mainWrapper: `${
                      errors.guests?.message ? "h-[68px]" : "h-[42px]"
                    }`,
                    innerWrapper: "h-[42px] px-3",
                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                      errors.guests?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] h-[42px]`,
                  }}
                  isInvalid={errors.guests?.message ? true : false}
                  errorMessage={errors.guests?.message}
                />
              )}
            />
          ) : (
            <div className="flex items-center gap-[13px]">
              <Image
                src={userIcon}
                alt="guests"
                className="w-[24px] h-[24px]"
              />

              <div>
                <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                  Guests
                </h4>
                <p className="text-base font-bold text-textColor leading-[22px]">
                  {guestsValue || "-"}
                </p>
              </div>
            </div>
          )}

          {editGuests ? (
            <div
              className={`flex items-center gap-2.5 ${
                errors?.guests?.message ? "mt-0" : "mt-2.5"
              }`}
            >
              <button
                onClick={handleSaveGuests}
                type="button"
                className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
              >
                Save
              </button>
              <button
                onClick={() => setEditGuests(false)}
                type="button"
                className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center"
              >
                Discard
              </button>
            </div>
          ) : (
            <button
              onClick={() => setEditGuests(true)}
              type="button"
              className="flex items-center gap-2.5"
            >
              <Image
                src={messageEditIcon}
                alt="Edit Item"
                className="w-[24px] h-[24px]"
              />
              <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
                Edit
              </span>
            </button>
          )}
        </div>

        <div className="flex justify-center items-center w-full">
          {departureIata &&
          destinationValue &&
          dateValue &&
          guestsValue &&
          departureCodeValue &&
          destinationCodeValue &&
          hotelId?.length > 0 &&
          !errors?.dateRange?.message ? (
            <button
              disabled={isDisabled}
              type="submit"
              className={`w-[118px] h-[39px] rounded-[27px] py-[11px] px-[25px] bg-mainGradiant flex justify-center items-center text-white leading-[18.4px] font-bold`}
            >
              {isLoading ? (
                <span className="flex justify-center items-center text-white">
                  <i className="fa-solid fa-spinner fa-spin"></i>
                </span>
              ) : (
                "Discover"
              )}
            </button>
          ) : null}
        </div>
      </form>
      <div
        className={`transition-all duration-300 mb-5 ${
          isModalOpen ? "opacity-100 z-20" : "opacity-0 z-10"
        }`}
      >
        <>
          {isModalOpen && (
            <DiscoverModal
              hotelsList={hotelId}
              flightOffer={flightOffer}
              toursList={toursList}
              checkInDate={`${startDateArr[2]}-${startDateArr[1]
                .toString()
                .padStart(2, "0")}-${startDateArr[0]
                .toString()
                .padStart(2, "0")}`}
              checkOutDate={`${endDateArr[2]}-${endDateArr[1]
                .toString()
                .padStart(2, "0")}-${endDateArr[0]
                .toString()
                .padStart(2, "0")}`}
              adults={guestsValue}
            />
          )}
          <div className="w-full flex justify-center items-center mt-10">
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
              className={`w-[148px] h-[39px] rounded-[27px] py-[11px] px-[25px] bg-mainGradiant flex justify-center items-center text-white leading-[18.4px] font-bold`}
            >
              End Planing
            </button>
          </div>
        </>
      </div>
    </section>
  );
}
