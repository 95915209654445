import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import { AuthContext } from "Context/AuthContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";

export default function EditCommentModal({
  isOpen,
  onClose,
  editId,
  editComment,
  isModalOpen,
  getPostData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: "",
    },
  });

  const [descLength, setDescLength] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen, isOpen]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  const { token } = useContext(AuthContext);

  async function addNote(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const { data } = await axios.put(
        `https://admin.thefrondappadmin.com/api/comments/${editId}`,
        reqBody,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getPostData();
      onClose();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  useEffect(() => {
    handleResize();
  }, [descLength]);

  useEffect(() => {
    if (descLength === 0) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [descLength]);

  useEffect(() => {
    if (editComment) {
      setValue("comment", editComment);
      setDescLength(editComment.length);
    }
  }, [editComment]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Edit Comment
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(addNote)}>
                <div className="max-h-[30vh] overflow-y-auto scroll-outter">
                  <ModalBody className="w-[100%] scroll-inner">
                    <div className="relative about-edit-img sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                      <div className="content flex flex-col gap-5 items-center">
                        <div className="w-full mb-5">
                          <Controller
                            name="comment"
                            control={control}
                            rules={{
                              required: "Comment is required",
                              minLength: {
                                value: 2,
                                message: "Minimum number of characters is 2",
                              },
                              maxLength: {
                                value: 500,
                                message: "Maximum number of characters is 500",
                              },
                            }}
                            render={({ field }) => (
                              <Textarea
                                {...field}
                                onInput={(e) =>
                                  setDescLength(e.target.value.length)
                                }
                                maxLength={500}
                                type="text"
                                variant="bordered"
                                placeholder="Enter your comment"
                                classNames={{
                                  base: "input-noMargin",
                                  label:
                                    "text-textColor font-normal phone:text-sm text-[10px]",
                                  input:
                                    "border-none px-[18px] phone:text-base text-[10px] min-h-[120px]",
                                  mainWrapper: "",
                                  innerWrapper: " overflow-y-auto",
                                  inputWrapper: `border-[0.86px] p-0 border-solid ${
                                    errors.comment?.message
                                      ? "invalid-text"
                                      : "border-[#E5E4EB]"
                                  } rounded-[8px] h-[102px] overflow-y-auto`,
                                }}
                                isInvalid={
                                  errors.comment?.message ? true : false
                                }
                                errorMessage={errors.comment?.message}
                              />
                            )}
                          />
                          {!errors.comment?.message && 500 - descLength > 0 ? (
                            <p className="text-[.75rem] text-mainBlue mt-1">
                              {500 - descLength} remaining characters
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow">
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
}
