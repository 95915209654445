import { Plane } from "lucide-react";
import {
  formatDate,
  formatTime,
  formatDuration,
} from "../../../../utils/dateUtlis.js";

export const FlightSegment = ({
  departure,
  arrival,
  duration,
  carrierCode,
  number,
}) => {
  return (
    <div className="flex items-center gap-4 py-4">
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-2xl font-bold">{departure.iataCode}</p>
            <p className="text-sm text-gray-600">{formatTime(departure.at)}</p>
            <p className="text-xs text-gray-500">{formatDate(departure.at)}</p>
            {departure.terminal && (
              <p className="text-xs text-gray-500">
                Terminal {departure.terminal}
              </p>
            )}
          </div>

          <div className="flex-1 px-4">
            <div className="relative flex items-center justify-center">
              <div className="absolute w-full border-t border-gray-300"></div>
              <div className="absolute">
                <Plane className="w-5 h-5 text-blue-500 rotate-90" />
              </div>
              <p className="mt-8 text-xs text-gray-500">
                {formatDuration(duration)}
              </p>
            </div>
          </div>

          <div className="text-right">
            <p className="text-2xl font-bold">{arrival.iataCode}</p>
            <p className="text-sm text-gray-600">{formatTime(arrival.at)}</p>
            <p className="text-xs text-gray-500">{formatDate(arrival.at)}</p>
            {arrival.terminal && (
              <p className="text-xs text-gray-500">
                Terminal {arrival.terminal}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="text-right">
        <p className="text-sm font-semibold">
          {carrierCode} {number}
        </p>
      </div>
    </div>
  );
};
