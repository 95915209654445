import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ProfileData({ userEmail, userPhone }) {
  const [frondsCount, setFrondsCount] = useState([]);
  let authToken = localStorage.getItem("token");

  const getFrondsCount = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/dashboard-stats`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondsCount(data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getFrondsCount();
  }, []);
  return (
    <>
      <div className="cards flex items-center gap-5 mb-[30px] sm:flex-nowrap flex-wrap px-5 justify-center sm:mt-0 mt-5">
        <div className="item w-[129px] h-[86px] border-1 border-solid border-grayBorder no-focus rounded-[10px] p-2 flex flex-col items-center justify-center gap-2">
          <h3 className="text-textColor leading-[22px] text-lg font-bold">
            {frondsCount?.myFronds}
          </h3>
          <h4 className="text-textColor leading-[16.1px] text-sm font-normal">
            All fronds
          </h4>
        </div>
        <div className="item w-[129px] h-[86px] border-1 border-solid border-grayBorder no-focus rounded-[10px] p-2 flex flex-col items-center justify-center gap-2">
          <h3 className="text-textColor leading-[22px] text-lg font-bold">
            {frondsCount?.sharedFronds}
          </h3>
          <h4 className="text-textColor leading-[16.1px] text-sm font-normal">
            Shared fronds
          </h4>
        </div>
        <div className="item w-[129px] h-[86px] border-1 border-solid border-grayBorder no-focus rounded-[10px] p-2 flex flex-col items-center justify-center gap-2">
          <h3 className="text-textColor leading-[22px] text-lg font-bold">
            {frondsCount?.activeTasks}
          </h3>
          <h4 className="text-textColor leading-[16.1px] text-sm font-normal">
            Active tasks
          </h4>
        </div>
      </div>
      <div className="info sm:w-auto w-[75%] sm:mx-0 mx-auto">
        <div className="email">
          <h3 className="text-cardGrayColor leading-[16.1px] text-sm font-normal mb-[5px]">
            Email
          </h3>
          <p className="text-textColor font-bold">{userEmail}</p>
        </div>
        {/* <div className="phone">
          <h3 className="text-cardGrayColor leading-[16.1px] text-sm font-normal mb-[5px]">
            Phone
          </h3>
          <p className="text-textColor font-bold">{userPhone}</p>
        </div> */}
      </div>
    </>
  );
}
