import { Tag } from "lucide-react";

export const PriceTag = ({ currency, amount }) => {
  return (
    <div className="flex items-center gap-2">
      <Tag className="w-4 h-4 text-green-600" />
      <span className="text-2xl font-bold text-green-600">
        {currency} {amount}
      </span>
    </div>
  );
};
