import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function Library() {
  const authToken = localStorage.getItem("token");
  const [videosList, setVideosList] = useState([]);
  const getVideos = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/libraries`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setVideosList(data?.data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };
  useEffect(() => {
    getVideos();
  }, []);
  return (
    <div className="contact-box relative p-5 bg-white border-1 border-solid border-grayBorder rounded-4xl no-focus">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5">
        {videosList?.map((item) => (
          <div key={item?.id}>
            <video controls className="mb-4 rounded-[10px]">
              <source src={item?.video_url} />
            </video>

            <h2 className="text-textColor mb-1 text-lg font-bold">
              {item?.title || "-"}
            </h2>
            <p className="text-cardGrayColor text-base leading-[18.4px]">
              {item?.description || "-"}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
