import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

import closeIcon from "../../../Assets/popup/cross-square.svg";
import { PopUpContext } from "Context/PopUpContext";
import axios from "axios";
import { toast } from "react-toastify";

export default function DeleteTaskModal({ isOpen, onClose, taskId }) {
  const handleClose = (e) => {
    onClose();
  };

  const { setIsAddTask, setIsAddEvent } = useContext(PopUpContext);
  const authToken = localStorage.getItem("token");

  const deleteRecord = async () => {
    if (!taskId) return;
    try {
      const { data } = await axios.delete(
        `https://admin.thefrondappadmin.com/api/tasks/${taskId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setIsAddEvent(true);
      setTimeout(() => {
        setIsAddEvent(false);
      }, 1000);

      setIsAddTask(true);
      onClose();
      setTimeout(() => {
        setIsAddTask(false);
      }, 1000);
      toast("Task deleted successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center py-5",
        body: "border-b-1 border-solid border-grayBorder pt-2 pb-4",
        closeButton: "hidden",
        base: "p-5 confirm-modal",
      }}
      hideCloseButton={true}
      onClick={(e) => e.stopPropagation()}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-end">
              <button onClick={handleClose} className="" aria-label="Close">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody>
              <p className="text-cardGrayColor font-bold text-center leading-[18.4px] sm:w-[256px] mx-auto">
                Are you sure you want to delete this task?
              </p>
            </ModalBody>
            <ModalFooter className="flex items-center justify-center gap-2.5">
              <button
                onClick={handleClose}
                className="text-base font-bold leading-[18.4px] flex justify-center items-center rounded-[27px] py-[11px] px-[25px] w-[100px] h-[39px] gradiantBorder no-focus smallBorder"
              >
                <span className="text-transparent bg-mainGradiant bg-clip-text font-normal text-base">
                  Cancel
                </span>
              </button>
              <button
                onClick={deleteRecord}
                className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[100px] h-[39px]"
              >
                Delete
              </button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
