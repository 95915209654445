import React, { useContext } from "react";
import { FlightCard } from "./FlightCard/FlightCard";
import HotelInfoCard from "./HotelInfoCard/HotelInfoCard";
import ActivityCard from "./ActivityCard/ActivityCard";

export default function DiscoverModal({
  flightOffer,
  hotelsList,
  toursList,
  checkInDate,
  checkOutDate,
  adults,
}) {
  return (
    <>
      <div className="w-full mb-5">
        <h1 className="sm:text-3xl text-lg font-bold text-textColor mb-5">
          Flights Recommendations
        </h1>
        <div className="grid 2xl:grid-cols-2 grid-cols-1 items-center gap-2.5">
          {flightOffer.length > 0 &&
            flightOffer.map((item, idx) => {
              return <FlightCard key={idx} flightOffer={item} />;
            })}
        </div>
      </div>
      <div className="w-full mb-5">
        <h1 className="sm:text-3xl text-lg font-bold text-textColor mb-5">
          Hotels Recommendations
        </h1>
        <div className="grid 2xl:grid-cols-2 grid-cols-1 items-center gap-2.5">
          {hotelsList.length > 0 &&
            hotelsList.map((item, idx) => {
              return (
                <HotelInfoCard
                  key={idx}
                  isLastItem={idx === hotelsList.length - 1}
                  hotel={item}
                  checkInDate={checkInDate}
                  checkOutDate={checkOutDate}
                  adults={adults}
                />
              );
            })}
        </div>
      </div>
      <div className="w-full mb-5">
        <h1 className="sm:text-3xl text-lg font-bold text-textColor mb-5">
          Activities Recommendations
        </h1>
        <div className="grid 2xl:grid-cols-2 grid-cols-1 items-center gap-2.5">
          {toursList.length > 0 &&
            toursList.map((item, idx) => {
              return (
                <ActivityCard
                  key={idx}
                  isLastItem={idx === toursList.length - 1}
                  activity={item}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
