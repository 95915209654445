import React, { useEffect, useState } from "react";
import ShareHeader from "./ShareHeader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ShareBody from "./ShareBody";

export default function SharePage() {
  const [frondData, setFrondData] = useState([]);

  const { frondSlug } = useParams();
  const navigate = useNavigate();

  const getFrondData = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/project/share/${frondSlug}`
      );
      setFrondData(data?.response?.data);
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    getFrondData();
  }, []);

  return (
    <section className="w-[70%] mx-auto my-2.5 border-2 border-solid border-grayBorder p-5 rounded-[20px] no-focus min-h-[calc(100vh-20px)]">
      <ShareHeader frondData={frondData} />
      <ShareBody frondData={frondData} />
    </section>
  );
}
