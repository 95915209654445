import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import dots from "../../Assets/community/dots-vertical.svg";
import grayLikesIcon from "../../Assets/community/gray-likes.svg";
import grayCommentsIcon from "../../Assets/community/gray-comments.svg";
import likesIcon from "../../Assets/community/likes.svg";
import commentsIcon from "../../Assets/community/comments.svg";
import axios from "axios";
import { toast } from "react-toastify";
import AddPostModal from "./AddPostModal";
import { PopUpContext } from "Context/PopUpContext";
import PostComments from "./PostComments";
import { CommunityContext } from "Context/CommunityContext";
import Linkify from "react-linkify";
import useSize from "hooks/useSize";

export default function PostLayout({
  userImg,
  postId,
  userName,
  time,
  content,
  likesCount,
  commentsCount,
  postImg,
  isOwner,
  isLiked,
  listOfComments,
  isLast,
}) {
  let authToken = localStorage.getItem("token");

  const [itemKey, setItemKey] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [frondData, setFrondData] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);

  const { commentsNum, setDeletedPosts, deletedPosts, updatedPosts } =
    useContext(CommunityContext);

  const { isEditPost, setIsRefetchTrending } = useContext(PopUpContext);

  async function getPostData(flag) {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/posts/${postId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setFrondData(data);

      if (!flag) {
        setIsEditModal(true);
      }
      setIsRefetchTrending(true);
      setTimeout(() => {
        setIsRefetchTrending(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  async function deletePost() {
    try {
      await axios.delete(
        `https://admin.thefrondappadmin.com/api/posts/${postId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast("Post Deleted", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setDeletedPosts((prev) => [...prev, postId]);

      setIsRefetchTrending(true);
      setTimeout(() => {
        setIsRefetchTrending(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  useEffect(() => {
    if (itemKey === "delete") {
      deletePost();
      setItemKey(null);
    } else if (itemKey === "edit") {
      getPostData();
      setItemKey(null);
    }
  }, [itemKey]);

  useEffect(() => {
    if (isEditPost) {
      getPostData(true);
    }
  }, [isEditPost]);

  const { width } = useSize();

  if (width === 0) {
    return null;
  }

  const openModal = (key) => {
    setItemKey(key);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  async function likePost() {
    try {
      await axios.post(
        `https://admin.thefrondappadmin.com/api/posts/${postId}/like`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getPostData(true);
    } catch (error) {}
  }

  if (deletedPosts.includes(postId)) {
    return null;
  }

  const makeUrlsActive = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.replace(urlRegex, (url) => {
      return `<a href="${url}" class="bg-mainGradiant bg-clip-text text-transparent underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  return (
    <>
      <div
        className={`post bg-white rounded-[20px] border-1 border-solid border-grayBorder p-5 w-full no-focus overflow-hidden ${
          !isLast ? "mb-5" : ""
        }`}
      >
        <div className="flex items-start justify-between gap-2.5 mb-5">
          <div className="flex items-center gap-2.5">
            <Image
              src={userImg}
              alt={userName}
              className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full"
            />
            <div>
              <h5 className="mb-[3px] text-textColor font-bold text-base leading-[22px]">
                {userName}
              </h5>
              <p className="text-cardGrayColor text-sm leading-[16.1px]">
                {time}
              </p>
            </div>
          </div>
          {isOwner && (
            <Dropdown
              classNames={{
                content:
                  "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
              }}
              placement="left-start"
            >
              <DropdownTrigger>
                <button className="w-[24px] h-[24px]" onClick={handleDotsClick}>
                  <img
                    src={dots}
                    alt="Dots"
                    className="min-w-[24px] min-h-[24px]"
                  />
                </button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Post Actions"
                onAction={(key) => openModal(key)}
              >
                <DropdownItem
                  textValue="edit note"
                  key="edit"
                  className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  key="delete"
                  className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                  color="danger"
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>

        <p
          className="mb-5 text-textColor leading-[22px] break-all"
          dangerouslySetInnerHTML={{
            __html: makeUrlsActive(
              updatedPosts.includes(postId) ? frondData?.content : content
            ),
          }}
        />

        {postImg && (
          <div
            className="w-full min-h-[300px] rounded-[10px] max-h-[300px] mb-2.5"
            style={{
              backgroundImage: `url(${postImg})`,
              backgroundSize:
                width >= 768 ? "100%" : width >= 640 ? "125%" : "100%",
              backgroundPosition: width >= 768 ? "50% 20%" : "50% 30%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Image
              src={
                updatedPosts.includes(postId)
                  ? frondData?.media[0]?.original_url
                  : postImg
              }
              alt={updatedPosts.includes(postId) ? frondData?.content : content}
              className="opacity-0 w-full h-full rounded-[10px]"
              classNames={{
                wrapper: "hidden rounded-[10px]",
              }}
            />
          </div>
        )}

        <div className="flex justify-between mx-auto items-center w-5/6 pb-2.5 mb-2.5 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-1.5">
            <Image
              src={grayLikesIcon}
              alt="Likes"
              className="w-[18px] h-[18px]"
            />
            <p className="text-sm text-cardGrayColor leading-[16.1px]">
              {frondData ? frondData?.likes?.length : likesCount} Likes
            </p>
          </div>
          <div className="flex items-center gap-1.5">
            <Image
              src={grayCommentsIcon}
              alt="Comments"
              className="w-[18px] h-[18px]"
            />
            <button
              type="button"
              onClick={() => {
                setShowCommentBox(!showCommentBox);
              }}
              className="text-sm text-cardGrayColor leading-[16.1px]"
            >
              {commentsNum?.find((item) => item?.commentPostId === postId)
                ?.commentPostNum || commentsCount}{" "}
              Comments
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between w-9/12 mx-auto">
          <button
            onClick={() => {
              likePost();
            }}
            className="flex items-center justify-start gap-[5px] w-1/2 border-r-2 border-solid border-grayBorder"
          >
            <Image src={likesIcon} alt="Like" className="w-[24px] h-[24px]" />
            <h4
              className={`text-base font-bold ${
                frondData
                  ? frondData?.is_liked
                    ? "text-transparent bg-clip-text bg-mainGradiant"
                    : "text-cardGrayColor"
                  : isLiked
                  ? "text-transparent bg-clip-text bg-mainGradiant"
                  : "text-cardGrayColor"
              } leading-[18.4px]`}
            >
              Like
            </h4>
          </button>
          <button
            onClick={() => {
              setShowCommentBox(!showCommentBox);
            }}
            className="flex items-center justify-end gap-[5px] w-1/2"
          >
            <Image
              src={commentsIcon}
              alt="Comment"
              className="w-[24px] h-[24px]"
            />
            <h4 className="text-base font-bold text-cardGrayColor leading-[18.4px]">
              Comment
            </h4>
          </button>
        </div>
        {showCommentBox && (
          <PostComments postId={postId} listOfComments={listOfComments} />
        )}
      </div>
      {isEditModal && (
        <AddPostModal
          isEdit={true}
          isOpen={isEditModal}
          onClose={() => setIsEditModal(false)}
          frondData={frondData}
          postId={postId}
          getPostData={getPostData}
        />
      )}
    </>
  );
}
