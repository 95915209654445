import { createContext, useState } from "react";

export let CashedDataContext = createContext();

export default function CashedDataContextProvider({ children }) {

    const [isShowHotelOffer, setIsShowHotelOffer] = useState(false);

    return (
        <CashedDataContext.Provider value={{isShowHotelOffer, setIsShowHotelOffer}}>
            {children}
        </CashedDataContext.Provider>
    );
}
