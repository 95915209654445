import React, { useContext, useEffect, useState } from "react";
import PostLayout from "./PostLayout";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { PopUpContext } from "Context/PopUpContext";
import { HashtagsContext } from "Context/HashtagsContext";

export default function CommunityPosts() {
  const [allPosts, setAllPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const authToken = localStorage.getItem("token");
  const postsPerPage = 3;

  const { targetHashtag, setTargetHashtag } = useContext(HashtagsContext);

  setTargetHashtag(null);

  const fetchPosts = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/posts`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setAllPosts(data?.data || []);

      setHasMore(true);

      setVisiblePosts(data?.data.slice(0, postsPerPage));
    } catch (error) {}
  };

  const loadMorePosts = () => {
    const nextPosts = allPosts.slice(
      visiblePosts.length,
      visiblePosts.length + postsPerPage
    );

    setVisiblePosts((prevVisiblePosts) => [...prevVisiblePosts, ...nextPosts]);

    if (visiblePosts.length + nextPosts.length >= allPosts.length) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const { isAddPost } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddPost) {
      fetchPosts();
    }
  }, [isAddPost]);

  const fetchPostsByHashtag = async () => {
    try {
      // Clear existing posts before fetching new ones for a new hashtag
      setAllPosts([]);
      setVisiblePosts([]);
      setHasMore(true);

      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/hashtags/${targetHashtag}/posts`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setAllPosts(data || []);
      setVisiblePosts(data.slice(0, postsPerPage));
    } catch (error) {
      console.error("Error fetching posts by hashtag:", error);
    }
  };

  useEffect(() => {
    if (targetHashtag) {
      fetchPostsByHashtag();
    }
  }, [targetHashtag]);

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  return (
    <div className="bg-white rounded-4xl flex flex-col gap-5 border-2 border-solid border-grayBorder sm:p-10 py-5 px-2.5 w-full 2xl:px-[15%] xl:px-[10%] no-focus">
      <InfiniteScroll
        dataLength={visiblePosts.length}
        next={loadMorePosts}
        hasMore={hasMore}
        loader={<p>Loading more posts...</p>}
        endMessage={<p>No more posts to display.</p>}
      >
        {visiblePosts.map((post, index) => (
          <PostLayout
            key={index}
            postId={post.id}
            userImg={post.user.img_url}
            postImg={post?.media[0]?.original_url}
            userName={post.user.name}
            time={timeAgo(post.created_at)}
            content={post.content}
            likesCount={post.likes_count}
            commentsCount={post.comments_count}
            isLast={index === visiblePosts.length - 1 ? true : false}
            isOwner={post?.is_owner}
            isLiked={post?.is_liked}
            listOfComments={post?.comments}
            likesList={post?.likes}
            getPost={fetchPosts}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
}
