import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import venture from "../../Assets/my-fronds/frond/category/venture.svg";
import other from "../../Assets/my-fronds/frond/category/other.svg";
import home from "../../Assets/my-fronds/frond/category/home.svg";
import event from "../../Assets/my-fronds/frond/category/event.svg";
import escape from "../../Assets/my-fronds/frond/category/escape.svg";
import placeholderImage from "../../Assets/my-fronds/frond/placeholder-image.svg";
import exitRed from "../../Assets/dropdown/shared/exit-red.svg";
import exitGray from "../../Assets/dropdown/shared/exit-gray.svg";

import calendar from "../../Assets/my-fronds/frond/calendar.svg";
import dots from "../../Assets/my-fronds/frond/dots.svg";
import listDots from "../../Assets/my-fronds/frond/list-dots.svg";
import geolocation from "../../Assets/my-fronds/frond/geolocation.svg";
import checkIcon from "../../Assets/my-fronds/check.svg";
import {
  Avatar,
  AvatarGroup,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Tooltip,
} from "@nextui-org/react";
import ShareFrond from "components/MyFrondsFuctions/ShareFrond";
import { Link } from "react-router-dom";
import ArchiveModal from "components/MyFrondsFuctions/ArchiveModal";
import DeleteModal from "components/MyFrondsFuctions/DeleteModal";
import { ArchiveContext } from "Context/ArchiveContext";
import { DeleteProjectsContext } from "Context/DeleteProjectsContext";
import LeaveProjectModal from "components/SharedFrondsFunctions/LeaveProjectsModal";
import { ViewProjectsContext } from "Context/ViewProjectsContext";
import { toast } from "react-toastify";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";
import useSize from "hooks/useSize";

const FrondCard = ({
  frondId,
  mainImg,
  title,
  category,
  startDate,
  endDate,
  location,
  userOne,
  userTwo,
  count,
  isFronds,
  isShared,
  isArchive,
  userOneName,
  userTwoName,
  restUsersName,
  getArchiveFrond,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [shareFrondModal, setShareFrondModal] = useState(false);
  const [archiveFrondModal, setArchiveFrondModal] = useState(false);
  const [deleteFrondModal, setDeleteFrondModal] = useState(false);
  const [leaveFrondModal, setLeaveFrondModal] = useState(false);

  const [itemKey, setItemKey] = useState(null);

  const startDateArr = startDate && startDate.split("/");
  const endDateArr = endDate && endDate.split("/");

  const [targetId, setTargetId] = useState(null);

  const { myFrondsView, archiveFrondsView, sharedFrondsView, pageType } =
    useContext(ViewProjectsContext);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function openModal(key, id) {
    setItemKey(key);
    setStopLink(true);
    setTargetId(id);
  }

  useEffect(() => {
    if (itemKey === "share") {
      setShareFrondModal(true);
    } else if (itemKey === "archive") {
      setArchiveFrondModal(true);
    } else if (itemKey === "delete") {
      setDeleteFrondModal(true);
    } else if (itemKey === "leave") {
      setLeaveFrondModal(true);
    } else if (itemKey === "unArchive") {
      unArchiveFrond();
    }
  }, [itemKey]);

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const [titleLength, setTitleLength] = useState(15);
  const [locationLength, setLocationLength] = useState(15);

  const calculateTitleLength = () => {
    const titleBox = document.querySelector(".title-box");
    if (titleBox) {
      const charNums = Number.parseInt(titleBox.clientWidth / 12);
      setTitleLength(charNums);
    }
  };
  const calculateLocationLength = () => {
    const titleBox = document.querySelector(".location-box");
    if (titleBox) {
      const charNums = Number.parseInt(titleBox.clientWidth / 8);
      setLocationLength(charNums);
    }
  };

  const { setIsArchiveProject } = useContext(PopUpContext);

  useEffect(() => {
    calculateTitleLength();
    calculateLocationLength();

    const handleResize = () => {
      calculateTitleLength();
      calculateLocationLength();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [titleLength, setTitleLength]);

  useEffect(() => {
    if (
      myFrondsView === "grid" ||
      archiveFrondsView === "grid" ||
      sharedFrondsView === "grid"
    ) {
      calculateTitleLength();
    }
    if (
      myFrondsView === "list" ||
      archiveFrondsView === "list" ||
      sharedFrondsView === "list"
    ) {
      calculateTitleLength();
    }
  }, [myFrondsView, archiveFrondsView, sharedFrondsView]);

  const [stopLink, setStopLink] = useState(false);

  const { openArchiveCheckbox, archiveProjects, setArchiveProjects } =
    useContext(ArchiveContext);

  useEffect(() => {}, [openArchiveCheckbox]);

  const toggleArchiveFrond = () => {
    if (archiveProjects.includes(frondId)) {
      setArchiveProjects(archiveProjects.filter((id) => id !== frondId));
    } else {
      setArchiveProjects([...archiveProjects, frondId]);
    }
  };

  useEffect(() => {}, [archiveProjects]);

  const { openDeleteCheckbox, deleteProjects, setDeleteProjects } = useContext(
    DeleteProjectsContext
  );

  const toggleDeleteFrond = () => {
    if (deleteProjects.includes(frondId)) {
      setDeleteProjects(deleteProjects.filter((id) => id !== frondId));
    } else {
      setDeleteProjects([...deleteProjects, frondId]);
    }
  };

  useEffect(() => {}, [deleteProjects]);

  const unArchiveFrond = async () => {
    let authToken = localStorage.getItem("token");
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/projects/${targetId}/restore`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Project restored successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsArchiveProject(true);
      setTimeout(() => {
        setIsArchiveProject(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const { width } = useSize();

  if (width === 0) {
    return null;
  }

  return (
    <div className="frond-card-wrapper relative">
      {(myFrondsView === "grid" && pageType === "my-fronds") ||
      (sharedFrondsView === "grid" && pageType === "shared-fronds") ||
      (archiveFrondsView === "grid" && pageType === "archive-fronds") ? (
        <button
          type="button"
          onClick={(e) => {
            if (openArchiveCheckbox) {
              toggleArchiveFrond();
            } else if (openDeleteCheckbox) {
              toggleDeleteFrond();
            }
          }}
          className={`transition-all duration-300 ${
            openArchiveCheckbox || openDeleteCheckbox
              ? "opacity-100 z-30"
              : "opacity-0 z-0"
          } absolute right-[22px] top-[22px] w-[29px] h-[29px] rounded-[7px] flex justify-center items-center bg-white`}
        >
          <div
            className={`w-[21px] h-[21px] transition-all border-cardGrayColor duration-300 rounded-[7px] border-2 border-solid relative
            
           flex justify-center items-center`}
          >
            <div
              className={`${
                (openArchiveCheckbox && archiveProjects.includes(frondId)) ||
                (openDeleteCheckbox && deleteProjects.includes(frondId))
                  ? "opacity-100"
                  : "opacity-0"
              } flex justify-center transition-all absolute inset-[-2px] duration-300 items-center rounded-[7px] bg-offWhite`}
            >
              <img
                src={checkIcon}
                alt="Check"
                className="w-[12.61px] h-[8.41px]"
              />
            </div>
          </div>
        </button>
      ) : null}

      <Link
        to={`/frond/about/${frondId}`}
        onClick={(e) => {
          if (openArchiveCheckbox) {
            toggleArchiveFrond();
            e.preventDefault();
          } else if (openDeleteCheckbox) {
            toggleDeleteFrond();
            e.preventDefault();
          }
        }}
        className="frond-card-link"
      >
        <div
          className={`frond-card flex ${
            (myFrondsView === "grid" && pageType === "my-fronds") ||
            (sharedFrondsView === "grid" && pageType === "shared-fronds") ||
            (archiveFrondsView === "grid" && pageType === "archive-fronds")
              ? "flex-col gap-x-0"
              : "flex-row items-center min-[1000px]:flex-nowrap flex-wrap"
          } gap-y-2 relative p-3 bg-white rounded-[14px] border-2 border-solid border-grayBorder transition-all duration-300 hover:shadow-frondShadow no-focus`}
        >
          {(myFrondsView === "list" && pageType === "my-fronds") ||
          (sharedFrondsView === "list" && pageType === "shared-fronds") ||
          (archiveFrondsView === "list" && pageType === "archive-fronds") ? (
            <button
              type="button"
              onClick={(e) => {
                if (openArchiveCheckbox) {
                  toggleArchiveFrond();
                } else if (openDeleteCheckbox) {
                  toggleDeleteFrond();
                }
              }}
              className={`transition-all duration-300 ${
                openArchiveCheckbox || openDeleteCheckbox
                  ? "w-[29px] h-[29px] opacity-100 me-2.5"
                  : "w-0 h-0 opacity-0 me-0"
              } rounded-[7px] flex justify-center items-center bg-white`}
            >
              <div
                className={`w-[21px] h-[21px] transition-all border-cardGrayColor duration-300 rounded-[7px] border-2 border-solid relative
                
               flex justify-center items-center`}
              >
                <div
                  className={`${
                    (openArchiveCheckbox &&
                      archiveProjects.includes(frondId)) ||
                    (openDeleteCheckbox && deleteProjects.includes(frondId))
                      ? "opacity-100"
                      : "opacity-0"
                  } flex justify-center transition-all absolute inset-[-2px] duration-300 items-center rounded-[7px] bg-offWhite`}
                >
                  <img
                    src={checkIcon}
                    alt="Check"
                    className="w-[12.61px] h-[8.41px]"
                  />
                </div>
              </div>
            </button>
          ) : null}
          {mainImg ? (
            <div
              className={`${
                (myFrondsView === "grid" && pageType === "my-fronds") ||
                (sharedFrondsView === "grid" && pageType === "shared-fronds") ||
                (archiveFrondsView === "grid" && pageType === "archive-fronds")
                  ? "w-full xl:h-[146px] lg:h-[194px] min-[901px]:h-[146px] sm:h-[194px] min-[460px]:h-[214px] h-[176px]"
                  : "xl:min-w-[200px] xl:h-[130px] lg:min-w-[177px] lg:h-[106px] sm:min-w-[220px] sm:h-[130px]"
              } rounded-[10px] overflow-hidden mb-0.5 border-2 border-solid border-grayBorder no-focus`}
              style={{
                backgroundImage: `url(${mainImg})`,
                backgroundSize:
                  (myFrondsView === "grid" && pageType === "my-fronds") ||
                  (sharedFrondsView === "grid" &&
                    pageType === "shared-fronds") ||
                  (archiveFrondsView === "grid" &&
                    pageType === "archive-fronds")
                    ? width >= 768
                      ? "100%"
                      : width >= 640
                      ? "125%"
                      : "100%"
                    : width >= 768
                    ? "100%"
                    : width >= 640
                    ? "125%"
                    : "100%",
                backgroundPosition: width >= 768 ? "50% 20%" : "50% 30%",
                backgroundRepeat: "no-repeat",
              }}
            />
          ) : (
            <div
              className={`${
                (myFrondsView === "grid" && pageType === "my-fronds") ||
                (sharedFrondsView === "grid" && pageType === "shared-fronds") ||
                (archiveFrondsView === "grid" && pageType === "archive-fronds")
                  ? "w-full xl:h-[146px] lg:h-[194px] min-[901px]:h-[146px] sm:h-[194px] min-[460px]:h-[214px] h-[176px]"
                  : "xl:min-w-[200px] xl:h-[130px] lg:min-w-[177px] lg:h-[106px] sm:min-w-[220px] sm:h-[130px]"
              } rounded-[10px] mb-0.5 flex justify-center items-center border-2 border-solid border-grayBorder no-focus`}
            >
              <img src={placeholderImage} alt="frond" />
            </div>
          )}
          {isShared && (
            <Dropdown
              classNames={{
                content:
                  "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
              }}
              placement="left-start"
            >
              <DropdownTrigger>
                <button
                  className="absolute w-[24px] h-[24px] top-5 right-5 z-20"
                  onClick={handleDotsClick}
                >
                  <img
                    src={sharedFrondsView === "grid" ? dots : listDots}
                    alt=":"
                  />
                </button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Shared Fronds Actions"
                onAction={(key) => openModal(key, frondId)}
              >
                <DropdownItem
                  key="leave"
                  className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                  color="danger"
                >
                  <div
                    className="flex items-center gap-2"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <Image src={isHovered ? exitGray : exitRed} alt="X" />
                    <h6>Leave</h6>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {isArchive && (
            <Dropdown
              classNames={{
                content:
                  "min-w-[97px] max-w-[97px] py-0 px-[5px] rounded-[10px]",
              }}
              placement="left-start"
            >
              <DropdownTrigger>
                <button
                  className="absolute w-[24px] h-[24px] top-5 right-5 z-20"
                  onClick={handleDotsClick}
                >
                  <img
                    src={archiveFrondsView === "grid" ? dots : listDots}
                    alt=":"
                  />
                </button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Archive Frond Actions"
                onAction={(key) => openModal(key, frondId)}
              >
                <DropdownItem
                  key="unArchive"
                  className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                >
                  Unarchive
                </DropdownItem>
                <DropdownItem
                  key="delete"
                  className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                  color="danger"
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {isFronds && (
            <Dropdown
              classNames={{
                content:
                  "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
              }}
              placement="left-start"
            >
              <DropdownTrigger>
                <button
                  className="absolute w-[24px] h-[24px] top-5 right-5 z-20"
                  onClick={handleDotsClick}
                >
                  <img
                    src={myFrondsView === "grid" ? dots : listDots}
                    alt=":"
                  />
                </button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Frond Actions"
                onAction={(key) => openModal(key, frondId)}
              >
                <DropdownItem
                  textValue="share frond"
                  key="share"
                  className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                >
                  Share
                </DropdownItem>
                <DropdownItem
                  key="archive"
                  className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                >
                  Archive
                </DropdownItem>
                <DropdownItem
                  key="delete"
                  className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                  color="danger"
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {(myFrondsView === "grid" && pageType === "my-fronds") ||
          (sharedFrondsView === "grid" && pageType === "shared-fronds") ||
          (archiveFrondsView === "grid" && pageType === "archive-fronds") ? (
            <>
              <div className="name-category flex gap-[5px]">
                <Tooltip content={title} placement="top-start">
                  <h5 className="text-textColor phone:text-base text-[12px] font-bold flex-1 title-box overflow-hidden">
                    {title.length > titleLength
                      ? `${title.substring(0, titleLength)}...`
                      : title}
                  </h5>
                </Tooltip>
                <div className="flex items-center gap-2">
                  <Image
                    src={
                      category === "Event"
                        ? event
                        : category === "Escape"
                        ? escape
                        : category === "Home"
                        ? home
                        : category === "Venture"
                        ? venture
                        : category === "Other"
                        ? other
                        : null
                    }
                    alt={category}
                    className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                  />
                  <span className="text-cardGrayColor phone:text-base text-[12px] font-normal">
                    {category}
                  </span>
                </div>
              </div>
              <div className="date flex items-center gap-1.5 ms-2.5">
                <Image
                  src={calendar}
                  alt="Calendar"
                  className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                />
                <div className="flex items-center phone:text-sm text-[12px] font-normal text-mainBlue opacity-75">
                  <p>
                    {startDateArr &&
                      `${startDateArr[0]} ${months[startDateArr[1] - 1]}, ${
                        startDateArr[2]
                      }`}
                  </p>
                  <span className={`${startDate && endDate ? "mx-1" : null}`}>
                    -
                  </span>
                  <p>
                    {endDateArr &&
                      `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                        endDateArr[2]
                      }`}
                  </p>
                </div>
              </div>
              <div className="content flex justify-between">
                <div className="info flex-1">
                  <div className="location flex items-center gap-1.5 ms-2.5">
                    <Image
                      src={geolocation}
                      alt="Geolocation"
                      className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                    />
                    <p className="phone:text-sm text-[12px] font-normal text-mainBlue opacity-75 flex-1 location-box">
                      {location
                        ? location.length > locationLength
                          ? `${location.substring(0, locationLength)}...`
                          : location
                        : "-"}
                    </p>
                  </div>
                </div>

                <AvatarGroup
                  isBordered
                  max={2}
                  total={count}
                  renderCount={(count) => (
                    <Tooltip
                      placement="right"
                      key={restUsersName?.length}
                      content={
                        <div className="">
                          {restUsersName?.map((item, idx) => (
                            <div key={idx}>{item}</div>
                          ))}
                        </div>
                      }
                    >
                      <Avatar
                        className="w-[24px] h-[24px] count-border gradiantBorder no-focus countBox"
                        name={`+${count}`}
                      />
                    </Tooltip>
                  )}
                >
                  {userOne && (
                    <Tooltip
                      key={userOneName?.split(" ")[0]}
                      content={userOneName?.split(" ")[0]}
                    >
                      <Avatar
                        classNames={{
                          base: "w-[24px] h-[24px] count-border gradiantBorder",
                        }}
                        src={userOne}
                      />
                    </Tooltip>
                  )}
                  {userTwo && (
                    <Tooltip
                      key={userTwoName?.split(" ")[0]}
                      content={userTwoName?.split(" ")[0]}
                    >
                      <Avatar
                        classNames={{
                          base: "w-[24px] h-[24px] count-border gradiantBorder",
                        }}
                        src={userTwo}
                      />
                    </Tooltip>
                  )}
                </AvatarGroup>
              </div>
            </>
          ) : null}

          {(myFrondsView === "list" && pageType === "my-fronds") ||
          (sharedFrondsView === "list" && pageType === "shared-fronds") ||
          (archiveFrondsView === "list" && pageType === "archive-fronds") ? (
            <div className="flex flex-col gap-0 w-full h-full justify-start min-[1000px]:ms-5 ms-0">
              <Tooltip content={title} placement="top-start">
                <h5
                  className={`text-textColor phone:text-base text-[12px] font-bold title-box min-[1000px]:mb-[15px] mb-[5px] overflow-hidden`}
                >
                  {title.length > titleLength
                    ? `${title.substring(0, titleLength)}...`
                    : title}
                </h5>
              </Tooltip>

              <div className="flex items-center gap-2 min-[1000px]:mb-[15px] mb-[5px]">
                <Image
                  src={
                    category === "Event"
                      ? event
                      : category === "Escape"
                      ? escape
                      : category === "Home"
                      ? home
                      : category === "Venture"
                      ? venture
                      : category === "Other"
                      ? other
                      : null
                  }
                  alt={category}
                  className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                />
                <span className="text-cardGrayColor phone:text-base text-[12px] font-normal">
                  {category}
                </span>
              </div>
              <div className="flex justify-between items-center gap-2.5 w-full sm:flex-nowrap flex-wrap">
                <div className="flex gap-[15px] items-center">
                  <div className="date flex items-center gap-1.5">
                    <Image
                      src={calendar}
                      alt="Calendar"
                      className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                    />
                    <div className="flex items-center phone:text-sm text-[12px] font-normal text-mainBlue opacity-75">
                      <p>
                        {startDateArr &&
                          `${startDateArr[0]} ${months[startDateArr[1] - 1]}, ${
                            startDateArr[2]
                          }`}
                      </p>
                      <span
                        className={`${startDate && endDate ? "mx-1" : null}`}
                      >
                        -
                      </span>
                      <p>
                        {endDateArr &&
                          `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                            endDateArr[2]
                          }`}
                      </p>
                    </div>
                  </div>
                  <div className="info">
                    <div className="location flex items-center gap-1.5">
                      <Image
                        src={geolocation}
                        alt="Geolocation"
                        className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                      />
                      <p className="phone:text-sm text-[12px] font-normal text-mainBlue opacity-75">
                        {location || "-"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={`users ${
                    userOne && userTwo && count && "w-[89.5px]"
                  } ${userOne && userTwo && !count && "w-[59.6px]"} ${
                    userOne && !userTwo && !count && "w-[29.8px]"
                  } ${
                    !userOne && userTwo && !count && "w-[29.8px]"
                  } h-[33px] relative flex self-end justify-end items-center`}
                >
                  {userOne && (
                    <img
                      src={userOne}
                      alt="User one"
                      className={`min-w-[33px] min-h-[33px] max-w-[33px] max-h-[33px] rounded-full absolute z-[10] ${
                        userTwo && count ? "left-[6.5px]" : "left-0"
                      }  top-0 count-border gradiantBorder`}
                    />
                  )}
                  {userTwo && (
                    <img
                      src={userTwo}
                      alt="User Two"
                      className={`min-w-[33px] min-h-[33px] max-w-[33px] max-h-[33px] rounded-full absolute z-[11] ${
                        count ? "right-[25px]" : "right-0"
                      } top-0 count-border gradiantBorder`}
                    />
                  )}
                  {count && (
                    <div className="min-w-[33px] min-h-[33px] max-w-[33px] max-h-[33px] flex justify-center items-center count-border gradiantBorder rounded-full absolute z-[12] right-0 top-0">
                      <span className="text-sm font-normal bg-clip-text text-transparent bg-mainGradiant">
                        +{count}
                      </span>
                    </div>
                  )}
                </div> */}
                <AvatarGroup
                  isBordered
                  max={2}
                  total={count}
                  renderCount={(count) => (
                    <Tooltip
                      placement="right"
                      key={restUsersName?.length}
                      content={
                        <div className="">
                          {restUsersName?.map((item, idx) => (
                            <div key={idx}>{item}</div>
                          ))}
                        </div>
                      }
                    >
                      <Avatar
                        className="w-[33px] h-[33px] count-border gradiantBorder no-focus countBox"
                        name={`+${count}`}
                      />
                    </Tooltip>
                  )}
                >
                  {userOne && (
                    <Tooltip
                      key={userOneName?.split(" ")[0]}
                      content={userOneName?.split(" ")[0]}
                    >
                      <Avatar
                        classNames={{
                          base: "w-[33px] h-[33px] count-border gradiantBorder",
                        }}
                        src={userOne}
                      />
                    </Tooltip>
                  )}
                  {userTwo && (
                    <Tooltip
                      key={userTwoName?.split(" ")[0]}
                      content={userTwoName?.split(" ")[0]}
                    >
                      <Avatar
                        classNames={{
                          base: "w-[33px] h-[33px] count-border gradiantBorder",
                        }}
                        src={userTwo}
                      />
                    </Tooltip>
                  )}
                </AvatarGroup>
              </div>
            </div>
          ) : null}

          {itemKey === "share" && (
            <ShareFrond
              isOpen={shareFrondModal}
              onClose={() => {
                setStopLink(true);
                setTimeout(() => {
                  setStopLink(false);
                }, 100);
                setItemKey(null);
                setShareFrondModal(false);
              }}
              stopClosePropagation={true}
              isModalOpen={true}
              frondId={targetId}
            />
          )}
          {itemKey === "archive" && (
            <ArchiveModal
              isOpen={archiveFrondModal}
              onClose={() => {
                setStopLink(true);
                setTimeout(() => {
                  setStopLink(false);
                }, 100);
                setItemKey(null);
                setArchiveFrondModal(false);
              }}
              stopClosePropagation={true}
              frondId={targetId}
            />
          )}
          {itemKey === "delete" && (
            <DeleteModal
              isOpen={deleteFrondModal}
              onClose={() => {
                setStopLink(true);
                setTimeout(() => {
                  setStopLink(false);
                }, 100);
                setItemKey(null);
                setDeleteFrondModal(false);
              }}
              stopClosePropagation={true}
              frondId={targetId}
            />
          )}
          {itemKey === "leave" && (
            <LeaveProjectModal
              isOpen={leaveFrondModal}
              onClose={() => {
                setStopLink(true);
                setTimeout(() => {
                  setStopLink(false);
                }, 100);
                setItemKey(null);
                setLeaveFrondModal(false);
              }}
              stopClosePropagation={true}
              frondId={targetId}
            />
          )}
        </div>
      </Link>
    </div>
  );
};

export default FrondCard;
