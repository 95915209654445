import React, { useContext, useEffect, useState } from "react";
import dots from "../../Assets/community/dots-vertical.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import { PopUpContext } from "Context/PopUpContext";
import likesIcon from "../../Assets/community/likes.svg";
import axios from "axios";
import { toast } from "react-toastify";
import EditCommentModal from "./EditCommentModal";

export default function CommentReplies({ listOfReplies, getPostData }) {
  let authToken = localStorage.getItem("token");
  const [itemKey, setItemKey] = useState(null);

  const [targetId, setTargetId] = useState(null);
  const [targetComment, setTargetComment] = useState(null);

  const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false);

  const { isAddPost } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddPost) {
      setTargetId(null);
      setTargetComment(null);
    }
  }, [isAddPost]);

  useEffect(() => {
    if (itemKey === "edit reply") {
      setIsEditCommentModalOpen(true);
      setItemKey(null);
    } else if (itemKey === "delete reply") {
      deleteComment();
      setTargetId(null);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id, comment) => {
    setItemKey(key);
    if (id) {
      setTargetId(id);
    }
    if (comment) {
      setTargetComment(comment);
    }
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  async function deleteComment() {
    try {
      const { data } = await axios.delete(
        `https://admin.thefrondappadmin.com/api/comments/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getPostData(true);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  // Like Comment

  async function likeComment(id) {
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/comments/${id}/toggle-like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getPostData(true);
    } catch (error) {
      toast(error?.response?.data?.message || "An error occurred", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  return (
    <>
      {listOfReplies?.length > 0 &&
        listOfReplies?.map((subItem) => (
          <div
            key={subItem?.id}
            className="flex flex-col items-start gap-2.5 mb-2.5 ms-[55px] w-fit"
          >
            <div className="flex items-start gap-2.5">
              <div
                className={`min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px] rounded-full`}
                style={{
                  backgroundImage: `url(${subItem?.user_data?.img_url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  objectFit: "cover",
                  backgroundPosition: "50%",
                }}
              ></div>
              <div className="flex bg-socialIconBg w-full justify-between items-start px-3 py-2 gap-5 rounded-[20px]">
                <div className="flex-col gap-[3px]">
                  <p className="text-textColor font-bold text-base">
                    {subItem?.user_data?.name}
                  </p>
                  <p className="text-cardGrayColor text-sm mb-2">
                    {timeAgo(subItem?.updated_at)}
                  </p>
                  <p className="text-textColor leading-[22px] break-before-all">
                    {subItem?.comment}
                  </p>
                </div>
                {subItem?.is_mine && (
                  <Dropdown
                    classNames={{
                      content:
                        "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                    }}
                    placement="left-start"
                  >
                    <DropdownTrigger>
                      <button
                        className="w-[24px] h-[24px] mt-0.5"
                        onClick={handleDotsClick}
                      >
                        <img
                          src={dots}
                          alt="Dots"
                          className="min-w-[24px] min-h-[24px]"
                        />
                      </button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Post Actions"
                      onAction={(key) =>
                        openModal(key, subItem?.id, subItem?.comment)
                      }
                    >
                      <DropdownItem
                        textValue="edit reply"
                        key="edit reply"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        key="delete reply"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                likeComment(subItem?.id);
              }}
              className="flex items-center justify-start gap-[5px] ms-[55px]"
            >
              <Image src={likesIcon} alt="Like" className="w-[18px] h-[18px]" />
              {subItem?.like_count > 0 && (
                <h4
                  className={`text-sm font-normal ${
                    subItem?.is_liked
                      ? "text-transparent bg-clip-text bg-mainGradiant"
                      : "text-textColor"
                  } leading-[18.4px]`}
                >
                  {subItem?.like_count}
                </h4>
              )}
            </button>
          </div>
        ))}
      {isEditCommentModalOpen && targetComment && (
        <EditCommentModal
          isOpen={isEditCommentModalOpen}
          onClose={() => setIsEditCommentModalOpen(false)}
          editComment={targetComment}
          editId={targetId}
          getPostData={getPostData}
        />
      )}
    </>
  );
}
