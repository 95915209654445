import { Image, Input, Tooltip } from "@nextui-org/react";
import { SearchFrondContext } from "Context/SearchFrondContext";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import magnifier from "../../Assets/filter/magnifier.svg";
import selectAllIcon from "../../Assets/invitations/check-squared.svg";
import axios from "axios";
import { toast } from "react-toastify";
import DataLoader from "pages/DataLoader/DataLoader";
import crossIcon from "../../Assets/invitations/cross-square.svg";
import checkIcon from "../../Assets/my-fronds/check.svg";
import GroupInvitationsModal from "./GroupInvitationsModal";
import { PopUpContext } from "Context/PopUpContext";
import noInvitationsImg from "../../Assets/empty-screens/empty-invitations.svg";
import EchoClient from "Echo/echo";
import { Link } from "react-router-dom";
export default function Invitations() {
  const { searchValue, setSearchValue } = useContext(SearchFrondContext);
  const [isInvite, setIsInvite] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { isGroupInvite, isGroupNotInvite } = useContext(PopUpContext);

  let authToken = localStorage.getItem("token");
  const [userId, setUserId] = useState(null);
  const [fronds, setFronds] = useState([]);
  const [openCheckbox, setOpenCheckbox] = useState(false);
  const [userInvitations, setUserInvitations] = useState([]);
  const [openGroupInvitations, setOpenGroupInvitations] = useState(false);
  const [actionType, setActionType] = useState("");

  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const getUserData = async () => {
    setIsError("");
    try {
      const res = await axios.get("/user", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserId(res.data?.id);
    } catch (error) {
      setIsError("Failed to fetch user data");
    }
  };

  // Fetch invitations
  const fetchInvitations = async () => {
    setIsLoading(true);
    setIsError("");
    try {
      const { data } = await axios.get(
        "https://admin.thefrondappadmin.com/api/invitations",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFronds(data.response.data);

      EchoClient.channel(`user.invitations.${userId}`).listen(
        "InvitationsUpdated",
        (event) => {
          setFronds(event);
        }
      );
    } catch (error) {
      toast("Failed to fetch invitations", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
      setIsError("Failed to fetch invitations");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchInvitations();
    }
  }, [userId]);

  // Accept invitation
  const acceptInvite = async (id) => {
    setIsInvite(true);
    setIsDisabled(true);
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/projects/${id}/accept-invite`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast(data.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
      fetchInvitations();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsInvite(false);
    setIsDisabled(false);
  };

  // Decline invitation
  const declineInvite = async (id) => {
    setIsInvite(true);
    setIsDisabled(true);
    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/projects/${id}/decline-invite`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(data.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
      fetchInvitations();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsInvite(false);
    setIsDisabled(false);
  };

  const toggleInvitations = (userId) => {
    if (userInvitations.includes(userId)) {
      setUserInvitations(userInvitations.filter((id) => id !== userId));
    } else {
      setUserInvitations([...userInvitations, userId]);
    }
  };

  useEffect(() => {
    if (isGroupInvite || isGroupNotInvite) {
      setUserInvitations([]);
    }
  }, [isGroupInvite, isGroupNotInvite]);

  useLayoutEffect(() => {
    setSearchValue("");
  }, []);

  const [showHistory, setShowHistory] = useState(false);

  // Filter and paginate invitations
  const filteredFronds = showHistory
    ? fronds?.history?.filter(frond =>
        frond?.title?.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : fronds?.invitations?.filter(frond =>
        frond?.title?.toLowerCase().includes(searchValue.toLowerCase()),
      );

  const ITEMS_PER_PAGE = 12;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (currentPage > Math.ceil(filteredFronds?.length / ITEMS_PER_PAGE)) {
      setCurrentPage(
        Math.max(Math.ceil(filteredFronds?.length / ITEMS_PER_PAGE), 1)
      );
    }
  }, [filteredFronds, currentPage]);

  const totalPages = Math.ceil(filteredFronds?.length / ITEMS_PER_PAGE);
  const currentItems = filteredFronds?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {}, [fronds]);

  return (
    <>
      <section
        className={`invitaions border-2 border-solid border-grayBorder p-5 rounded-4xl bg-white no-focus ${
          !fronds.invitations?.length > 0 ||
          !fronds.history?.length > 0 ||
          searchValue
            ? "min-h-[calc(100vh-192px)]"
            : ""
        }`}
      >
        {isError ? (
          <div className="text-white bg-cardColor py-3 text-center rounded-15px text-sm">
            <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
              {isError || "An error occurred"}
            </span>
          </div>
        ) : isLoading ? (
          <div className="min-h-[400px] flex justify-center items-center">
            <DataLoader />
          </div>
        ) : (
          <>
            <div className="controls flex items-center lg:justify-between justify-center gap-x-2.5 gap-y-[15px] mb-[30px] lg:flex-nowrap flex-wrap">
              <div className="flex items-center sm:gap-[30px] phone:gap-5 gap-2.5 w-full lg:justify-start justify-center">
                {openCheckbox
                  ? !showHistory && (
                      <button
                        type="button"
                        onClick={() => {
                          setOpenCheckbox(false);
                          setUserInvitations([]);
                        }}
                        className="flex justify-center items-center rounded-4xl py-[11px] px-5 phone:w-[118px] w-2/4 h-[40px] bg-darkBlue gap-2.5"
                      >
                        <img
                          src={crossIcon}
                          alt="Cancel"
                          className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                        />
                        <span className="text-white leading-[18.4px] phone:text-base text-[12px]">
                          Cancel
                        </span>
                      </button>
                    )
                  : !showHistory && (
                      <button
                        type="button"
                        onClick={() => {
                          if (fronds?.invitations?.length > 0) {
                            setOpenCheckbox(true);
                          }
                        }}
                        className="flex justify-center items-center rounded-4xl phone:py-[11px] py-2 phone:px-5 px-2 phone:w-[136px] w-2/4 h-[40px] gradiantBorder smallBorder gap-2.5 no-focus"
                      >
                        <img
                          src={selectAllIcon}
                          alt="Select All"
                          className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                        />
                        <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] phone:text-base text-[12px]">
                          Select
                        </span>
                      </button>
                    )}
                <Input
                  onInput={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  type="text"
                  placeholder="Search ..."
                  classNames={{
                    base: "2xl:w-[284px] sm:w-[260px] w-2/4",
                    input:
                      "border-none p-0 sm:text-base phone:text-[12px] text-[12px] sm:h-auto phone:h-[30px] h-[20px]",
                    innerWrapper: "sm:h-auto h-fit",
                    inputWrapper:
                      "phone:px-15px px-[6px] sm:py-2.5 py-0 bg-white rounded-[20px] in-wrap h-[40px] min-h-fit",
                    mainWrapper:
                      "bg-white rounded-[20px] border-2 border-solid border-grayBorder no-focus ",
                  }}
                  startContent={
                    <Image
                      src={magnifier}
                      alt="magnifier"
                      className="sm:min-w-[18px] sm:min-h-[18px] sm:max-w-[18px] sm:max-h-[18px] phone:min-w-[14px] phone:min-h-[14px] phone:max-w-[14px] phone:max-h-[14px] min-w-[14px] min-h-[14px] max-w-[14px] max-h-[14px]"
                    />
                  }
                  className="search-projects invitaion-search"
                />
              </div>
              <div className="accept-all flex items-center gap-2.5">
                <Tooltip
                  key={!showHistory ? "History" : "Invitations"}
                  content={!showHistory ? "History" : "Invitations"}
                >
                  <button
                    onClick={() => {
                      setShowHistory(!showHistory);
                    }}
                  >
                    {!showHistory ? (
                      <i class="fa-solid fa-clock-rotate-left text-mainPink fa-xl"></i>
                    ) : (
                      <i class="fa-solid fa-xmark text-mainPink fa-xl"></i>
                    )}
                  </button>
                </Tooltip>
                {!showHistory && (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        if (userInvitations.length > 0) {
                          setActionType("accept");
                          setOpenGroupInvitations(true);
                          setOpenCheckbox(false);
                        }
                      }}
                      disabled={!openCheckbox}
                      className={`leading-[18.4px] phone:w-[115px] w-[90px] phone:h-[40px] h-[30px] flex justify-center items-center py-[11px] phone:px-5 px-2.5 phone:text-base text-[12px] rounded-4xl text-white transition-all duration-300 ${
                        openCheckbox
                          ? "bg-greenTimeline cursor-pointer"
                          : "bg-btnGray cursor-auto"
                      }`}
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {
                        if (userInvitations.length > 0) {
                          setActionType("decline");
                          setOpenGroupInvitations(true);
                          setOpenCheckbox(false);
                        }
                      }}
                      disabled={!openCheckbox}
                      className={`leading-[18.4px] phone:w-[115px] w-[90px] phone:h-[40px] h-[30px] flex justify-center items-center py-[11px] phone:px-5 px-2.5 phone:text-base text-[12px] rounded-4xl text-white transition-all duration-300 ${
                        openCheckbox
                          ? "bg-redTimeline cursor-pointer"
                          : "bg-btnGray cursor-auto"
                      }`}
                    >
                      Decline
                    </button>
                  </>
                )}
              </div>
            </div>
            {filteredFronds?.length > 0 ? (
              <>
                <div className="requests flex flex-col phone:mx-5">
                  {currentItems.map((item, idx) => (
                    <div
                      key={item?.id}
                      className={`request flex justify-between items-center gap-x-4 gap-y-2.5 lg:flex-nowrap flex-wrap ${
                        idx !== filteredFronds?.length - 1 &&
                        "border-b-1 border-solid border-grayBorder pb-5 mb-5"
                      }`}
                    >
                      <div className="info flex items-center">
                        <button
                          type="button"
                          onClick={(e) => {
                            if (openCheckbox) {
                              toggleInvitations(item?.id);
                            }
                          }}
                          className={`transition-all duration-300 ${
                            openCheckbox
                              ? "opacity-100 z-30 w-[29px] h-[29px] me-2.5"
                              : "opacity-0 z-0 w-0 h-0 me-0"
                          } rounded-[7px] flex justify-center items-center bg-white`}
                        >
                          <div
                            className={`w-[21px] h-[21px] transition-all border-cardGrayColor duration-300 rounded-[7px] border-2 border-solid relative
            
           flex justify-center items-center`}
                          >
                            <div
                              className={`${
                                openCheckbox &&
                                userInvitations.includes(item?.id)
                                  ? "opacity-100"
                                  : "opacity-0"
                              } flex justify-center transition-all absolute inset-[-2px] duration-300 items-center rounded-[7px] bg-offWhite`}
                            >
                              <img
                                src={checkIcon}
                                alt="Check"
                                className="w-[12.61px] h-[8.41px]"
                              />
                            </div>
                          </div>
                        </button>
                        <Image
                          src={item?.pivot?.owner?.img_url}
                          alt="User Image"
                          className="phone:min-w-[60px] phone:min-h-[60px] phone:max-w-[60px] phone:max-h-[60px] min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full object-cover me-2.5"
                        />
                        <div>
                          <p className="text-textColor font-bold leading-[22px] phone:text-base text-sm">
                            <span>{item?.pivot?.owner?.name}</span>
                            <span className="font-normal leading-[18.4px] text-cardGrayColor mx-1">
                              has invited you to join their frond
                            </span>
                            <span>{item?.title}</span>
                          </p>
                          <p className="text-sm text-cardGrayColor">
                            {timeAgo(item?.pivot?.created_at)}
                          </p>
                        </div>
                      </div>
                      {showHistory ? (
                        item?.pivot?.status && (
                          <div className="flex items-center gap-2.5 flex-wrap">
                            <p className="flex justify-center items-center py-2.5 px-5 rounded-md w-[83px] h-[36px] text-white text-sm leading-[16.1px] bg-greenTimeline">
                              {item?.pivot?.status?.charAt(0).toUpperCase() +
                                item?.pivot?.status?.slice(1)}
                            </p>
                            <Link
                              to={`/share/frond/${item?.slug}`}
                              target="_blank"
                              className="flex justify-center items-center py-2.5 px-5 rounded-md w-[128px] h-[36px] text-white text-sm leading-[16.1px] bg-mainGradiant"
                            >
                              Preview frond
                            </Link>
                          </div>
                        )
                      ) : (
                        <div className="actions flex items-center gap-2.5 min-[440px]:flex-nowrap flex-wrap">
                          <button
                            type="button"
                            disabled={isDisabled}
                            onClick={() => {
                              acceptInvite(item?.pivot?.project_id);
                            }}
                            className="flex justify-center items-center py-2.5 px-5 rounded-md w-[83px] h-[36px] text-white text-sm leading-[16.1px] bg-greenTimeline"
                          >
                            {isInvite ? (
                              <span className="flex gap-2 justify-center items-center text-white">
                                <i className="fa-solid fa-spinner fa-spin"></i>
                              </span>
                            ) : (
                              "Accept"
                            )}
                          </button>
                          <button
                            type="button"
                            disabled={isDisabled}
                            onClick={() => {
                              declineInvite(item?.pivot?.project_id);
                            }}
                            className="flex justify-center items-center py-2.5 px-5 rounded-md w-[83px] h-[36px] text-white text-sm leading-[16.1px] bg-redTimeline"
                          >
                            {isInvite ? (
                              <span className="flex gap-2 justify-center items-center text-white">
                                <i className="fa-solid fa-spinner fa-spin"></i>
                              </span>
                            ) : (
                              "Decline"
                            )}
                          </button>
                          <Link
                            to={`/share/frond/${item?.slug}`}
                            target="_blank"
                            className="flex justify-center items-center py-2.5 px-5 rounded-md w-[128px] h-[36px] text-white text-sm leading-[16.1px] bg-mainGradiant"
                          >
                            Preview frond
                          </Link>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {filteredFronds?.length > 12 && (
                  <div className="flex justify-center items-center gap-2.5 mt-6">
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                      className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
                    >
                      Prev
                    </button>
                    <span className="smallPhone:text-lg text-sm text-textColor font-semibold">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col justify-center items-center translate-y-[53.2px] gap-6 min-h-[calc(100%-43.2px)]">
                <Image src={noInvitationsImg} alt="No Invitations" />
                <p className="text-cardGrayColor leading-[20.7px] w-[233px] text-lg font-bold text-center">
                  You don’t have any invitations yet !
                </p>
              </div>
            )}
          </>
        )}
      </section>
      {openGroupInvitations && (
        <GroupInvitationsModal
          isOpen={openGroupInvitations}
          onClose={() => {
            setOpenGroupInvitations(false);
          }}
          actionType={actionType}
          projects={userInvitations}
        />
      )}
    </>
  );
}
