import React, { useEffect, useState } from "react";
import { Image, Input } from "@nextui-org/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import locationIcon from "../../Assets/community/geolocation.svg";
import imgIcon from "../../Assets/community/picture.svg";
import AddPostModal from "./AddPostModal";

export default function AddPost() {
  const [userImg, setUserImg] = useState(null);

  const { session } = useClerk();
  const isSignedIn = localStorage.getItem("token");
  const clerkUser = useUser();

  const getData = () => {
    if (session?.user && isSignedIn) {
      setUserImg(clerkUser.user.imageUrl);
    }
  };

  useEffect(() => {
    getData();
  }, [clerkUser.user]);

  const [addPostModal, setAddPostModal] = useState(false);

  return (
    <>
      <div className="bg-white rounded-4xl border-2 border-solid border-grayBorder p-5 w-full no-focus">
        <div className="post flex items-center phone:gap-5 gap-2.5 mb-5 px-2.5 w-full 2xl:px-[15%] xl:px-[10%]">
          <Image
            src={userImg}
            className="phone:min-w-[51px] phone:max-w-[51px] phone:min-h-[51px] phone:max-h-[51px] min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] rounded-full"
            alt="userImg"
          />
          <button
            onClick={() => {
              setAddPostModal(true);
            }}
            className="px-[20px] py-2.5 bg-[#F5F6FF] rounded-[40px] flex-1 phone:h-[51px] text-cardGrayColor text-left phone:text-lg text-xs"
          >
            Whats on your mind?
          </button>
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={() => {
              setAddPostModal(true);
            }}
            className="flex items-center justify-center gap-[15px] phone:w-1/3 w-1/2 border-r-2 border-solid border-grayBorder"
          >
            <Image
              src={locationIcon}
              alt="Location"
              className="w-[24px] h-[24px]"
            />
            <h4 className="text-base font-bold text-blueTimeline leading-[18.4px]">
              Location
            </h4>
          </button>
          <button
            onClick={() => {
              setAddPostModal(true);
            }}
            className="flex items-center justify-center gap-[15px] phone:w-1/3 w-1/2"
          >
            <Image src={imgIcon} alt="Image" className="w-[24px] h-[24px]" />
            <h4 className="text-base font-bold text-blueTimeline leading-[18.4px]">
              Image
            </h4>
          </button>
        </div>
      </div>
      {addPostModal && (
        <AddPostModal
          isOpen={addPostModal}
          onClose={() => setAddPostModal()}
          frondData={{
            editId: "",
            editLatitude: "",
            editLongitude: "",
            editCountry: "",
            editCity: "",
            editLocation: "",
          }}
          editImage={""}
        />
      )}
    </>
  );
}

/*
<Input
            type="text"
            placeholder="Whats on your mind?"
            classNames={{
              base: "h-[51px] add-post-input",
              input: "border-none p-0",
              inputWrapper:
                "px-[20px] py-2.5 bg-[#F5F6FF] rounded-[40px] in-wrap h-full",
              mainWrapper: "bg-[#F5F6FF] rounded-[40px]",
            }}
          />
*/
