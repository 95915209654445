import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import dots from "../../Assets/community/dots-vertical.svg";
import postImg from "../../Assets/community/post-img.svg";
import grayLikesIcon from "../../Assets/community/gray-likes.svg";
import grayCommentsIcon from "../../Assets/community/gray-comments.svg";
import { LoadingContext } from "Context/LoadingContext";
import axios from "axios";
import { HashtagsContext } from "Context/HashtagsContext";
import { PopUpContext } from "Context/PopUpContext";
import { toast } from "react-toastify";
import AddPostModal from "./AddPostModal";

export default function Trending() {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);

  const { isTrendingLoaded, setIsTrendingLoaded } = useContext(LoadingContext);

  const [isEditModal, setIsEditModal] = useState(false);
  const [frondData, setFrondData] = useState([]);

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  useLayoutEffect(() => {
    if (!isTrendingLoaded) {
      setTimeout(() => {
        setIsTrendingLoaded(true);
      }, 2000);
    }
  }, [isTrendingLoaded]);

  useEffect(() => {
    if (itemKey === "delete") {
      deletePost();
      setItemKey(null);
    } else if (itemKey === "edit") {
      getPostData();
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  let authToken = localStorage.getItem("token");

  const [topHashtags, setTopHashtags] = useState([]);

  const fetchHashtags = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/hashtags/top`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setTopHashtags(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchHashtags();
  }, []);

  const { setTargetHashtag } = useContext(HashtagsContext);

  const [mostLikePosts, setMostLikedPosts] = useState([]);

  const fetchMostLiked = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/mostliked`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMostLikedPosts(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchMostLiked();
  }, []);

  const { setIsAddPost, isRefetchTrending } = useContext(PopUpContext);

  async function deletePost() {
    try {
      const { data } = await axios.delete(
        `https://admin.thefrondappadmin.com/api/posts/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast("Post Deleted", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsAddPost(true);
      setTimeout(() => {
        setIsAddPost(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  async function getPostData() {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/posts/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setFrondData(data);
      setIsEditModal(true);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  const makeUrlsActive = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.replace(urlRegex, (url) => {
      return `<a href="${url}" class="bg-mainGradiant bg-clip-text text-transparent underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  useEffect(() => {
    if (isRefetchTrending) {
      fetchMostLiked();
    }
  }, [isRefetchTrending]);

  return (
    <>
      <section
        className={`trending hidden lg:block p-5 right-0 lg:min-w-[317px] lg:max-w-[317px] bg-white rounded-4xl border-2 border-solid border-grayBorder absolute z-20 no-focus transition-all duration-400 ${
          !isTrendingLoaded ? "opacity-0" : "opacity-100"
        }`}
      >
        <div>
          <h3 className="text-textColor leading-[20.7px] font-bold text-lg mb-[15px] pb-[15px] border-b-1 border-solid border-grayBorder">
            Top Trending Posts
          </h3>
          {topHashtags.length > 0 && (
            <div className="flex flex-wrap items-start mb-7">
              {topHashtags.map((item, idx) => (
                <button
                  className=""
                  key={item?.id}
                  onClick={() => {
                    setTargetHashtag(item?.tag);
                  }}
                >
                  <p className="text-textColor break-before-all leading-[20.7px] text-lg bg-white transition-all duration-300 hover:bg-lightWhite py-2.5 px-[15px] rounded-[10px]">
                    #{item?.tag}
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>
        <div>
          <h3 className="text-textColor leading-[20.7px] font-bold text-lg mb-[15px] pb-[15px] border-b-1 border-solid border-grayBorder">
            Most Liked Posts
          </h3>
          {mostLikePosts?.length > 0 && (
            <div className="flex flex-col">
              {mostLikePosts.map((item, idx) => (
                <div
                  key={item?.id}
                  className={`post ${
                    idx !== mostLikePosts.length - 1 &&
                    "border-b-1 border-solid border-grayBorder mb-5 pb-5"
                  }`}
                >
                  <div className="flex items-start justify-between gap-2.5 mb-2.5">
                    <div className="flex items-center gap-2.5">
                      <Image
                        src={item?.user?.img_url}
                        alt={item?.user?.name}
                        className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] object-cover rounded-full"
                      />
                      <div>
                        <h5 className="mb-[3px] text-textColor font-bold text-base leading-[22px]">
                          {item?.user?.name}
                        </h5>
                        <p className="text-cardGrayColor text-sm leading-[16.1px]">
                          {timeAgo(item?.created_at)}
                        </p>
                      </div>
                    </div>
                    {item?.is_owner && (
                      <Dropdown
                        classNames={{
                          content:
                            "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                        }}
                        placement="left-start"
                      >
                        <DropdownTrigger>
                          <button
                            className="w-[24px] h-[24px]"
                            onClick={handleDotsClick}
                          >
                            <img
                              src={dots}
                              alt="Dots"
                              className="min-w-[24px] min-h-[24px]"
                            />
                          </button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Post Actions"
                          onAction={(key) => openModal(key, item?.id)}
                        >
                          <DropdownItem
                            textValue="edit post"
                            key="edit"
                            className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            key="delete"
                            className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                            color="danger"
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeUrlsActive(item?.content),
                    }}
                    className="mb-2.5 text-textColor leading-[22px] break-all"
                  />
                  <div className="flex justify-between mx-auto items-center w-5/6">
                    <div className="flex items-center gap-1.5">
                      <Image
                        src={grayLikesIcon}
                        alt="Likes"
                        className="w-[18px] h-[18px]"
                      />
                      <p className="text-sm text-cardGrayColor leading-[16.1px]">
                        {item?.likes?.length}
                      </p>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <Image
                        src={grayCommentsIcon}
                        alt="Comments"
                        className="w-[18px] h-[18px]"
                      />
                      <p className="text-sm text-cardGrayColor leading-[16.1px]">
                        {item?.comments?.length}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      {isEditModal && (
        <AddPostModal
          isOpen={isEditModal}
          onClose={() => setIsEditModal()}
          frondData={{
            editId: frondData?.id,
            editContent: frondData?.content,
            editLatitude: frondData?.latitude,
            editLongitude: frondData?.longitude,
            editCountry: "",
            editCity: "",
            editLocation: "",
          }}
          editImage={frondData?.media[0]?.original_url}
        />
      )}
    </>
  );
}
