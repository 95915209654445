import React from "react";
import { MapPin, Clock, DollarSign, ExternalLink } from "lucide-react";
import PriceTag from "./PriceTag";
import LocationInfo from "./LocationInfo";
import BookingButton from "./BookingButton";
import { getImageWithFallback } from "../../../../utils/imageHelpers";

const ActivityCard = ({ activity }) => {
  const {
    name,
    shortDescription,
    description,
    price,
    pictures,
    bookingLink,
    minimumDuration,
    geoCode,
  } = activity;

  return (
    <div className="bg-white rounded-xl h-full shadow-lg overflow-hidden transition-transform duration-300 hover:scale-[1.02] hover:shadow-xl">
      <div className="relative">
        <img
          src={getImageWithFallback(pictures[0])}
          alt={name}
          className="w-full h-48 object-cover"
        />
        <PriceTag price={price} />
      </div>

      <div className="p-5">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{name}</h3>
        <p className="text-gray-600 text-sm mb-4">
          {description || shortDescription}
        </p>

        <div className="space-y-2">
          <LocationInfo geoCode={geoCode} />
          {minimumDuration && (
            <div className="flex items-center text-gray-600 text-sm">
              <Clock className="w-4 h-4 mr-2 text-gray-400" />
              <span>{minimumDuration}</span>
            </div>
          )}
        </div>

        <BookingButton bookingLink={bookingLink} />
      </div>
    </div>
  );
};

export default ActivityCard;
