import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import magnifier from "../../Assets/my-fronds/magnifier.svg";
import frondImg from "../../Assets/my-fronds/frond/category/other.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function AppSearch({ isOpen, onClose }) {
  const handleClose = () => {
    onClose();
  };

  const authToken = localStorage.getItem("token");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState({
    fronds: [],
    hashtags: [],
    posts: [],
    library: [],
  });
  const [selectedTab, setSelectedTab] = useState("All");

  const getSearchResults = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/search`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          params: { query: query },
        }
      );
      setResults(data || { fronds: [], hashtags: [], posts: [], library: [] });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query.trim() !== "") {
        getSearchResults();
      }
    }, 500);
    return () => clearTimeout(handler);
  }, [query]);

  const renderTabButton = (name) => (
    <button
      onClick={() => setSelectedTab(name)}
      className={`py-1.5 px-2.5 flex items-center justify-center text-sm rounded-full flex-1 ${
        selectedTab === name
          ? "bg-mainGradiant text-white"
          : "bg-lightWhite text-cardGrayColor"
      }`}
    >
      {name}
    </button>
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => onClose()}
      placement="center"
      backdrop="opaque"
      size="4xl"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center py-5",
        body: "border-b-1 border-solid border-grayBorder pt-2 pb-4 overflow-auto",
        closeButton: "hidden",
        base: "p-5 search-modal",
      }}
      hideCloseButton={true}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-between">
              <h2 className="text-textColor font-bold text-lg">Search</h2>
              <button onClick={handleClose} aria-label="Close">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody>
              <Input
                type="text"
                variant="bordered"
                placeholder="Search frond..."
                startContent={<img src={magnifier} />}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                classNames={{
                  base: "",
                  input:
                    "border-none px-[22px] text-darkBlue text-base search-input",
                  innerWrapper: "h-[39px] px-[15px]",
                  inputWrapper:
                    "border-[0.86px] p-0 border-solid bg-lightWhite search-input-box no-focus border-lightWhite rounded-[20px] h-[39px]",
                }}
              />

              <div className="flex items-center gap-2.5 lg:w-[60%] sm:w-[80%] mx-auto mt-3 mb-3">
                {renderTabButton("All")}
                {renderTabButton("Fronds")}
                {renderTabButton("Hashtags")}
                {renderTabButton("Posts")}
                {renderTabButton("Library")}
              </div>

              {(selectedTab === "All" || selectedTab === "Fronds") && (
                <div className="mb-5">
                  <div className="flex items-center gap-2.5 mb-3">
                    <h3 className="text-cardGrayColor text-sm">Fronds</h3>
                    <div className="flex-1 h-[1px] bg-grayBorder"></div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {results.fronds?.length > 0 ? (
                      results.fronds.map((item) => (
                        <div key={item.id} className="flex gap-3 items-center">
                          <Image src={frondImg} alt="Frond" />
                          <Link
                            onClick={handleClose}
                            to={`/frond/about/${item?.id}`}
                            className="text-textColor text-sm"
                          >
                            {item.title}
                          </Link>
                        </div>
                      ))
                    ) : (
                      <p className="text-textColor text-sm">No fronds found.</p>
                    )}
                  </div>
                </div>
              )}

              {(selectedTab === "All" || selectedTab === "Posts") && (
                <div className="mb-5">
                  <div className="flex items-center gap-2.5 mb-3">
                    <h3 className="text-cardGrayColor text-sm">Posts</h3>
                    <div className="flex-1 h-[1px] bg-grayBorder"></div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {results.posts?.length > 0 ? (
                      results.posts.map((item) => (
                        <Link
                          onClick={handleClose}
                          to={"/community"}
                          key={item.id}
                          className="flex gap-3 items-center"
                        >
                          <span className="text-textColor text-sm">
                            {item.content}
                          </span>
                        </Link>
                      ))
                    ) : (
                      <p className="text-textColor text-sm">No posts found.</p>
                    )}
                  </div>
                </div>
              )}

              {(selectedTab === "All" || selectedTab === "Hashtags") && (
                <div className="mb-5">
                  <div className="flex items-center gap-2.5 mb-3">
                    <h3 className="text-cardGrayColor text-sm">Hashtags</h3>
                    <div className="flex-1 h-[1px] bg-grayBorder"></div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {results.hashtags?.length > 0 ? (
                      results.hashtags.map((item) => (
                        <div key={item.id} className="flex gap-3 items-center">
                          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-offWhite text-[#787FB9] text-sm font-bold">
                            #
                          </div>
                          <Link
                            onClick={handleClose}
                            to={"/community"}
                            className="text-textColor text-sm"
                          >
                            #{item.tag}
                          </Link>
                        </div>
                      ))
                    ) : (
                      <p className="text-textColor text-sm">
                        No hashtags found.
                      </p>
                    )}
                  </div>
                </div>
              )}

              {(selectedTab === "All" || selectedTab === "Library") && (
                <div>
                  <div className="flex items-center gap-2.5 mb-3">
                    <h3 className="text-cardGrayColor text-sm">Library</h3>
                    <div className="flex-1 h-[1px] bg-grayBorder"></div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {results.library?.length > 0 ? (
                      results.library.map((item) => (
                        <div key={item.id} className="flex gap-3 items-center">
                          {item.video_url ? (
                            <video
                              src={item.video_url}
                              controls
                              className="rounded-md w-[200px]"
                            ></video>
                          ) : (
                            <p className="text-textColor text-sm">
                              No video available.
                            </p>
                          )}
                          <Link
                            onClick={handleClose}
                            to={"/library"}
                            className="text-textColor text-sm font-bold"
                          >
                            {item.title}
                          </Link>
                        </div>
                      ))
                    ) : (
                      <p className="text-textColor text-sm">
                        No library items found.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
