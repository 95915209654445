import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import closeIcon from "../../../Assets/popup/cross-square.svg";
import urlIcon from "../../../Assets/people/fasten.svg";
import smsIcon from "../../../Assets/people/sms.svg";
import magnifier from "../../../Assets/people/magnifier.svg";
import { AuthContext } from "Context/AuthContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import linkImg from "../../../Assets/landing/feedbackCardImg.svg";

const InvitePeople = ({ isOpen, onClose, frondId, isModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [members, setMembers] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  let authToken = localStorage.getItem("token");
  const getMembers = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/projects/${frondId}/users`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setMembers(data?.response?.data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  // useEffect(() => {
  //   if (frondId) {
  //     getMembers();
  //   }
  // }, [frondId]);

  const { setIsAddMember } = useContext(PopUpContext);

  async function addMember(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/projects/${frondId}/invite`,
        {
          email: reqBody.email,
          role: "member",
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data.message === "User invited successfully.") {
        toast("User invited successfully.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
        // getMembers();
        reset();
        setIsAddMember(true);
        setTimeout(() => {
          setIsAddMember(false);
        }, 1000);

        // pushNotification(data?.user?.id, "dummy frond");
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  const getSearchUsers = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/users/search`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          params: {
            query: searchVal,
          },
        }
      );
      setMembers(data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (searchVal && searchVal.length > 2) {
      getSearchUsers();
    }

    if (!searchVal) {
      setMembers([]);
    }
  }, [searchVal]);

  useEffect(() => {}, [isDisabled]);

  useEffect(() => {
    handleResize();
  }, [members]);

  // const pushNotification = async (id, name) => {
  //   try {
  //     const { data } = axios.post(
  //       `https://admin.thefrondappadmin.com/api/notifications`,
  //       {
  //         user_id: id,
  //         title: name,
  //         message: name,
  //         link: frondId,
  //         type: "invite",
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );
  //   } catch (error) {}
  // };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden invite-modal",
        footer:
          "absolute min-h-[25px] max-h-[25px] w-full bottom-0 bg-white z-10 p-0",
        body: "px-[30px] mb-2.5 py-0",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Invite People
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <div className="lg:max-h-[63vh] lg:min-h-[63vh] max-h-[62vh] min-h-[62vh] overflow-y-auto scroll-outter">
                <ModalBody className="w-[100%] scroll-inner px-0">
                  <div className="relative about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                    <form
                      className="bg-white fixed sm:w-[430px] max-[639px]:w-[418px] max-[459px]:w-[308px] z-[99999]"
                      onSubmit={handleSubmit(addMember)}
                    >
                      <div className="flex items-start gap-2.5 pb-[15px] sm:px-[30px] max-[659px]:px-[30px] border-b-1 border-solid border-grayBorder">
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: "Email is required",
                            validate: (value) => {
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              return (
                                emailRegex.test(value) ||
                                "Invalid email address"
                              );
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              onInput={(e) => {
                                if (e.target.value.length > 0) {
                                  setIsDisabled(false);
                                } else {
                                  setIsDisabled(true);
                                }
                              }}
                              startContent={
                                <Image
                                  src={smsIcon}
                                  alt="SMS"
                                  className="min-w-[18px] max-w-[18px] min-h-[18px] max-h-[18px]"
                                  classNames={{
                                    wrapper:
                                      "min-w-[18px] max-w-[18px] min-h-[18px] max-h-[18px] ps-[15px]",
                                  }}
                                />
                              }
                              {...field}
                              type="text"
                              variant="bordered"
                              placeholder="Use Email address"
                              isInvalid={errors.email?.message ? true : false}
                              errorMessage={errors.email?.message}
                              classNames={{
                                base: `input-noMargin ${
                                  errors.email?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                label:
                                  "text-textColor font-normal phone:text-sm text-[10px]",
                                input:
                                  "border-none px-[18px] ms-[5px] py-0 phone:text-base text-[10px]",
                                mainWrapper: `${
                                  errors.email?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                innerWrapper: "h-[39px]",
                                inputWrapper: `border-[0.86px] p-0 border-solid ${
                                  errors.email?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] h-[39px]`,
                              }}
                            />
                          )}
                        />

                        <button
                          disabled={isDisabled}
                          type="submit"
                          className={`w-[75px] h-[39px] rounded-[6px] py-[11px] px-[18px] flex justify-center items-center ${
                            isDisabled ? "bg-btnGray" : "bg-darkBlue"
                          } text-white leading-[18.4px]`}
                        >
                          {isLoading ? (
                            <span className="flex justify-center items-center text-white">
                              <i className="fa-solid fa-spinner fa-spin"></i>
                            </span>
                          ) : (
                            "Invite"
                          )}
                        </button>
                      </div>
                    </form>

                    <div
                      className={`search px-[30px] my-5 fixed ${
                        errors.email?.message ? "pt-[81px]" : "pt-[49px]"
                      } pb-5 bg-white sm:w-[430px] max-[639px]:w-[418px] max-[459px]:w-[308px] z-[9999]`}
                    >
                      <Input
                        onInput={(e) => {
                          setSearchVal(e.target.value);
                        }}
                        startContent={
                          <Image
                            src={magnifier}
                            alt="Magnifier"
                            className="min-w-[18px] max-w-[18px] min-h-[18px] max-h-[18px]"
                            classNames={{
                              wrapper:
                                "min-w-[18px] max-w-[18px] min-h-[18px] max-h-[18px] ps-[15px]",
                            }}
                          />
                        }
                        type="text"
                        variant="bordered"
                        placeholder="People on frond..."
                        classNames={{
                          base: `input-noMargin h-[39px]`,
                          label:
                            "text-textColor font-normal phone:text-sm text-[10px]",
                          input:
                            "border-none px-[18px] ms-[5px] phone:text-base text-[10px]",
                          mainWrapper: `h-[39px]`,
                          innerWrapper: "h-[39px]",
                          inputWrapper: `border-[0.86px] p-0 border-solid
                                                         border-[#E5E4EB]
                                                         rounded-[8px] h-[39px]`,
                        }}
                      />
                    </div>
                    {members?.users?.length > 0 && (
                      <div
                        className={`members flex flex-col gap-[15px] px-[30px] ${
                          errors.email?.message ? "mt-[162px]" : "mt-[130px]"
                        }`}
                      >
                        {members?.users?.map((item, idx) => (
                          <div
                            id={idx}
                            key={item?.id}
                            className={`member flex justify-between items-center gap-2.5 ${
                              idx === members?.users?.length - 1
                                ? "mb-[45px]"
                                : ""
                            }`}
                          >
                            <div className="flex gap-5 items-center">
                              <Image
                                src={item?.img_url}
                                className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-full object-cover"
                                alt={item?.name}
                              />
                              <div>
                                <h4 className="text-textColor font-bold phone:text-base text-sm leading-[22px]">
                                  {item?.name}
                                </h4>
                              </div>
                            </div>
                            <button
                              disabled={item?.invited}
                              onClick={() => {
                                addMember({ email: item.email });
                              }}
                              type="button"
                              className={`w-[62px] h-[28px] text-white flex justify-center items-center rounded-[6px] py-[6px] px-2.5 ${
                                item?.invited
                                  ? "bg-cardGrayColor"
                                  : "bg-greenTimeline"
                              }`}
                            >
                              {item?.invited ? "Invited" : "Invite"}
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    {members?.length > 0 && (
                      <div
                        className={`members flex flex-col gap-[15px] px-[30px] ${
                          errors.email?.message ? "mt-[162px]" : "mt-[130px]"
                        }`}
                      >
                        {members?.map((item, idx) => (
                          <div
                            id={idx}
                            key={item?.id}
                            className={`member flex justify-between items-center gap-2.5 ${
                              idx === members?.length - 1 ? "mb-[45px]" : ""
                            }`}
                          >
                            <div className="flex gap-5 items-center">
                              <Image
                                src={item?.img_url}
                                className="w-[50px] h-[50px] rounded-full"
                                alt={item?.name}
                              />
                              <h4 className="text-textColor font-bold text-base leading-[22px]">
                                {item?.name}
                              </h4>
                            </div>
                            <button
                              disabled={item?.invited}
                              onClick={() => {
                                addMember({ email: item.email });
                              }}
                              type="button"
                              className={`w-[62px] h-[28px] text-white flex justify-center items-center rounded-[6px] py-[6px] px-2.5 ${
                                item?.invited
                                  ? "bg-cardGrayColor"
                                  : "bg-greenTimeline"
                              }`}
                            >
                              {item?.invited ? "Invited" : "Invite"}
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    {members?.length === 0 && (
                      <div
                        className={`members transition-all duration-300 text-center ${
                          errors.email?.message ? "" : "mt-[130px]"
                        } ${
                          members?.length === 0
                            ? "opacity-100 mt-[162px]"
                            : "opacity-0 mt-0 h-0"
                        }`}
                      >
                        <p className="text-cardGrayColor text-lg font-bold leading-[22px]">
                          You can search for users to invite or enter an email
                          address.
                        </p>
                      </div>
                    )}
                  </div>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </div>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default InvitePeople;
