import React, { useContext, useEffect, useState } from "react";
import editPen from "../../Assets/frond-details/edit-pen.svg";
import rocket from "../../Assets/frond-details/rocket.svg";
import dollar from "../../Assets/frond-details/dollar.svg";
import calendar from "../../Assets/frond-details/calendar.svg";
import like from "../../Assets/frond-details/like.svg";
import { Image } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import EditDetailsModal from "./EditDetailsModal";
import { SettingsContext } from "Context/SettingsContext";
import useSettings from "hooks/useSettings";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";
import editImgIcon from "../../Assets/frond-details/message-edit.svg";
import { PopUpContext } from "Context/PopUpContext";
import EditFrondImageModal from "./EditFrondImageModal";
import { CashedDataContext } from "Context/CashedDataContext";

const AboutFrondDetails = () => {
  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [frondDetails, setFrondDetails] = useState([]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");

  const { frondId } = useParams();
  let authToken = localStorage.getItem("token");

  const getFrondDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondDetails(data?.response?.data);
      if (data?.response?.data?.frond?.start_date) {
        setStartDateArr(data?.response?.data?.frond?.start_date.split("/"));
      }
      if (data?.response?.data?.frond?.end_date) {
        setEndDateArr(data?.response?.data?.frond?.end_date.split("/"));
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondDetails();
  }, []);

  const { isEditLocation } = useContext(PopUpContext);

  useEffect(() => {
    if (isEditLocation) {
      getFrondDetails();
    }
  }, [isEditLocation]);

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  // Change Img

  const [editImagenModal, setEditImageModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="about-frond">
        <div className="flex gap-x-2.5 gap-y-2.5 items-start justify-between timelineMedia:flex-nowrap flex-wrap">
          <div className="content flex flex-col">
            <div className="flex items-start gap-5">
              <div className="info flex items-start phone:gap-[45px] gap-5 mb-5">
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Category
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={rocket}
                      alt="Rocket"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondDetails?.frond?.category || "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Importance
                  </h5>
                  <div
                    className={`${
                      !frondDetails?.frond?.priority &&
                      "text-center text-[10px]"
                    }`}
                  >
                    <span
                      className={`leading-normal ${
                        frondDetails?.frond?.priority === "High" &&
                        "text-[#FF3D00E5]"
                      } ${
                        frondDetails?.frond?.priority === "Medium" &&
                        "text-[#FFC000]"
                      } ${
                        frondDetails?.frond?.priority === "Low" &&
                        "text-[#5CCDB0]"
                      } ${!frondDetails?.frond?.priority && "text-textColor"}`}
                    >
                      {frondDetails?.frond?.priority
                        ? frondDetails?.frond?.priority
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Budget
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={dollar}
                      alt="Dollar"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondDetails?.frond?.budget
                        ? frondDetails?.frond?.budget
                        : "-"}
                    </span>
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondDetails?.frond?.currency
                        ? frondDetails?.frond?.currency
                        : "$"}
                    </span>
                  </div>
                </div>
              </div>
              {frondSettings.is_owner && (
                <button onClick={() => setEditDetailsModal(true)}>
                  <Image
                    src={editPen}
                    alt="Pen"
                    className="phone:min-w-6 phone:min-h-6 phone:max-w-6 phone:max-h-6 min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                </button>
              )}
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Start Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {startDateArr
                      ? `${startDateArr[0]} ${months[startDateArr[1] - 1]}, ${
                          startDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  End Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {endDateArr
                      ? `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                          endDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Description
                </h5>
                <div
                  className={`${!frondDetails?.frond?.desc && "text-center"}`}
                >
                  <p className="leading-normal text-textColor phone:text-base text-[10px] break-before-all">
                    {frondDetails?.frond?.desc
                      ? frondDetails?.frond?.desc
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={like}
                    alt="Heart"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Tags
                  </span>
                </div>
                <div
                  className={`flex items-center phone:text-base text-[10px] ${
                    frondDetails?.tags?.length === 0 && "justify-center"
                  } gap-2.5 flex-wrap`}
                >
                  {frondDetails?.tags?.length > 0
                    ? frondDetails?.tags.map((item, idx) => (
                        <div
                          key={idx}
                          className="py-1.5 px-2.5 rounded-[20px] bg-mainBlue text-white flex justify-center items-center"
                        >
                          <span className="phone:text-sm text-[10px] leading-[16.1px]">
                            {item.name.en}
                          </span>
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          <div className="card-img relative">
            <div
              className={`absolute inset-0 ${
                frondDetails?.image ? "bg-frondDetails" : "bg-textColor"
              } z-20 rounded-[20px]`}
            ></div>
            <div className="absolute phone:top-5 top-2.5 phone:left-5 phone:right-5 left-2.5 right-2.5 flex justify-end items-center z-30 phone:gap-[30px] gap-2.5">
              <div className="flex gap-2.5 items-center min-w-fit">
                {frondSettings.is_owner && (
                  <button
                    onClick={() => setEditImageModal(true)}
                    className="flex gap-2.5 items-center shadow-editImgShadow"
                  >
                    <Image
                      src={editImgIcon}
                      alt="Edit Image"
                      className="phone:min-w-[24px] phone:min-h-[24px] phone:max-w-[24px] phone:max-h-[24px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-[18.4px] text-white phone:text-base text-[12px]">
                      Edit Image
                    </span>
                  </button>
                )}
              </div>
            </div>
            {frondDetails?.image ? (
              <div
                className="phone:w-[300px] phone:h-[300px] w-[200px] h-[200px]"
                style={{
                  backgroundImage: `url(${frondDetails?.image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  maxHeight: "300px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              />
            ) : (
              <div className="flex justify-center phone:w-[300px] phone:h-[300px] w-[200px] h-[200px] items-center rounded-[20px]">
                <Image
                  classNames={{ wrapper: "z-30 rounded-[20px]" }}
                  src={placholderIcon}
                  alt={frondDetails?.frond?.title}
                  className="w-[46px] h-[50px]"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {editDetailsModal && (
        <EditDetailsModal
          isOpen={editDetailsModal}
          isModalOpen={true}
          onClose={() => {
            setEditDetailsModal(false);
          }}
          getFrondData={getFrondDetails}
          frondData={{
            editId: frondDetails?.frond?.id,
            editTitle: frondDetails?.frond?.title,
            editCategory: frondDetails?.frond?.category,
            editDesc: frondDetails?.frond?.desc,
            editStartDate: startDateArr,
            editEndDate: endDateArr,
            editBudget: frondDetails?.frond?.budget,
            editCurrecy: frondDetails?.frond?.currency,
            editTags: frondDetails?.tags,
            editImportance: frondDetails?.frond?.priority,
            editLatitude: frondDetails?.latitude,
            editLongitude: frondDetails?.longitude,
            editCountry: frondDetails?.frond?.country,
            editCity: frondDetails?.frond?.city,
            editLocation: frondDetails?.frond?.location,
          }}
        />
      )}
      {editImagenModal && (
        <EditFrondImageModal
          isOpen={editImagenModal}
          onClose={() => {
            setEditImageModal(false);
          }}
          editId={frondDetails?.frond?.id}
          editTitle={frondDetails?.frond?.title}
          editCategory={frondDetails?.frond?.category}
          editImage={frondDetails?.image}
          editBudget={frondDetails?.frond?.budget}
          editCurrecy={frondDetails?.frond?.currency}
          editDesc={frondDetails?.frond?.desc}
          editStartDate={startDateArr}
          editEndDate={endDateArr}
          editImportance={frondDetails?.frond?.priority}
          editLatitude={frondDetails?.latitude}
          editLongitude={frondDetails?.longitude}
          editCountry={frondDetails?.frond?.country}
          editCity={frondDetails?.frond?.city}
          editLocation={frondDetails?.frond?.location}
          editTags={frondDetails?.tags}
          getFrondData={getFrondDetails}
        />
      )}
    </>
  );
};

export default AboutFrondDetails;
