import React, { useContext } from "react";
import errorImg from "../../Assets/404/404.svg";
import { Image } from "@nextui-org/react";
import { AuthContext } from "Context/AuthContext";
import { Link } from "react-router-dom";

const Error404 = () => {
  const { token } = useContext(AuthContext);
  return (
    <section
      className={`404 flex flex-col gap-2 justify-center items-center bg-white ${
        !token
          ? "h-[calc(100vh-85px)]"
          : "h-[calc(100vh-109px)] rounded-4xl border-2 border-solid border-grayBorder"
      } relative`}
    >
      {!token && (
        <>
          <div className="absolute left-0 bottom-0 sm:w-[344px] w-[55%] h-[384.17px] z-10 bg-orangeBuble404"></div>
          <div className="absolute right-0 top-[5%] sm:w-[260px] w-[40%] h-[348px] z-10 bg-print404"></div>
        </>
      )}
      <Image
        src={errorImg}
        alt="404"
        className="w-[239px] h-[239px]"
        classNames={{ wrapper: "relative z-20" }}
      />
      <p
        className={`${
          token && "mb-4"
        } text-cardGrayColor font-bold text-lg leading-[20.7px] sm:w-[437px] sm:px-0 px-4 mx-auto text-center relative z-20`}
      >
        The page you are looking for may have been moved or possibly never
        existed
      </p>
      {token && (
        <Link
          to={"/"}
          className="text-lg font-bold leading-[20.7px] text-white flex justify-center items-center bg-darkBlue rounded-4xl py-3 px-5 w-[161px] h-[45px] shadow-whatsappShadow"
        >
          Back to Home
        </Link>
      )}
    </section>
  );
};

export default Error404;
