import React, { useContext, useEffect, useState } from "react";
import photosIcon from "../../../Assets/frond-details/settings/picture.svg";
import notesIcon from "../../../Assets/frond-details/settings/files.svg";
import voiceIcon from "../../../Assets/frond-details/settings/graph.svg";
import linksIcon from "../../../Assets/frond-details/settings/fasten.svg";
import membersIcon from "../../../Assets/frond-details/settings/profile-2user.svg";
import timelineIcon from "../../../Assets/frond-details/settings/notepad-bookmark.svg";
import updatesIcon from "../../../Assets/frond-details/settings/notification-statu.svg";
import tasklistIcon from "../../../Assets/frond-details/settings/questionnaire-tablet.svg";
import { Image } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { SettingsContext } from "Context/SettingsContext";

const switchOptions = [
  {
    key: "photosSwitch",
    label: "Photos",
    icon: photosIcon,
    stateKey: "can_edit_photos",
  },
  {
    key: "filesSwitch",
    label: "Files",
    icon: notesIcon,
    stateKey: "can_edit_files",
  },
  {
    key: "notesSwitch",
    label: "Notes",
    icon: notesIcon,
    stateKey: "can_edit_notes",
  },
  {
    key: "voiceSwitch",
    label: "Voice Memos",
    icon: voiceIcon,
    stateKey: "can_edit_voice",
  },
  {
    key: "linksSwitch",
    label: "Links",
    icon: linksIcon,
    stateKey: "can_edit_links",
  },
  {
    key: "membersSwitch",
    label: "Members",
    icon: membersIcon,
    stateKey: "can_edit_members",
  },
  {
    key: "tasklistSwitch",
    label: "Tasklist",
    icon: tasklistIcon,
    stateKey: "can_edit_tasks",
  },
  {
    key: "timelineSwitch",
    label: "Timeline",
    icon: timelineIcon,
    stateKey: "can_edit_timeline",
  },
  {
    key: "updatesSwitch",
    label: "Updates",
    icon: updatesIcon,
    stateKey: "can_edit_updates",
  },
  {
    key: "discoverSwitch",
    label: "Discover",
    icon: membersIcon,
    stateKey: "can_edit_discover",
  },
];

export default function PrivacySettings() {
  const { frondId } = useParams();
  const { frondSettings, setFrondSettings } = useContext(SettingsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [settings, setSettings] = useState({
    photosSwitch: frondSettings.can_edit_photos,
    filesSwitch: frondSettings.can_edit_files,
    notesSwitch: frondSettings.can_edit_notes,
    voiceSwitch: frondSettings.can_edit_voice,
    linksSwitch: frondSettings.can_edit_links,
    membersSwitch: frondSettings.can_edit_members,
    tasklistSwitch: frondSettings.can_edit_tasks,
    timelineSwitch: frondSettings.can_edit_timeline,
    updatesSwitch: frondSettings.can_edit_updates,
    discoverSwitch: frondSettings.can_edit_discover,
  });

  const [filteredSwitchOptions, setFilteredSwitchOptions] =
    useState(switchOptions);

  const token = localStorage.getItem("token");

  async function changeSettings(updatedSetting) {
    const payload = {
      can_edit_photos: settings.photosSwitch,
      can_edit_files: settings.filesSwitch,
      can_edit_notes: settings.notesSwitch,
      can_edit_voice: settings.voiceSwitch,
      can_edit_links: settings.linksSwitch,
      can_edit_members: settings.membersSwitch,
      can_edit_tasks: settings.tasklistSwitch,
      can_edit_timeline: settings.timelineSwitch,
      can_edit_updates: settings.updatesSwitch,
      can_edit_discover: settings.discoverSwitch,
      ...updatedSetting,
    };

    try {
      const { data } = await axios.put(
        `https://admin.thefrondappadmin.com/api/settings/${frondId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.status === 200) {
        setFrondSettings(data?.response?.data);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  const handleToggle = (key) => {
    const updatedSwitchState = !settings[key];
    setSettings((prevState) => ({
      ...prevState,
      [key]: updatedSwitchState,
    }));
    changeSettings({
      [switchOptions.find((option) => option.key === key).stateKey]:
        updatedSwitchState,
    });
  };

  const [frondData, setFrondData] = useState([]);

  let authToken = localStorage.getItem("token");

  const renderSwitchItem = (key, label, icon, idx) => (
    <div
      className={`item flex justify-between gap-2.5 items-center ${
        idx !== filteredSwitchOptions.length - 1
          ? "pb-4 mb-4 border-b-1 border-solid border-grayBorder"
          : ""
      }`}
    >
      <div className="flex items-center gap-2.5">
        <Image src={icon} alt={label} className="w-[24px] h-[24px]" />
        <h6 className="text-textColor font-bold leading-[22px]">{label}</h6>
      </div>
      <div
        className={`${
          settings[key]
            ? "bg-mainGradiant justify-end"
            : "bg-darkBlue justify-start"
        } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[120px] h-[36px] rounded-full flex gap-2.5 items-center`}
        onClick={() => handleToggle(key)}
      >
        <div
          className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
            settings[key] ? "left-[calc(100%-28px)]" : "left-[5px]"
          }`}
        />
        <span
          className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
            settings[key] ? "left-2.5 right-auto" : "left-auto right-2.5"
          }`}
        >
          {settings[key] ? "Full Access" : "No Access"}
        </span>
      </div>
    </div>
  );

  // Get Frond Data

  const getFrondData = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getFrondData();
  }, []);

  useEffect(() => {
    const filteredOptions = switchOptions.filter((option) => {
      if (option.key === "discoverSwitch") {
        return frondData?.frond?.category === "Escape";
      }
      return true;
    });
    setFilteredSwitchOptions(filteredOptions);
  }, [frondData]);

  return (
    <section className="settings">
      <div className="title flex justify-between gap-2.5 items-center mb-[15px]">
        <h3 className="text-textColor text-lg font-bold leading-[20.7px]">
          Access Control Settings
        </h3>
        <h4 className="font-bold text-base text-cardGrayColor leading-[18.4px]">
          Visibility
        </h4>
      </div>

      <div className="content flex flex-col">
        {filteredSwitchOptions.map((option, idx) =>
          renderSwitchItem(option.key, option.label, option.icon, idx)
        )}
      </div>
    </section>
  );
}
