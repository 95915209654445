import React, { useState, useEffect } from "react";
import { MapPin, Calendar, Building2, Tag } from "lucide-react";
import axios from "axios";

const HotelInfoCard = ({ hotel, checkInDate, checkOutDate, adults }) => {
  const formattedDate = new Date(hotel.lastUpdate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [accessToken, setAccessToken] = useState("");
  const [hotelOffers, setHotelOffers] = useState([]);
  const [isError, setIsError] = useState("");

  async function fetchAccessToken() {
    const data = new URLSearchParams({
      client_id: "AExKid6IxxPOmXL7bfJxfUYpZDeKLh3k",
      client_secret: "TesdxsjvDa2Z7hAz",
      grant_type: "client_credentials",
    });

    try {
      const response = await axios.post(
        "https://test.api.amadeus.com/v1/security/oauth2/token",
        data.toString(),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
      setAccessToken(response.data.access_token);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  }

  async function fetchHotelOffers() {
    try {
      const response = await axios.get(
        `https://test.api.amadeus.com/v3/shopping/hotel-offers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            hotelIds: hotel.hotelId,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            // adults: adults,
          },
        }
      );
      setHotelOffers(response?.data?.data || []);
    } catch (error) {
      setIsError("No Offers found");
    }
  }

  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchHotelOffers();
    }
  }, [accessToken]);

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-100 hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
      <div className="p-6 flex-grow">
        <div className="flex items-start justify-between">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            {hotel.name}
          </h2>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            {hotel.chainCode}
          </span>
        </div>

        <div className="space-y-3 mt-4">
          <div className="flex items-center text-gray-600">
            <MapPin className="w-4 h-4 mr-2" />
            <span>
              {hotel.geoCode.latitude.toFixed(4)}°N,{" "}
              {hotel.geoCode.longitude.toFixed(4)}°W
            </span>
          </div>

          <div className="flex items-center text-gray-600">
            <Building2 className="w-4 h-4 mr-2" />
            <span>IATA: {hotel.iataCode}</span>
          </div>

          <div className="flex items-center text-gray-600">
            <Tag className="w-4 h-4 mr-2" />
            <span>Hotel ID: {hotel.hotelId}</span>
          </div>

          <div className="flex items-center text-gray-600">
            <Calendar className="w-4 h-4 mr-2" />
            <span>Last Updated: {formattedDate}</span>
          </div>
        </div>
      </div>

      {/* Render Hotel Offers */}
      <div className="p-6">
        {hotelOffers.length > 0
          ? hotelOffers.map((offer, index) => (
              <div key={index} className="border-t pt-4 mt-4">
                <h3 className="text-lg font-semibold">Offer {index + 1}</h3>
                <p>
                  Price: ${offer?.offers[0]?.price?.total}{" "}
                  {offer?.offers[0]?.price?.currency}
                </p>
                <p>Check-In: {offer?.offers[0]?.checkInDate}</p>
                <p>Check-Out: {offer?.offers[0]?.checkOutDate}</p>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default HotelInfoCard;
