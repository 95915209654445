import React from "react";
import { MapPin } from "lucide-react";
import { formatCoordinates } from "../../../../utils/formatters";

const LocationInfo = ({ geoCode }) => {
  return (
    <div className="flex items-center text-gray-600 text-sm">
      <MapPin className="w-4 h-4 mr-2 text-gray-400" />
      <span>{formatCoordinates(geoCode)}</span>
    </div>
  );
};

export default LocationInfo;
