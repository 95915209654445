import React from "react";
import { ExternalLink } from "lucide-react";

const BookingButton = ({ bookingLink }) => {
  return (
    <div className="mt-5">
      <a
        href={bookingLink}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center justify-center w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
      >
        Book Now
        <ExternalLink className="w-4 h-4 ml-2" />
      </a>
    </div>
  );
};

export default BookingButton;
