import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";

export default function ChangeAccessModal({
  isOpen,
  onClose,
  role,
  frondId,
  userId,
}) {
  const [accessType, setAccessType] = useState(role);
  const { setIsAddMember } = useContext(PopUpContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const callChange = async () => {
    let authToken = localStorage.getItem("token");
    setIsDisabled(true);
    setIsLoading(true);

    try {
      const { data } = await axios.patch(
        `https://admin.thefrondappadmin.com/api/projects/${frondId}/change-role`,
        {
          user_id: userId,
          role: accessType.toLowerCase(),
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Role Changed", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsAddMember(true);
      setTimeout(() => {
        setIsAddMember(false);
      }, 1000);
      onClose();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] sort-modal",
        footer: "justify-center items-center pb-2",
        body: "border-b-1 border-solid border-grayBorder p-0",
        closeButton: "hidden",
        base: "rounded-[10px]",
      }}
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <ModalBody className="overflow-auto">
              <div className="sort sm:w-auto w-full">
                <RadioGroup
                  onValueChange={(e) => {
                    setAccessType(e);
                  }}
                  classNames={{
                    label:
                      "text-blueTimeline text-center border-b-1 border-solid border-grayBorder font-bold leading-[30px] text-base pb-2.5",
                    base: "gap-0 py-[15px]",
                    wrapper: "gap-0 px-5",
                  }}
                  label="Change Access"
                  value={accessType}
                >
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="member"
                  >
                    Member
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 radio-filter sort-fronds",
                    }}
                    value="guest"
                  >
                    Guest
                  </Radio>
                </RadioGroup>
                <div className="w-full flex justify-center items-center mb-5">
                  <button
                    disabled={isDisabled}
                    onClick={() => {
                      callChange();
                    }}
                    className="w-[92px] h-[33px] rounded-4xl bg-mainGradiant text-white"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
