import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import {
  DateRangePicker,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import calendar from "../../Assets/create-frond/calendar.svg";
import up from "../../Assets/create-frond/up.svg";
import euro from "../../Assets/create-frond/euro.svg";
import RemoveComponent from "../../pages/CreateAFrond/RemoveComponent";
import { WithContext as ReactTags } from "react-tag-input";
import "./modalStyle.css";
import { parseDate } from "@internationalized/date";
import { AuthContext } from "Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";
import useSize from "hooks/useSize";

const EditDetailsModal = ({
  isOpen,
  onClose,
  frondData,
  getFrondData,
  isModalOpen,
}) => {
  const labelBudgetHtml = `Budget <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelCurrencyHtml = `Currency <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelDescHtml = `Description <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelDateHtml = `Start Date-End Date <span class='text-xs text-cardGrayColor'>(Optional)</span>`;

  const categories = ["Home", "Event", "Escape", "Venture", "Other"];
  const [importance, setImportance] = useState(null);

  const [descLength, setDescLength] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      category: "",
      desc: "",
      dateRange: "",
      budget: "",
      currency: "",
      tags: [],
      importance: "",
    },
  });

  // currencies

  const [currenciesList, setCurrenciesList] = useState([]);

  const getCurrencies = async () => {
    try {
      const { data } = await axios.get(
        `https://admin.thefrondappadmin.com/api/currencies`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrenciesList(data?.response?.data[0]);
      console.log(data?.response?.data[0]);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  //Tags

  const [tags, setTags] = useState([]);
  const [tagError, setTagError] = useState("");
  const [tagLimit, setTagLimit] = useState(false);

  useEffect(() => {
    setValue("tags", tags);
    if (tags.length === 10) {
      setTagLimit(true);

      setTimeout(() => {
        setTagLimit(false);
      }, 1500);
    }
  }, [tags, setValue]);

  const handleValidation = (tags) => {
    for (let tag of tags) {
      if (tag.text.length < 2 || tag.text.length > 60) {
        return "Each tag must be between 2 and 60 characters";
      }
      if (
        !/^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+)*$/gi.test(
          tag.text
        )
      ) {
        return "Tag may contain characters, numbers or special characters and shouldn't end with space";
      }
    }
    return true;
  };

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    setValue("tags", newTags);

    const validation = handleValidation(newTags);
    if (validation === true) {
      setTagError("");
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    const validation = handleValidation(newTags);

    if (validation === true) {
      setTags(newTags);
      setValue("tags", newTags);
      setTagError("");
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  useEffect(() => {
    if (frondData) {
      setValue("title", frondData.editTitle || "");
      setValue("category", frondData.editCategory || "");
      setValue("desc", frondData.editDesc || "");
      setValue("budget", frondData.editBudget || "");
      setValue("currency", frondData.editCurrecy || "");
      setValue("importance", frondData.editImportance || "");
      setImportance(frondData.editImportance || "");

      if (frondData.editStartDate && frondData.editEndDate) {
        setValue("dateRange", {
          start: parseDate(
            `${
              frondData.editStartDate[2]
            }-${frondData.editStartDate[1].padStart(
              2,
              "0"
            )}-${frondData.editStartDate[0].padStart(2, "0")}`
          ),
          end: parseDate(
            `${frondData.editEndDate[2]}-${frondData.editEndDate[1].padStart(
              2,
              "0"
            )}-${frondData.editEndDate[0].padStart(2, "0")}`
          ),
        });
      }

      if (Array.isArray(frondData.editTags)) {
        const processedTags = frondData.editTags
          .map((tag) => ({
            id: tag.name.en || "",
            text: tag.name.en || "",
          }))
          .filter((tag) => tag.text);

        setValue("tags", processedTags);
        setTags(processedTags);
      } else {
        setValue("tags", []);
        setTags([]);
      }

      if (frondData.editDesc) {
        setDescLength(frondData.editDesc.length);
      }
    }
  }, []);

  const { token } = useContext(AuthContext);

  async function editFrond(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    const start_date = reqBody.dateRange.start
      ? `${reqBody.dateRange.start.day}/${reqBody.dateRange.start.month}/${reqBody.dateRange.start.year}`
      : null;
    const end_date = reqBody.dateRange.end
      ? `${reqBody.dateRange.end.day}/${reqBody.dateRange.end.month}/${reqBody.dateRange.end.year}`
      : null;
    const tags = reqBody.tags.map((tag) => tag.text);

    try {
      const { data } = await axios.post(
        `https://admin.thefrondappadmin.com/api/frond-about/${frondData.editId}?_method=PUT`,
        {
          title: reqBody.title,
          start_date,
          end_date,
          category: reqBody.category,
          desc: reqBody.desc,
          budget: reqBody.budget,
          currency: reqBody.currency,
          tags: tags,
          priority: importance ? importance : null,
          latitude: frondData.editLatitude,
          longitude: frondData.editLongitude,
          location: frondData.editLocation,
          country: frondData.editCountry,
          city: frondData.editCity,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.status === 200) {
        toast("Updates Saved", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
        getFrondData();
        onClose();
        reset();

        setTags([]);
        setImportance(null);
        setIsEditTitle(true);
        setTimeout(() => {
          setIsEditTitle(false);
        }, 1000);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  const { isEditDetailsOpen, setIsEditTitle } = useContext(PopUpContext);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isEditDetailsOpen]);

  useEffect(() => {
    if (isEditDetailsOpen) {
      handleResize();
    }
  }, [isEditDetailsOpen]);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  const { width } = useSize();

  if (width === 0) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center p-0 min-h-[60px]",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Edit About
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(editFrond)}>
                <div className="max-h-[55vh] overflow-y-auto scroll-outter">
                  <ModalBody className="w-[100%] scroll-inner">
                    <div className="flex flex-col gap-2.5">
                      <Controller
                        name="title"
                        control={control}
                        rules={{
                          required: "Title is required",
                          minLength: {
                            value: 3,
                            message: "Minimum number of characters is 3",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maximum number of characters is 100",
                          },
                          validate: {
                            noTrailingSpace: (value) =>
                              !/\s$/.test(value) ||
                              "Title shouldn't end with a space",
                          },
                          pattern: {
                            value:
                              // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                              /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\:\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                            message:
                              "Title may contain characters, numbers and special characters",
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            {...field}
                            type="text"
                            variant="bordered"
                            label="Title"
                            placeholder="Title here..."
                            labelPlacement="outside"
                            classNames={{
                              base: `input-noMargin ${
                                errors.title?.message ? "h-[86px]" : "h-[68px]"
                              }`,
                              label:
                                "text-textColor font-normal phone:text-sm text-[10px]",
                              input:
                                "border-none px-[18px] phone:text-base text-[10px]",
                              mainWrapper: `${
                                errors.title?.message ? "h-[68px]" : "h-[42px]"
                              }`,
                              innerWrapper: "h-[42px]",
                              inputWrapper: `border-[0.86px] p-0 border-solid ${
                                errors.title?.message
                                  ? "invalid-text"
                                  : "border-[#E5E4EB]"
                              } rounded-[8px] h-[42px]`,
                            }}
                            isInvalid={errors.title?.message ? true : false}
                            errorMessage={errors.title?.message}
                          />
                        )}
                      />

                      <Controller
                        name="category"
                        control={control}
                        rules={{
                          required: "Category is required",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            variant="bordered"
                            label="Category"
                            labelPlacement="outside"
                            selectedKeys={field.value ? [field.value] : []}
                            placeholder="Select Category"
                            classNames={{
                              base: `input-noMargin ${
                                errors.category?.message
                                  ? "h-[96px]"
                                  : "h-[68px]"
                              } custom-input-value`,
                              label:
                                "text-textColor font-normal phone:text-sm text-[10px]",
                              mainWrapper: `${
                                errors.category?.message
                                  ? "h-[68px]"
                                  : "h-[42px]"
                              }`,
                              innerWrapper: "h-[42px] px-[18px]",
                              trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                                errors.location?.message
                                  ? "invalid-text"
                                  : "border-[#E5E4EB]"
                              } rounded-[8px] flex items-center justify-between`,
                              value:
                                "text-cardGrayColor phone:text-sm text-[10px] font-normal",
                              selectorIcon:
                                "phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]",
                            }}
                            selectorIcon={<img src={up} />}
                            isInvalid={errors.category?.message ? true : false}
                            errorMessage={errors.category?.message}
                          >
                            {categories.map((item) => (
                              <SelectItem key={item}>{item}</SelectItem>
                            ))}
                          </Select>
                        )}
                      />

                      <div>
                        <Controller
                          name="desc"
                          control={control}
                          rules={{
                            minLength: {
                              value: 2,
                              message: "Minimum number of characters is 2",
                            },
                            maxLength: {
                              value: 255,
                              message: "Maximum number of characters is 255",
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\/\\\:]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\:]+)*$/gi,
                              message:
                                "Description may contain characters, numbers or special characters and shouldn't end with space",
                            },
                          }}
                          render={({ field }) => (
                            <Textarea
                              {...field}
                              onInput={(e) =>
                                setDescLength(e.target.value.length)
                              }
                              variant="bordered"
                              maxLength={255}
                              placeholder="Text here..."
                              labelPlacement="outside"
                              classNames={{
                                base: "input-noMargin",
                                label:
                                  "text-textColor font-normal phone:text-sm text-[10px]",
                                input:
                                  "px-[18px] py-[12px] border-none create-frond-desc phone:text-base text-[10px]",
                                innerWrapper: "p-0 min-h-[116px] max-h-[116px]",
                                inputWrapper: `border-[0.86px] p-0 border-solid ${
                                  errors.title?.desc
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] min-h-[116px] max-h-[116px]`,
                              }}
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: labelDescHtml,
                                  }}
                                />
                              }
                              isInvalid={errors.desc?.message ? true : false}
                              errorMessage={errors.desc?.message}
                            />
                          )}
                        />
                        {!errors.desc?.message && 255 - descLength > 0 ? (
                          <p className="text-[.75rem] text-mainBlue mt-1">
                            {255 - descLength} remaining characters
                          </p>
                        ) : null}
                      </div>

                      <Controller
                        name="dateRange"
                        control={control}
                        render={({ field }) => (
                          <DateRangePicker
                            {...field}
                            variant="bordered"
                            labelPlacement="outside"
                            visibleMonths={width >= 640 ? 2 : 1}
                            selectorIcon={
                              <Image
                                src={calendar}
                                className="w-[18px] h-[18px]"
                              />
                            }
                            label={
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: labelDateHtml,
                                }}
                              />
                            }
                            isInvalid={errors.dateRange?.message ? true : false}
                            errorMessage={errors.dateRange?.message}
                            classNames={{
                              base: `mt-0 min-h-[42px] max-h-[96px]`,
                              label:
                                "text-textColor font-normal phone:text-sm text-[10px]",
                              inputWrapper:
                                "border-[0.86px] p-0 border-solid border-[#E5E4EB] rounded-[8px] min-h-[42px] flex-row-reverse date-box",
                              innerWrapper: "px-[18px] h-full no-action",
                              segment:
                                "date-picker-frond phone:text-sm text-[10px] font-normal",
                              selectorButton:
                                "order-first phone:min-w-[18px] phone:max-h-[18px] phone:h-[18px] min-w-[12px] max-h-[12px] h-[12px] phone:me-1 me-[2px]",
                              separator:
                                "mx-0 date-picker-separator text-sm font-normal no-action",
                            }}
                          />
                        )}
                      />

                      <div className="flex items-start gap-2 sm:flex-nowrap flex-wrap">
                        <Controller
                          name="budget"
                          control={control}
                          rules={{
                            pattern: {
                              value: /^[1-9][0-9]*(\.[0-9]+)?$/,
                              message: "Invalid Budget",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              className="sm:min-w-[140px] sm:max-w-[140px] w-full"
                              {...field}
                              type="text"
                              variant="bordered"
                              placeholder="0"
                              labelPlacement="outside"
                              classNames={{
                                base: `input-noMargin ${
                                  errors.budget?.message ||
                                  errors.currency?.message
                                    ? "h-[96px]"
                                    : "h-[68px]"
                                }`,
                                label:
                                  "text-textColor font-normal phone:text-sm text-[10px]",
                                input:
                                  "border-none px-[18px] phone:text-base text-[10px]",
                                mainWrapper: `${
                                  errors.budget?.message ||
                                  errors.currency?.message
                                    ? "h-[68px]"
                                    : "h-[42px]"
                                }`,
                                innerWrapper: "h-[42px] px-[18px]",
                                inputWrapper: `border-[0.86px] p-0 border-solid ${
                                  errors.budget?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] h-[42px]`,
                              }}
                              isInvalid={errors.budget?.message ? true : false}
                              errorMessage={errors.budget?.message}
                              startContent={
                                <Image
                                  src={euro}
                                  className="phone:min-w-[18px] phone:max-w-[18px] phone:h-[18px] min-w-[12px] max-w-[12px] h-[12px] phone:me-1 me-[2px]"
                                />
                              }
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: labelBudgetHtml,
                                  }}
                                />
                              }
                            />
                          )}
                        />

                        <Controller
                          name="currency"
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="w-[100%]"
                              {...field}
                              variant="bordered"
                              labelPlacement="outside"
                              selectedKeys={field.value ? [field.value] : []}
                              placeholder="Select Currency"
                              classNames={{
                                base: `input-noMargin ${
                                  errors.currency?.message ||
                                  errors.budget?.message
                                    ? "h-[96px]"
                                    : "h-[68px]"
                                } custom-input-value`,
                                label:
                                  "text-textColor font-normal phone:text-sm text-[10px]",
                                mainWrapper: `${
                                  errors.currency?.message ||
                                  errors.budget?.message
                                    ? "h-[68px]"
                                    : "h-[42px]"
                                }`,
                                innerWrapper: "h-[42px] px-[18px]",
                                trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                                  errors.currency?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] flex items-center justify-between`,
                                value:
                                  "text-cardGrayColor phone:text-sm text-[10px] font-normal",
                                selectorIcon:
                                  "phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]",
                              }}
                              selectorIcon={
                                <img
                                  src={up}
                                  className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                                />
                              }
                              isInvalid={
                                errors.currency?.message ? true : false
                              }
                              errorMessage={errors.currency?.message}
                              startContent={
                                <Image
                                  src={euro}
                                  className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px] phone:me-1 me-[2px]"
                                />
                              }
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: labelCurrencyHtml,
                                  }}
                                />
                              }
                            >
                              {currenciesList.map((item) => (
                                <SelectItem key={item?.currency_code}>
                                  {item?.currency_code}
                                </SelectItem>
                              ))}
                            </Select>
                          )}
                        />
                      </div>

                      <Controller
                        name="tags"
                        control={control}
                        rules={{ validate: handleValidation }}
                        render={({ field }) => (
                          <div>
                            <label
                              className={`block ${
                                // || tagLimit (if there are tag limit enter that after tagError)
                                tagError ? "text-[#f31260]" : "text-textColor"
                              } phone:text-sm text-[10px] font-normal mb-2 transition-all duration-400`}
                              htmlFor="tags"
                            >
                              Tags{" "}
                              <span className="phone:text-xs text-[12px] text-cardGrayColor">
                                (Optional)
                              </span>
                            </label>
                            <ReactTags
                              autoFocus={false}
                              {...field}
                              id="tags"
                              tags={tags}
                              handleDelete={handleDelete}
                              handleAddition={handleAddition}
                              allowDragDrop={false}
                              inputFieldPosition="top"
                              separators={["Enter", "Comma"]}
                              placeholder="Add Tags ..."
                              // maxTags={10}
                              removeComponent={RemoveComponent}
                              classNames={{
                                // || tagLimit (if there are tag limit enter that after tagError)
                                tagInputField: `tagInputField bg-white border-[0.86px] w-full px-[18px] border-solid phone:placeholder:text-base placeholder:text-[10px] ${
                                  tagError ? "invalid-text" : "border-[#E5E4EB]"
                                } rounded-[8px] h-[42px] placeholder: text-sm placeholder: font-normal phone:text-sm text-[12px] font-normal transition-all duration-400 no-focus`,
                                tag: `px-2.5 py-[6px] bg-[#1976D2] rounded-[20px] inline-flex text-white phone:text-sm text-[12px] items-center justify-center gap-1.5 mt-2 me-2
          `,
                                editTagInput: "flex",
                              }}
                            />
                            {tagError && (
                              <p
                                className={`text-[#f31260] phone:text-xs text-[10px] mt-0.5 transition-opacity duration-400 ${
                                  tagError ? "opacity-100" : "opacity-0"
                                }`}
                              >
                                {tagError}
                              </p>
                            )}
                            {/* {tagLimit && (
                <p
                  className={`text-[#f31260] text-xs mt-1 transition-opacity duration-400 ${
          tagLimit? "opacity-100": "opacity-0"
        }`}
                >
                  Tag limit reached
                </p>
              )} */}
                          </div>
                        )}
                      />

                      <div className="flex flex-col justify-between">
                        <span className="text-textColor font-normal phone:text-sm text-[10px] mb-2">
                          Importance{" "}
                          <span className="phone:text-xs text-[10px] text-cardGrayColor">
                            (Optional)
                          </span>
                        </span>
                        <div className="flex gap-2 items-center h-[32px]">
                          <button
                            type="button"
                            className={`importanceBtn transition-all duration-300 phone:text-[15.54px] text-[12px] font-normal h-full w-1/3 rounded-l-[20px] flex justify-center items-center ${
                              importance === "High"
                                ? "bg-[#FF3D00E5] opacity-90 shadow-importanceShadow border-2 border-solid border-[#FF3D00E5]"
                                : "border-2 border-solid border-[#FF3D00E5]"
                            } `}
                            onClick={() => setImportance("High")}
                          >
                            <span
                              className={`transition-all duration-300 opacity-100 ${
                                importance === "High"
                                  ? "text-white"
                                  : "text-[#FF3D00E5]"
                              } `}
                            >
                              High
                            </span>
                          </button>
                          <button
                            type="button"
                            className={`importanceBtn transition-all duration-300 phone:text-[15.54px] text-[12px] font-normal h-full w-1/3 flex justify-center items-center ${
                              importance === "Medium"
                                ? "bg-[#FFC000] opacity-90 shadow-importanceShadow border-2 border-solid border-[#FFC000]"
                                : "border-2 border-solid border-[#FFC000]"
                            } `}
                            onClick={() => setImportance("Medium")}
                          >
                            <span
                              className={`transition-all duration-300 opacity-100 ${
                                importance === "Medium"
                                  ? "text-white"
                                  : "text-[#FFC000]"
                              } `}
                            >
                              Medium
                            </span>
                          </button>
                          <button
                            type="button"
                            className={`importanceBtn transition-all duration-300 phone:text-[15.54px] text-[12px] font-normal h-full w-1/3 rounded-r-[20px] flex justify-center items-center ${
                              importance === "Low"
                                ? "bg-[#5CCDB0] opacity-90 shadow-importanceShadow border-2 border-solid border-[#5CCDB0]"
                                : "border-2 border-solid border-[#5CCDB0]"
                            } `}
                            onClick={() => setImportance("Low")}
                          >
                            <span
                              className={`transition-all duration-300 opacity-100 ${
                                importance === "Low"
                                  ? "text-white"
                                  : "text-[#5CCDB0]"
                              } `}
                            >
                              Low
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow min-h-[60px] max-h-[80px]">
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] phone:py-[11px] py-[8px] px-[25px] sm:w-[234px] w-[117px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default EditDetailsModal;
