import { Luggage } from "lucide-react";
import { FlightDetails } from "./FlightDetails";
import { PriceTag } from "./PriceTag";

export const FlightCard = ({ flightOffer, isLastItem }) => {
  return (
    <div
      className={`bg-white h-full rounded-xl shadow-lg overflow-hidden border border-gray-100 hover:shadow-xl transition-shadow duration-300 
      `}
    >
      <div className="p-6 space-y-6">
        {/* Header */}
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {flightOffer.itineraries[0].segments[0].departure.iataCode} →{" "}
              {
                flightOffer.itineraries[0].segments[
                  flightOffer.itineraries[0].segments.length - 1
                ].arrival.iataCode
              }
            </h3>
            <p className="text-sm text-gray-500">
              {flightOffer.validatingAirlineCodes[0]} -{" "}
              {flightOffer.numberOfBookableSeats} seats available
            </p>
          </div>
          <PriceTag
            currency={flightOffer.price.currency}
            amount={flightOffer.price.total}
          />
        </div>

        {/* Outbound Flight */}
        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-3">
            Outbound Flight
          </h4>
          <FlightDetails itinerary={flightOffer.itineraries[0]} />
        </div>

        {/* Return Flight */}
        {flightOffer.itineraries[1] && (
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-3">
              Return Flight
            </h4>
            <FlightDetails itinerary={flightOffer.itineraries[1]} isReturn />
          </div>
        )}

        {/* Footer */}
        <div className="pt-4 border-t border-gray-100">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Luggage className="w-4 h-4" />
            <span>1 checked bag included (23kg)</span>
          </div>
        </div>
      </div>
    </div>
  );
};
