import { createContext, useState } from "react";

export let CommunityContext = createContext();

export default function CommunityContextProvider({ children }) {
    const [commentsNum, setCommentsNum] = useState([]);
    const [deletedPosts, setDeletedPosts] = useState([]);
    const [updatedPosts, setUpdatedPosts] = useState([]);

    return (
        <CommunityContext.Provider value={{ commentsNum, setCommentsNum, deletedPosts, setDeletedPosts, updatedPosts, setUpdatedPosts }}>
            {children}
        </CommunityContext.Provider>
    );
}
